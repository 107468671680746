import React from 'react'
import classnames from 'classnames'
import css from './PriceTag.styles.scss'
import { SaleCallout } from './PriceTag.types'
import Badge from '../Badge/Badge'
import PromoMessage from '../PromoMessage/PromoMessage'

const SALE_THRESHOLD = 0.95 // At least 5% off

export interface PriceTagProps {
  callouts?: SaleCallout[]
  className?: string
  msrp?: number | string
  minPrice?: number | string
  maxPrice?: number | string
  currentPrice?: number | string
  currentlyInActiveSale?: boolean
  size?: 'xs' | 'sm' | 'md'
  truncateZeroCents?: boolean
  variant?: 'default' | 'compact'
}

const formatPrice = (dollars: string, cents?: string) => (
  <div className={css.PriceTag__price}>
    <span>$</span>
    <div className={css.PriceTag__numerals}>
      <span className={css.PriceTag__dollars}>{dollars}</span>
      {cents && (
        <>
          <span className={css.PriceTag__decimal}>.</span>
          <span className={css.PriceTag__cents}>{cents}</span>
        </>
      )}
    </div>
  </div>
)

const formatPriceVisually = (
  price: number | string | undefined,
  truncateZeroCents: boolean
) => {
  if (price === undefined || price === null) return null

  const priceStr = typeof price === 'number' ? price.toFixed(2) : price
  const [dollars, cents] = priceStr.split('.')

  if (truncateZeroCents && cents === '00') {
    return formatPrice(dollars)
  }
  return formatPrice(dollars, cents)
}

const PriceRange: React.FC<{
  minPrice?: number | string
  maxPrice?: number | string
  onSale: boolean
  truncateZeroCents: boolean
}> = ({ minPrice, maxPrice, onSale, truncateZeroCents }) => (
  <div
    className={classnames(
      css.PriceTag__range,
      onSale && css['PriceTag__range--sale']
    )}
    data-testid="range"
  >
    {formatPriceVisually(minPrice, truncateZeroCents)}
    <span
      className={classnames(
        css.PriceTag__range__separator,
        onSale && css['PriceTag__range__separator--sale']
      )}
    >
      -
    </span>
    {formatPriceVisually(maxPrice, truncateZeroCents)}
  </div>
)

const SinglePrice: React.FC<{
  currentPrice?: number | string
  msrp?: number | string
  onSale: boolean
  truncateZeroCents: boolean
}> = ({ currentPrice, msrp, onSale, truncateZeroCents }) => {
  const currentPriceNumber = parseFloat(String(currentPrice))
  const msrpNumber = parseFloat(String(msrp))
  const currentPriceUnderMsrp =
    !Number.isNaN(currentPriceNumber) &&
    !Number.isNaN(msrpNumber) &&
    currentPriceNumber < msrpNumber &&
    currentPriceNumber / msrpNumber <= SALE_THRESHOLD

  return (
    <>
      <div
        className={classnames(
          css.PriceTag__current,
          onSale && css['PriceTag__current--sale']
        )}
        data-testid="current"
      >
        {formatPriceVisually(currentPrice, truncateZeroCents)}
      </div>
      {currentPriceUnderMsrp && (
        <div className={css.PriceTag__msrp} data-testid="msrp">
          <span className={css['PriceTag__msrp__strike-through']} />
          {formatPriceVisually(msrp, truncateZeroCents)}
        </div>
      )}
    </>
  )
}

const PriceTag: React.FC<PriceTagProps> = ({
  callouts = [],
  className,
  msrp,
  minPrice,
  maxPrice,
  currentPrice,
  currentlyInActiveSale = false,
  size = 'sm',
  truncateZeroCents = false,
  variant = 'default',
}) => {
  const displayPriceRange =
    Boolean(minPrice) && Boolean(maxPrice) && minPrice !== maxPrice
  const onSale = currentlyInActiveSale

  const priceTagClasses = classnames(
    css.PriceTag,
    css[`PriceTag--variant-${variant}`],
    css[`PriceTag--size-${size}`],
    className
  )

  const badgeCallouts = callouts.filter((callout) => callout.type === 'badge')
  const textCallouts = callouts.filter((callout) => callout.type === 'text')

  return (
    <div className={priceTagClasses}>
      {displayPriceRange ? (
        <PriceRange
          maxPrice={maxPrice}
          minPrice={minPrice}
          truncateZeroCents={truncateZeroCents}
          onSale={onSale}
        />
      ) : (
        <SinglePrice
          currentPrice={currentPrice}
          msrp={msrp}
          truncateZeroCents={truncateZeroCents}
          onSale={onSale}
        />
      )}
      {badgeCallouts.length > 0 && (
        <div
          className={css.PriceTag__badgeCallouts}
          data-testid="badge-callouts"
        >
          {badgeCallouts.map((callout: SaleCallout) => (
            <div
              className={css['PriceTag__callout--type-text']}
              key={callout.text}
            >
              <Badge
                fill="discount-ruby"
                label={callout.text}
                size={size === 'xs' ? 'small' : 'medium'}
                variant="promo"
              />
            </div>
          ))}
        </div>
      )}
      {textCallouts.length > 0 && (
        <div className={css.PriceTag__textCallouts} data-testid="text-callouts">
          {textCallouts.map((callout: SaleCallout) => (
            <PromoMessage
              className={css['PriceTag__callout--type-text']}
              key={callout.text}
              size={size === 'xs' ? 'xs' : 'sm'}
              text={callout.text}
            />
          ))}
        </div>
      )}
    </div>
  )
}

export default PriceTag
