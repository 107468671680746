import classnames from 'classnames'
import Link from 'components/link'
import css from './LanguageSelector.styles.scss'

const READ_LANGUAGE_LABEL: Record<'es-US' | 'en-US', string> = {
  'es-US': 'En Español',
  'en-US': 'In English',
}

interface LanguageSelectorProps {
  locales?: Array<{ locale: string; slug: string }>
}

const LanguageSelector: React.FC<LanguageSelectorProps> = ({
  locales,
}: LanguageSelectorProps) => (
  <div className={classnames(css.LanguageSelector__languages)}>
    {locales?.map((l) => {
      const text = READ_LANGUAGE_LABEL[l.locale as 'es-US' | 'en-US']
      return text ? (
        <i key={l.slug}>
          <Link className="language-link h6 mvn" url={l.slug}>
            {text}
          </Link>
        </i>
      ) : null
    })}
  </div>
)

export default LanguageSelector
