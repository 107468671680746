import Card from 'components/card'
import Image from 'components/image'
import { numberToCurrency } from 'lib/money'

interface GroupGiftProductPreviewCardProps {
  product?: {
    name: string
    image: string
    price: number
  }
}

const GroupGiftProductPreviewCard = ({
  product,
}: GroupGiftProductPreviewCardProps) => {
  const { image = '', name = '', price } = product || {}
  return (
    // @ts-ignore
    <Card className="pal flex bg-white">
      <Image src={image} alt={name} width={100} />
      <div className="mlm" style={{ minHeight: 100 }}>
        <div className="h4 mtn">{name}</div>
        {price && (
          <div className="flex">
            <span className="icon-offer icon-offer-babylist mrs" />
            <span>Babylist</span>
            <span className="phs text-muted">|</span>
            <span>{numberToCurrency(price)}</span>
          </div>
        )}
      </div>
    </Card>
  )
}

export default GroupGiftProductPreviewCard
