import React from 'react'

interface Shipping1Props {
  className?: string
  filled?: boolean
}

const outlinedPath = (
  <path
    d="M22 12.9999V18.2029C22 18.6425 21.6437 19 21.2041 19H20.5M22 12.9999C22 9.13387 18.866 6 15 6M22 12.9999L17 13C15.8954 13 15 12.1046 15 11V6M15 6C15 3.79086 13.2091 2 11 2H6C3.79086 2 2 3.79086 2 6V9.5M10 19H14M10 19C10 20.6569 8.65685 22 7 22C5.34315 22 4 20.6569 4 19M10 19C10 17.3431 8.65685 16 7 16C5.34315 16 4 17.3431 4 19M14 19C14 20.6569 15.3431 22 17 22C18.6569 22 20 20.6569 20 19C20 17.3431 18.6569 16 17 16C15.3431 16 14 17.3431 14 19ZM4 19C2.89543 19 2 18.1046 2 17V12.1562M1 9.5H5.5M1 12H7.5"
    stroke="#252324"
    strokeLinecap="round"
    strokeLinejoin="round"
    strokeWidth="1.5"
  />
)

const filledPath = (
  <>
    <path
      clipRule="evenodd"
      d="M1.25 6C1.25 3.37665 3.37665 1.25 6 1.25H11C13.3789 1.25 15.3493 2.99874 15.6959 5.28082C19.6501 5.6327 22.75 8.95432 22.75 13V18.2029C22.75 19.0552 22.0593 19.75 21.2041 19.75H20.8281C20.815 19.75 20.802 19.7497 20.7891 19.749L20.7687 19.7498L20.75 19.75C20.3358 19.75 20 19.4142 20 19C20 17.3431 18.6569 16 17 16C15.3431 16 14 17.3431 14 19C14 19.4142 13.6642 19.75 13.25 19.75C13.2074 19.75 13.1657 19.7465 13.125 19.7396C13.0843 19.7465 13.0426 19.75 13 19.75H11C10.9574 19.75 10.9157 19.7465 10.875 19.7396C10.8343 19.7465 10.7926 19.75 10.75 19.75C10.3358 19.75 10 19.4142 10 19C10 17.3431 8.65685 16 7 16C5.38499 16 4.06774 17.2765 4.00254 18.8751C3.98566 19.2889 3.63647 19.6108 3.2226 19.5939C3.10593 19.5891 2.99657 19.558 2.90005 19.5064C2.84704 19.4927 2.79471 19.473 2.74398 19.4469C1.85844 18.9917 1.25 18.0675 1.25 17V12.75H7.5C7.91421 12.75 8.25 12.4142 8.25 12C8.25 11.5858 7.91421 11.25 7.5 11.25H1.25V10.25H5.5C5.91421 10.25 6.25 9.91421 6.25 9.5C6.25 9.08579 5.91421 8.75 5.5 8.75H1.25V6ZM17 12.25C16.3096 12.25 15.75 11.6904 15.75 11V6.79454C18.6023 7.13563 20.8644 9.39769 21.2055 12.25H17Z"
      fill="#252324"
      fillRule="evenodd"
    />
    <path
      d="M7 16.75C8.24264 16.75 9.25 17.7574 9.25 19C9.25 20.2426 8.24264 21.25 7 21.25C5.75736 21.25 4.75 20.2426 4.75 19C4.75 17.7574 5.75736 16.75 7 16.75Z"
      fill="#252324"
    />
    <path
      d="M17 16.75C18.2426 16.75 19.25 17.7574 19.25 19C19.25 20.2426 18.2426 21.25 17 21.25C15.7574 21.25 14.75 20.2426 14.75 19C14.75 17.7574 15.7574 16.75 17 16.75Z"
      fill="#252324"
    />
  </>
)

const Shipping1: React.FC<Shipping1Props> = ({
  className,
  filled,
}: Shipping1Props) => (
  <svg
    className={className}
    fill="none"
    height="24"
    viewBox="0 0 24 24"
    width="24"
    xmlns="http://www.w3.org/2000/svg"
  >
    {filled ? filledPath : outlinedPath}
  </svg>
)

export default Shipping1
