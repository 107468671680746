import React, { useEffect } from 'react'
import classNames from 'classnames'
import { MOBILE_VARIANTS } from './constants'
import {
  ModalActions,
  ModalBackdrop,
  ModalContent,
  ModalHeader,
  ModalTopBar,
  ModalTransition,
} from './components'
import { ModalProps } from './Modal.types'
import css from './Modal.styles.scss'

const { class: BASIC_CLASS, value: BASIC_VALUE } = MOBILE_VARIANTS.basic

const Modal: React.FC<ModalProps> = ({
  additionalLink,
  alignButton,
  children,
  className,
  handleClose,
  icon,
  isOpen,
  mobileVariant = 'rich',
  onOpen,
  primaryButton,
  scrollToTopOnContentChange = false,
  secondaryButton,
  showCloseButton = false,
  subTitle,
  timeout = 300,
  title,
}) => {
  const showActions = primaryButton || secondaryButton || additionalLink

  const modalContentBoxClasses = classNames(css.Modal, className, {
    [css['Modal--noActions']]: !showActions,
    [css[`Modal--${BASIC_CLASS}`]]: mobileVariant === BASIC_VALUE,
    [css['Modal--noHeader']]: !icon && !title,
  })

  useEffect(() => {
    if (isOpen) onOpen?.()
  }, [isOpen])

  return (
    <>
      <ModalTransition in={isOpen} timeout={timeout} type="backdrop">
        <ModalBackdrop handleClose={handleClose} />
      </ModalTransition>
      <ModalTransition in={isOpen} timeout={timeout} type="modal">
        <div className={modalContentBoxClasses}>
          <div className={css.Modal__wrapper}>
            <div className={css.Modal__topBar__header__wrapper}>
              {showCloseButton && (
                <ModalTopBar
                  handleClose={handleClose}
                  mobileVariant={mobileVariant}
                  showCloseButton={showCloseButton}
                />
              )}
              {(icon || title) && (
                <ModalHeader
                  icon={icon}
                  mobileVariant={mobileVariant}
                  subTitle={subTitle}
                  title={title}
                />
              )}
            </div>
            <ModalContent
              scrollToTopOnContentChange={scrollToTopOnContentChange}
            >
              {children}
            </ModalContent>
            {showActions && (
              <ModalActions
                additionalLink={additionalLink}
                alignButton={alignButton}
                mobileVariant={mobileVariant}
                primaryButton={primaryButton}
                secondaryButton={secondaryButton}
              />
            )}
          </div>
        </div>
      </ModalTransition>
    </>
  )
}

export default Modal
