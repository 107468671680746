import React from 'react'
// eslint-disable-next-line import/no-extraneous-dependencies
import { Button } from 'baby-design'
import { PurchasesSectionProps } from 'src/routes/(registry)/list/[slug]/components/EditRegItemDrawer/components/PurchasesSection/PurchasesSection.types'
import NiceModal from '@ebay/nice-modal-react'
import { CheckCircle } from 'baby-design/icons'
import { Reservation } from 'src/types/reservation'
import PurchasesSectionHeader from './components/PurchasesSectionHeader/PurchasesSectionHeader'
import css from './PurchasesSection.styles.scss'

const PurchasesSection: React.FC<PurchasesSectionProps> = ({
  bullet: Bullet,
  icon: HeaderIcon,
  initialButtonText,
  markAsPurchasedModal: MarkAsPurchasedModal,
  onRegItemUpdated,
  purchasedButtonText,
  regItem,
  reservations = [],
  status,
  title,
}) => {
  const handleOpenMarkPurchasedModal = async () => {
    if (!MarkAsPurchasedModal) return

    const createdReservation: Reservation | undefined = await NiceModal.show(
      MarkAsPurchasedModal,
      {
        regItem,
      }
    )
    if (createdReservation) {
      onRegItemUpdated({ ...createdReservation.regItem })
    }
  }

  const currentQuantity = reservations.reduce(
    (sum, reservation) => sum + reservation.quantity,
    0
  )

  return (
    <div className={css.PurchasesSection}>
      <div className={css.PurchasesSection__Content}>
        <PurchasesSectionHeader
          hasReservations={reservations.length > 0}
          icon={HeaderIcon}
          status={status}
          title={title}
        />
        <div className={css.PurchasesSection__Reservations}>
          {reservations.map((reservation) => (
            <Bullet
              regItem={regItem}
              reservation={reservation}
              totalReservationQuantity={currentQuantity}
            />
          ))}
        </div>
      </div>
      {initialButtonText && (
        <Button
          icon={{
            icon: <CheckCircle />,
            side: 'left',
            size: 'xl',
          }}
          size="md"
          variant="secondary"
          onClick={handleOpenMarkPurchasedModal}
        >
          {purchasedButtonText && reservations.length > 0
            ? purchasedButtonText
            : initialButtonText}
        </Button>
      )}
    </div>
  )
}

export default PurchasesSection
