import React from 'react'
import { PaypalMeHelpTextProps } from 'src/routes/(registry)/list/[slug]/components/CashFundPreferencesDrawer/components/LegacyCashFundPreferencesForm/components/PaypalmeHelpText/PaypalMeHelpText.types'
import css from './PaypalMeHelpText.styles.scss'
import { TextLink } from '../../../../../../../../../../../baby-design'

const PaypalMeHelpText: React.FC<PaypalMeHelpTextProps> = ({ input }) => {
  if (input) {
    const url = `https://www.paypal.me/${input}`

    return (
      <div className={css.cash_fund_preferences_info}>
        Please double-check that{' '}
        <TextLink href={url} size="sm" target="_blank">
          {url}
        </TextLink>{' '}
        is correct.
      </div>
    )
  }
  return (
    <div className={css.cash_fund_preferences_info}>
      Enter your PayPal.Me username or{' '}
      <TextLink href="https://www.paypal.me/" size="sm" target="_blank">
        Create a new one
      </TextLink>
    </div>
  )
}

export default PaypalMeHelpText
