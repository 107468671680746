import React from 'react'
import Toast from 'baby-design/components/Toast/Toast'
import { RegItemAddedToastProps } from 'shared/RegItemAddedToast/RegItemAddedToast.types'
import useCurrentUser from 'shared/hooks/useCurrentUser/useCurrentUser'
import { useRegistry } from 'src/routes/(registry)/list/[slug]/utils/useQueries'
import NiceModal from '@ebay/nice-modal-react'
import EditRegItemDrawer from 'src/routes/(registry)/list/[slug]/components/EditRegItemDrawer/EditRegItemDrawer'

const RegItemAddedToast: React.FC<RegItemAddedToastProps> = ({
  isOpen,
  onDismiss,
  onEdit,
  regItem,
}) => {
  const [currentUser] = useCurrentUser()
  const registry = useRegistry()

  const handleEditRegItem = () => {
    NiceModal.show(EditRegItemDrawer, { regItem })
    onDismiss?.()
    onEdit?.()
  }

  const eyebrow =
    regItem.quantity && regItem.quantity > 1
      ? `${regItem.quantity} added to category`
      : `Added to category`

  const category = registry?.categories?.find(
    (c) => c.id === regItem.categoryId
  )
  const title = category?.title || 'General'

  const numRegistries = currentUser?.registries?.length || 0
  const text = numRegistries > 1 && registry ? registry.title : undefined

  return (
    <Toast
      dismissible
      cta={{
        text: 'Edit',
        onClick: handleEditRegItem,
      }}
      eyebrow={eyebrow}
      imageProps={{
        imageSrc: regItem.imgUrl,
        imageAlt: regItem.title,
      }}
      isOpen={isOpen}
      text={text}
      title={title}
      variant="rich"
      onDismiss={onDismiss}
    />
  )
}

export default RegItemAddedToast
