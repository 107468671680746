import * as React from 'react'
import { UI } from '../../global'

/**
 * This function works a proxy to reinforce the contract between a TransitionBuilder
 * and an individual transition.
 * @param Component A transition component that returns <TransitionBuilder />.
 * @param propsMaker Utility function that returns the transition properties.
 */
export default function makeComposableTransitionComponent<P>(
  Component: React.ForwardRefExoticComponent<
    P & React.RefAttributes<HTMLDivElement>
  >,
  propsMaker: (params: UI.TransitionPropMakerParams) => UI.TransitionSettings
) {
  return Object.assign(Component, { makeProps: propsMaker })
}
