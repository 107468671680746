import PropTypes from 'prop-types'
import { connect } from 'react-redux'
// @ts-ignore
import { track, useTracking } from 'lib/analytics'
import {
  openAddToCartModal,
  openFundThisGiftModal,
  openRedirectModal,
} from '../../modals/actions'
import CrowdfundAction from '../reg-item-card/guest-actions/crowdfund-action'
import { OfferCard } from './components/OfferCard'
import { BabylistOffer } from './components/BabylistOffer'
import { BabylistOfferWithDeliveryEstimates } from './components/BabylistOfferWithDeliveryEstimates'
import { GroupGiftOffer } from './components/GroupGiftOffer'
import { createReservation, updateRegItem } from '../../actions'
import { FavorOffer } from './components/FavorOffer'
import ExternalProductOffer from './components/ExternalProductOffer'
import useEZAddToCart from 'src/hooks/useEZAddToCart'
import InCartLink from './components/InCartLink'
import { useState } from 'react'

export interface AddOrBuyProps {
  offers: Array<any>
  regItem: any
  registry: any
  reservation: any
  openAddToCartModal: any
  openFundThisGiftModal: any
  createReservation: any
  updateRegItem: (id: number, data: any) => any
}

interface AddOrBuyContext {
  isMobile: boolean
}

export const AddOrBuy = (
  {
    offers,
    regItem,
    registry,
    reservation,
    openAddToCartModal,
    openFundThisGiftModal,
    createReservation,
    updateRegItem,
  }: AddOrBuyProps,
  { isMobile }: AddOrBuyContext
) => {
  const { firstNames } = registry
  const {
    goalAmount,
    isFavor,
    isGiftCard,
    isAvailableOnBabylist,
    isGroupGift,
    isCrowdfund,
    cartInfo,
  } = regItem
  const babylistOffer = offers && offers.find((o) => o.isBabylist)
  const tracker = useTracking()
  const [isEZAddingToCart, setIsEZAddingToCart] = useState(false)
  const { isItemEnabledForEZAddToCart, addRegItemToCart } = useEZAddToCart()

  // @ts-ignore
  const trackedCreateReservation = (...args) =>
    // @ts-ignore https://stackoverflow.com/questions/67633259/property-then-does-not-exist-on-type-dispatch-any-promiseany-ts2339
    createReservation(...args).then(
      (newReservation: Record<string, unknown>) => {
        const { email, userId } = newReservation
        tracker.trackEvent({
          event: track.emailCaptured,
          eventLocation: track.EventLocation.GIFT_GIVER_MINUS_EMAIL_COLLECTION,
          eventUrl: window.location.href,
          email,
          userId,
        })
        return newReservation
      }
    )

  const handleOnAddClick = () => {
    if (isItemEnabledForEZAddToCart(regItem)) {
      setIsEZAddingToCart(true)
      addRegItemToCart({
        regItem,
        onSuccess: ({ regItem, quantity }) => {
          updateRegItem(regItem.id, { ...regItem, cartInfo: { quantity } })
          setIsEZAddingToCart(false)
        },
      })
    } else {
      openAddToCartModal(regItem, trackedCreateReservation)
    }
  }

  const handleOpenFundThisGiftModal = () => openFundThisGiftModal(regItem)

  const isBabylistProduct = () =>
    babylistOffer && (isAvailableOnBabylist || offers.length >= 1)

  const isExternalWithBabylistOffers = () =>
    offers && !isGroupGift && !isGiftCard

  const isValidGroupGift = () => !!goalAmount && isGroupGift

  // if it's in the user's cart via ezAddToCart
  if (cartInfo?.quantity) {
    return <InCartLink />
  }

  if (isFavor) {
    return (
      <FavorOffer
        inCart={!!reservation}
        isMobile={isMobile}
        onClick={handleOnAddClick}
      />
    )
  }

  if (isCrowdfund) {
    return (
      <OfferCard isMobile={isMobile}>
        <CrowdfundAction regItem={regItem} />
      </OfferCard>
    )
  }

  if (isValidGroupGift()) {
    return (
      <GroupGiftOffer
        openAddToCartModal={handleOnAddClick}
        openFundThisGiftModal={handleOpenFundThisGiftModal}
        regItem={regItem}
        registry={registry}
        trackedCreateReservation={trackedCreateReservation}
        submitting={isEZAddingToCart}
      />
    )
  }

  if (regItem.deliveryEstimate && isBabylistProduct()) {
    return (
      <BabylistOfferWithDeliveryEstimates
        inCart={!!reservation}
        offer={babylistOffer}
        regItem={regItem}
        onClick={handleOnAddClick}
        registry={registry}
        trackedCreateReservation={trackedCreateReservation}
        submitting={isEZAddingToCart}
      />
    )
  }

  if (isBabylistProduct()) {
    return (
      <OfferCard isMobile={isMobile}>
        <BabylistOffer
          inCart={!!reservation}
          offer={babylistOffer}
          regItem={regItem}
          registryFirstNames={firstNames}
          onClick={handleOnAddClick}
          registry={registry}
          trackedCreateReservation={trackedCreateReservation}
          submitting={isEZAddingToCart}
        />
      </OfferCard>
    )
  }

  if (isExternalWithBabylistOffers()) {
    return (
      <OfferCard isMobile={isMobile}>
        <ExternalProductOffer
          offers={offers}
          regItem={regItem}
          registry={registry}
          trackedCreateReservation={trackedCreateReservation}
        />
      </OfferCard>
    )
  }

  return null
}

AddOrBuy.propTypes = {
  regItem: PropTypes.object.isRequired,
  offers: PropTypes.array.isRequired,
  registry: PropTypes.object.isRequired,
  reservation: PropTypes.object,
}

AddOrBuy.contextTypes = {
  isMobile: PropTypes.bool,
}

AddOrBuy.defaultProps = {
  regItem: {},
}

export default connect(null, {
  openAddToCartModal,
  openRedirectModal,
  openFundThisGiftModal,
  createReservation,
  updateRegItem,
})(AddOrBuy)
