// eslint-disable-next-line import/no-extraneous-dependencies
import { Image as BabyImage } from 'baby-design'
import { useState } from 'react'
import { cloudinaryThumbnailUrl } from 'hello_baby/lib/cloudinary'
import classNames from 'classnames'
import EmbedImage, { TypePostEmbedImageFields } from '../Image/Image'
import css from './ImageGallery.styles.scss'

interface TypePostEmbedImageGalleryImage {
  fields: TypePostEmbedImageFields
}
interface TypePostEmbedImageGalleryFields {
  images: TypePostEmbedImageGalleryImage[]
}

interface EmbedImageGalleryProps {
  fields: TypePostEmbedImageGalleryFields
}

const DEFAULT_ASPECT_RATIO = 1.618

const ImageGallery = ({ fields }: EmbedImageGalleryProps) => {
  const { images } = fields
  const [currentImage, setCurrentImage] = useState(images[0])

  return (
    <section className={css.ImageGallery__container}>
      <div className={css.ImageGallery__currentImage}>
        <EmbedImage
          aspectRatio={DEFAULT_ASPECT_RATIO}
          fields={currentImage.fields}
          key={currentImage.fields.imageTitle}
        />
      </div>
      <div className={css.ImageGallery__thumbnailRow}>
        {images.map((image, index) => (
          <button
            className={classNames(css.ImageGallery__thumbnailButton, {
              [css.ImageGallery__thumbnailButtonActive]: currentImage === image,
            })}
            // eslint-disable-next-line react/no-array-index-key
            key={`${image.fields.imageTitle}-${index}`}
            type="button"
            onClick={() => setCurrentImage(image)}
          >
            <BabyImage
              alt={
                `${image.fields.altText} thumbnail` ||
                'Babylist Gallery Thumbnail'
              }
              lazyLoad={false}
              src={cloudinaryThumbnailUrl(image.fields.image, true) || ''}
            />
          </button>
        ))}
      </div>
    </section>
  )
}

export default ImageGallery
