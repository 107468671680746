import { useState } from 'react'
import { RegItem } from 'src/types/regItem'

export type RegItemChanges = Record<string, any>

interface UseRegItemChangesProps {
  initialChanges?: RegItemChanges
  regItem: RegItem
}

const useRegItemChanges = ({
  initialChanges = {},
  regItem,
}: UseRegItemChangesProps) => {
  const [regItemChanges, setRegItemChanges] =
    useState<RegItemChanges>(initialChanges)

  const hasUnsavedChanges = () =>
    Object.keys(regItemChanges).some(
      (key) => regItemChanges[key as keyof {}] !== regItem[key as keyof RegItem]
    )

  const updateRegItemFields = (changes: object) => {
    setRegItemChanges({
      ...regItemChanges,
      ...changes,
    })
  }
  const clearRegItemFields = (...keys: Array<string>) => {
    const tempChanges: Record<string, unknown> = { ...regItemChanges }
    keys.forEach((key) => delete tempChanges[key])

    setRegItemChanges(tempChanges)
  }

  return {
    regItemChanges,
    clearRegItemFields,
    hasUnsavedChanges,
    setRegItemChanges,
    updateRegItemFields,
  }
}

export default useRegItemChanges
