import NiceModal, { useModal } from '@ebay/nice-modal-react'
import MarkPurchasedModal from 'src/routes/(registry)/list/[slug]/components/EditRegItemDrawer/components/MarkPurchasedModal/MarkPurchasedModal'
import useMarkPurchaseFormField from 'src/routes/(registry)/list/[slug]/components/EditRegItemDrawer/hooks/useMarkPurchaseFormField'
import useMarkPurchaseModal from 'src/routes/(registry)/list/[slug]/components/EditRegItemDrawer/hooks/useMarkPurchaseModal'
import TextInput from 'baby-design/components/input/TextInput/TextInput'
import PurchasedQuantity from 'src/routes/(registry)/list/[slug]/components/EditRegItemDrawer/components/RegItemPurchaseModal/components/PurchasedQuantity/PurchasedQuantity'
import { MarkAsPurchasedProps } from 'src/routes/(registry)/list/[slug]/hooks/useMarkAsPurchased'
import { RecordFavorModalProps } from './RecordFavorModal.types'

const TITLE = 'Record a favor'
const SUBTITLE =
  "Add favors you've received so you can see them in your gift tracker."
const NAME_FIELD_ERROR = 'Enter a name'

const RecordFavorModal = NiceModal.create(
  ({ regItem }: RecordFavorModalProps) => {
    const modal = useModal()
    const nameField = useMarkPurchaseFormField<string>({
      name: 'name',
      errorMessage: (val: string | undefined) =>
        !val ? NAME_FIELD_ERROR : undefined,
    })
    const { value: name, updateValue: setName, error: nameError } = nameField

    const quantityField = useMarkPurchaseFormField<number>({
      name: 'quantity',
      defaultValue: 1,
    })
    const { value: quantity, updateValue: setQuantity } = quantityField

    const {
      closeModal,
      handleMarkAsPurchased,
      handleSubmit,
      isCreatingReservation,
    } = useMarkPurchaseModal({
      fields: () => [nameField, quantityField],
      isInitiallySelfPurchase: false,
      modal,
      regItem,
    })

    const onSubmit = () => {
      handleMarkAsPurchased(
        (payload: Record<string, any>) =>
          ({
            ...payload,
            isPurchased: false,
          }) as MarkAsPurchasedProps
      )
    }

    return (
      <MarkPurchasedModal
        isLoading={isCreatingReservation}
        isOpen={modal.visible}
        subtitle={SUBTITLE}
        title={TITLE}
        onCloseModal={closeModal}
        onSubmit={() => handleSubmit(onSubmit)}
      >
        <TextInput
          inputProps={{
            value: name,
            onChange: (e) => setName(e.target.value),
          }}
          label="Who gave you this favor?"
          size="md"
          status={nameError ? { type: 'error', message: nameError } : undefined}
        />
        <PurchasedQuantity
          label="Quantity given"
          quantity={quantity as number}
          onQuantityChanged={setQuantity}
        />
      </MarkPurchasedModal>
    )
  }
)

export default RecordFavorModal
