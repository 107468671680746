import classnames from 'classnames'
import DismissibleBanner, {
  DismissibleBannerProps,
} from '../../../../../components/DismissibleBanner'
import FadeOut, {
  FadeOutProps,
} from '../../../../../components/transitions/FadeOut'
import { InfoButton } from '../../../../../components/buttons'
import { newRegistryPath } from '../../../../../lib/urls'

import SignUpBannerCss from './SignUpBanner.styles.scss'
import usePosition from '../../usePosition'
import { bowserFlags } from '../../../../../lib/bowser'

const SignUpBanner = ({
  show,
  onClose,
}: Pick<FadeOutProps & DismissibleBannerProps, 'show' | 'onClose'>) => {
  const positionStyle = usePosition(bowserFlags.mobile ? 52 : 0)

  return (
    <FadeOut
      animateOnEnter
      className={classnames(SignUpBannerCss.root, SignUpBannerCss.sticky)}
      show={show}
      // Put the component on top of the Solvvy button
      style={{ ...positionStyle, zIndex: 9999999999 }}
    >
      <DismissibleBanner
        className={SignUpBannerCss.signUpBannerBg}
        role="banner"
        onClose={onClose}
      >
        <InfoButton
          pill
          className={SignUpBannerCss.startNewRegButton}
          href={`${newRegistryPath}?loc=Banner+Button`}
        >
          Start Your Registry
        </InfoButton>
      </DismissibleBanner>
    </FadeOut>
  )
}

export default SignUpBanner
