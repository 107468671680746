import Link from 'components/link'
import { helloBabyPostPath } from 'lib/urls'
import classNames from 'classnames'

interface PostLinkProps {
  direction?: 'left' | 'right'
  post: Post
  className?: string
}

const PostLink = ({ className, direction, post }: PostLinkProps) => (
  <Link
    className={classNames('h6 link-primary', className)}
    to={helloBabyPostPath(post.slug)}
  >
    {direction === 'left' && <i className="h7 mrs fa fa-chevron-left" />}
    <span>
      {post.title.substring(0, 35)}
      {post.title.length > 35 && '...'}
    </span>
    {direction === 'right' ? (
      <i className="h7 mls fa fa-chevron-right" />
    ) : null}
  </Link>
)

export default PostLink
