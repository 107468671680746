import css from './CriteriaCard.styles.scss'

interface PostEmbedCriteraCardFields {
  label: string
  body?: string
}

export type PostEmbedCriteriaCard = {
  fields: PostEmbedCriteraCardFields
  sys: { id: string }
}

export interface CriteriaCardProps {
  fields: PostEmbedCriteraCardFields
}

const CriteriaCard = ({ fields }: CriteriaCardProps) => (
  <div className={css.CriteriaCard__cardOuterContainer}>
    <div className={css.CriteriaCard__cardInnerContainer}>
      <div className={css.CriteriaCard__cardLabel}>{fields.label}</div>
      <div className={css.CriteriaCard__cardBody}>{fields.body}</div>
    </div>
  </div>
)

export default CriteriaCard
