import { PriceRange } from './PricingTreatment.types'

export const formatPrice = (price: number, decimalPlaces: number) =>
  Number(price).toLocaleString('en-US', {
    style: 'currency',
    currency: 'USD',
    minimumFractionDigits: decimalPlaces,
  })

export const shouldDisplayPriceRange = (priceRange: PriceRange | undefined) =>
  priceRange && priceRange.min !== priceRange.max

export const shouldDisplayStrikePrice = (
  price?: number,
  strikePrice?: number,
  priceRange?: PriceRange
) =>
  !shouldDisplayPriceRange(priceRange) && strikePrice && price !== strikePrice

export const displayPrice = (price: number, decimalPlaces: number) =>
  formatPrice(price || 0, decimalPlaces)

export const displayStrikePrice = (
  strikePrice: number,
  decimalPlaces: number
) => formatPrice(strikePrice || 0, decimalPlaces)
