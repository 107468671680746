import NiceModal, { useModal } from '@ebay/nice-modal-react'
import { Button, Modal } from '../../../../../baby-design'

import css from './ConfirmUnsavedChangesModal.styles.scss'

export default NiceModal.create(() => {
  const modal = useModal()

  const handleClose = () => {
    modal.resolve(false)
    modal.remove()
  }

  const handleLeaveClick = () => {
    // close modal and drawer
    modal.resolve(true)
    modal.remove()
  }

  const primaryButton = (
    <Button size="md" variant="destructive" onClick={handleLeaveClick}>
      Leave
    </Button>
  )

  const cancelButton = (
    <Button size="md" variant="secondary" onClick={handleClose}>
      Go Back
    </Button>
  )

  return (
    <Modal
      handleClose={handleClose}
      isOpen={modal.visible}
      primaryButton={primaryButton}
      secondaryButton={cancelButton}
    >
      <div className={css.ConfirmUnsavedChangesModal}>
        <h2>Are you sure?</h2>
        <p>You have unsaved changes. Are you sure you want to close?</p>
      </div>
    </Modal>
  )
})
