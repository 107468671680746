import React from 'react'
import { ToastButtonProps } from './ToastButton.types'
import Button from '../../../Button/Button'

const ToastButton: React.FC<ToastButtonProps> = ({ text, ...props }) => (
  <Button data-testid="toast-button" size="sm" variant="secondary" {...props}>
    {text}
  </Button>
)

export default ToastButton
