import classNames from 'classnames'
import { useEffect, useRef } from 'react'
import css from './SocialMedia.styles.scss'

interface PostEmbedSocialMediaFields {
  channel:
    | 'Facebook'
    | 'Giphy'
    | 'Instagram'
    | 'TikTok'
    | 'Twitter'
    | 'Youtube'
    | 'Other'
  postTitle: string
  embedCode: string
}

interface EmbedSocialMediaProps {
  fields: PostEmbedSocialMediaFields
}

const EmbedSocialMedia: React.FC<EmbedSocialMediaProps> = ({
  fields,
}: EmbedSocialMediaProps) => {
  const { embedCode, channel } = fields
  const containerRef = useRef<HTMLDivElement>(null)

  useEffect(() => {
    const container = containerRef.current
    if (container) {
      container.innerHTML = embedCode

      const scripts = Array.from(container.querySelectorAll('script'))
      scripts.forEach((oldScript) => {
        const newScript = document.createElement('script')
        Array.from(oldScript.attributes).forEach((attr) =>
          newScript.setAttribute(attr.name, attr.value)
        )
        newScript.appendChild(document.createTextNode(oldScript.innerHTML))
        if (oldScript.parentNode) {
          oldScript.parentNode.replaceChild(newScript, oldScript)
        }
      })
    }
  }, [embedCode])

  return (
    <div
      className={classNames(css.EmbedSocialMedia__container, {
        [css['EmbedSocialMedia__container--youtube']]: channel === 'Youtube',
        [css['EmbedSocialMedia__container--tiktok']]: channel === 'TikTok',
      })}
      ref={containerRef}
    />
  )
}

export default EmbedSocialMedia
