import useFeatureFlag from 'shared/hooks/useFeatureFlag/useFeatureFlag'

const useCategorizeAnyRegAdd = () => {
  const { flagValue } = useFeatureFlag(
    '24e_ff_regex_categorize_any_reg_add_web',
    false
  )

  return flagValue
}

export default useCategorizeAnyRegAdd
