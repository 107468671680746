import React from 'react'
import classNames from 'classnames'

import css from './Checkbox.styles.scss'
import Check from '../../icons/Check.svg'

import { CheckboxProps } from './Checkbox.types'

const checkboxSizeClassname = (size: CheckboxProps['size']) => {
  switch (size) {
    case 'sm':
      return css.checkbox__small
    case 'md':
      return css.checkbox__medium
    case 'lg':
      return css.checkbox__large
    default:
      return css.checkbox__medium
  }
}

const checkboxTypeClassname = (type: CheckboxProps['type']) => {
  switch (type) {
    case 'primary':
      return css.checkbox__primary
    case 'secondary':
      return css.checkbox__secondary
    default:
      return css.checkbox__primary
  }
}

const Checkbox: React.FC<CheckboxProps> = ({
  type = 'primary',
  size,
  className,
  invalid = false,
  inputProps = {},
}) => {
  const CheckboxClasses = classNames(
    css.checkbox__wrapper,
    checkboxTypeClassname(type),
    checkboxSizeClassname(size),
    {
      [css.checkbox__invalid]: invalid,
    },
    className
  )

  return (
    <span className={CheckboxClasses}>
      <input
        type="checkbox"
        {...inputProps}
        className={classNames(css.checkbox__reset, css.checkbox__input)}
      />
      <div aria-hidden="true" className={css.checkbox__check_wrapper}>
        <Check className={css.checkbox__check} />
      </div>
    </span>
  )
}

export default Checkbox
