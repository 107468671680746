export interface DiscountProps {
  className?: string
}

export default function Discount({ className }: DiscountProps) {
  return (
    <svg
      className={className}
      fill="none"
      height="16"
      viewBox="0 0 14 15"
      width="16"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        clipRule="evenodd"
        d="M1.3125 3.70378C1.3125 2.81782 2.03071 2.09961 2.91667 2.09961H6.85846C7.43862 2.09961 7.99502 2.33008 8.40526 2.74031L12.0761 6.41117C13.1582 7.49325 13.1582 9.24764 12.0761 10.3297L9.54261 12.8632C8.46053 13.9453 6.70614 13.9453 5.62406 12.8632L1.9532 9.19237C1.54297 8.78213 1.3125 8.22573 1.3125 7.64557V3.70378ZM2.91667 2.97461C2.51396 2.97461 2.1875 3.30107 2.1875 3.70378V7.64557C2.1875 7.99367 2.32578 8.32751 2.57192 8.57365L6.24278 12.2445C6.98315 12.9849 8.18352 12.9849 8.92389 12.2445L11.4574 9.711C12.1978 8.97063 12.1978 7.77026 11.4574 7.02989L7.78654 3.35903C7.5404 3.11289 7.20656 2.97461 6.85846 2.97461H2.91667ZM4.86111 5.30794C4.67318 5.30794 4.52083 5.46029 4.52083 5.64822C4.52083 5.83615 4.67318 5.9885 4.86111 5.9885C5.04904 5.9885 5.20139 5.83615 5.20139 5.64822C5.20139 5.46029 5.04904 5.30794 4.86111 5.30794ZM3.64583 5.64822C3.64583 4.97704 4.18993 4.43294 4.86111 4.43294C5.53229 4.43294 6.07639 4.97704 6.07639 5.64822C6.07639 6.3194 5.53229 6.8635 4.86111 6.8635C4.18993 6.8635 3.64583 6.3194 3.64583 5.64822Z"
        fill="#252324"
        fillRule="evenodd"
      />
    </svg>
  )
}
