import React from 'react'
import PaymentPreferenceCheckbox from 'src/routes/(registry)/list/[slug]/components/CashFundPreferencesDrawer/components/PaymentPreferenceCheckbox/PaymentPreferenceCheckbox'
import PaymentPreferenceTextField from 'src/routes/(registry)/list/[slug]/components/CashFundPreferencesDrawer/components/PaymentPreferenceTextField/PaymentPreferenceTextField'
import classNames from 'classnames'
import { PaymentPreferenceFormGroupProps } from 'src/routes/(registry)/list/[slug]/components/CashFundPreferencesDrawer/components/PaymentPreferenceFormGroup/PaymentPreferenceFormGroup.types'
import {
  CashFundPaymentPreference,
  PaymentAccountField,
} from 'src/types/registry'
import css from './PaymentPreferenceFormGroup.styles.scss'

const PaymentPreferenceFormGroup: React.FC<PaymentPreferenceFormGroupProps> = ({
  clearError,
  errors = {},
  onPreferenceChanged,
  preference,
}) => {
  const formGroupClasses = classNames(css.PaymentPreferenceFormGroup, {
    [css.PaymentPreferenceFormGroup__Selected]: !!preference?.selected,
  })

  const fieldsClasses = classNames(css.PaymentPreferenceFormGroup__Fields, {
    [css.PaymentPreferenceFormGroup__Fields__Hidden]: !preference?.selected,
  })

  const handlePreferenceChange = (newPreference: CashFundPaymentPreference) =>
    onPreferenceChanged && onPreferenceChanged(newPreference)

  const handleInputChange = (
    field: PaymentAccountField,
    index: number,
    value?: string | null
  ) => {
    const newField = {
      ...field,
      value,
    } as PaymentAccountField

    const newFieldsArr: PaymentAccountField[] = []
    if (preference?.fields) {
      newFieldsArr.push(...preference.fields)
      newFieldsArr[index] = newField
    } else {
      newFieldsArr.push(newField)
    }

    handlePreferenceChange({
      ...preference,
      fields: newFieldsArr,
    })

    clearError(field.name)
  }

  const handleSelectionChanged = (isSelected: boolean) => {
    handlePreferenceChange({
      ...preference,
      selected: isSelected,
    })
  }

  return (
    <div className={formGroupClasses}>
      <PaymentPreferenceCheckbox
        image={preference.image}
        key={preference.key}
        name={preference.name}
        selected={!!preference?.selected}
        onCheckChanged={handleSelectionChanged}
      />
      {preference?.fields && preference?.fields?.length > 0 && (
        <div className={fieldsClasses} data-testid={`${preference.key}_fields`}>
          {preference?.fields?.map((field, index) => (
            <PaymentPreferenceTextField
              {...field}
              errorMessage={errors[field.name]}
              key={field.name}
              onValueChanged={(value) => handleInputChange(field, index, value)}
            />
          ))}
        </div>
      )}
    </div>
  )
}

export default PaymentPreferenceFormGroup
