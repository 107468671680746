import React from 'react'
import { Calendar, Shipping1, SimpleShipping } from 'baby-design/icons'
import { InfoBanner } from './components'
import { dataTestId } from '../../functions'
import css from './Info.styles.scss'

const Info: React.FC = () => (
  <div className={css.Info} data-testid={dataTestId('info')}>
    <InfoBanner icon={<SimpleShipping className={css['Info__icon--fill']} />}>
      <span>
        <strong>Free shipping</strong> for your friends & family on gifts
        purchased from the Babylist Shop
      </span>
    </InfoBanner>
    <InfoBanner icon={<Shipping1 className={css['Info__icon--stroke']} />}>
      <span>
        Bundles ship every 2 weeks or you can select <strong>ship now</strong>{' '}
        on the shipments page
      </span>
    </InfoBanner>
    <InfoBanner icon={<Calendar className={css['Info__icon--fill']} />}>
      <span>
        We'll <strong>automatically</strong> bundle your items until 14 days
        before your baby's arrival date
      </span>
    </InfoBanner>
  </div>
)

export default Info
