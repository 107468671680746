import { useContext, useEffect, useState } from 'react'
import css from './AddUpdateCategoryDrawer.styles.scss'
//@ts-ignore
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd'
import { Registry } from 'src/types/registry'
import { Lock } from 'baby-design/icons'
import { RegItemCategory } from 'src/types/regItemCategory'
import { useMutation, useQueryClient } from '@tanstack/react-query'
import {
  FETCH_REGISTRY_KEY,
  createCategory,
  updateCategory,
} from 'src/api/queries'
import { successMessage, errorMessage } from 'lib/flash-message'
import { TextInput, Checkbox, NavLink, RightDrawer } from 'baby-design'
import NiceModal, { useModal } from '@ebay/nice-modal-react'
import { useRegistry } from '../../utils/useQueries'
import ConfirmUnsavedChangesModal from '../ConfirmUnsavedChangesModal/ConfirmUnsavedChangesModal'
import RegistryContext from 'src/contexts/registry'

interface AddUpdateCategoryDrawerProps {
  category?: RegItemCategory
}

interface AddUpdateCategoryDrawerPropsDecorator
  extends AddUpdateCategoryDrawerProps {
  registry: Registry
}

interface tempRegItemCategory {
  id: number
  title: string
  isPrivate: boolean
  allowsManualAdd: boolean
  position: number
}

function AddUpdateCategoryDrawer({
  registry,
  category,
}: AddUpdateCategoryDrawerPropsDecorator) {
  const modal = useModal()
  const queryClient = useQueryClient()
  const { handleCategoryUpdated } = useContext(RegistryContext)
  const [editingCategory, setCategory] = useState<
    RegItemCategory | tempRegItemCategory
  >({
    title: '',
    isPrivate: false,
    position: 0,
  })

  useEffect(() => {
    if (!modal.visible) {
      setCategory({
        title: '',
        isPrivate: false,
        position: 0,
      })
    } else {
      if (category) setCategory(category)
    }
  }, [modal.visible, category])

  const mutateCategory = useMutation({
    mutationFn: async (category: RegItemCategory) => {
      if (category?.id) return await updateCategory(registry.id, category)
      return await createCategory(registry.id, category)
    },
    onSuccess: ({ category }: { category: RegItemCategory }) => {
      queryClient.invalidateQueries({
        queryKey: [FETCH_REGISTRY_KEY, registry.id],
      })
      handleCategoryUpdated(category)
      successMessage(`Categories successfully updated!`)

      modal.resolve(category)
      modal.hide()
    },
    onError: (error: any) => {
      console.error(error)
      errorMessage(
        error?.error?.message ||
          'Failed to add new category. Try refreshing the page. If the problem persists, contact support.'
      )
    },
  })

  const hasUnsavedChanges = Object.keys(editingCategory).some(
    (key) =>
      category && editingCategory[key as keyof {}] !== category[key as keyof {}]
  )

  const handleClose = async () => {
    if (mutateCategory.isLoading) {
      return
    }

    if (hasUnsavedChanges) {
      const confirmUnsavedChanges: boolean | undefined = await NiceModal.show(
        ConfirmUnsavedChangesModal
      )

      if (!confirmUnsavedChanges) {
        return
      }
    }

    modal.resolve(undefined)
    modal.hide()
  }

  const handleItemTitleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setCategory({ ...editingCategory, title: e.target.value })
  }

  const onSave = async () => {
    mutateCategory.mutate(editingCategory)
  }

  return (
    <RightDrawer
      contextualIcon={
        <NavLink
          variant="eggplant"
          size="md"
          onClick={onSave}
          disabled={mutateCategory.isLoading}
        >
          Save
        </NavLink>
      }
      handleClose={handleClose}
      handleDismount={modal.remove}
      isOpen={modal.visible}
      title={'Add Category'}
      mainIcon="back"
    >
      <div className={css.AddUpdateCategoryDrawer}>
        <TextInput
          inputProps={{
            placeholder: 'Category Name',
            value: editingCategory.title,
            onChange: handleItemTitleChange,
          }}
          size="md"
        />

        <div className={css.AddUpdateCategoryDrawer__checkbox}>
          <Checkbox
            type="secondary"
            size="md"
            inputProps={{
              checked: editingCategory.isPrivate,
              value: 'isPrivate',
              onChange: () =>
                setCategory({
                  ...editingCategory,
                  isPrivate: !editingCategory.isPrivate,
                }),
            }}
          />
          <span className={css.AddUpdateCategoryDrawer__checkboxLabel}>
            <span className={css.AddUpdateCategoryDrawer__checkboxLabelPrivacy}>
              Set to Private <Lock />
            </span>
            <span className={css.AddUpdateCategoryDrawer__checkboxSubtext}>
              Category and all items in this category will be hidden from
              guests.
            </span>
          </span>
        </div>
      </div>
    </RightDrawer>
  )
}

export default NiceModal.create(
  ({ category }: AddUpdateCategoryDrawerProps) => {
    const registry = useRegistry()

    if (!registry) {
      return <></>
    }

    return <AddUpdateCategoryDrawer registry={registry} category={category} />
  }
)
