import useFeatureFlag from 'shared/hooks/useFeatureFlag/useFeatureFlag'

const useMarkPurchasedEnabled = () => {
  const { flagValue: isMarkPurchasedEnabled } = useFeatureFlag(
    '24d_ff_regex_mark_unmark_web',
    false
  )

  return isMarkPurchasedEnabled
}

export default useMarkPurchasedEnabled
