import { apiV3RegItemReservationsPath, apiV3ReservationsPath } from 'lib/urls'
import { useMutation, useQuery } from '@tanstack/react-query'
import { Reservation } from 'src/types/reservation'
import fetch from 'lib/fetch'
import {
  CreateReservationRequest,
  CreateReservationResponse,
} from 'src/routes/(registry)/api/types'

export const fetchReservationsForRegItem = (
  regItemId: number
): Promise<Reservation[]> => fetch(apiV3RegItemReservationsPath(regItemId))

const REG_ITEM_RESERVATIONS_QUERY_KEY = 'reg_item_reservations'

export const regItemReservationsKey = (regItemId: number) => [
  REG_ITEM_RESERVATIONS_QUERY_KEY,
  regItemId,
]

export const useRegItemReservations = (regItemId: number) =>
  useQuery({
    enabled: !!regItemId,
    queryFn: () => fetchReservationsForRegItem(regItemId),
    queryKey: [REG_ITEM_RESERVATIONS_QUERY_KEY, regItemId],
  })

const createRegItemReservation = (data: CreateReservationRequest) =>
  fetch(apiV3ReservationsPath, {
    method: 'POST',
    body: JSON.stringify(data),
  })

interface UseCreateRegItemReservationProps {
  onSuccess?: (payload: CreateReservationResponse) => void
  onError?: (errors: any) => void
}

export const useCreateRegItemReservation = ({
  onSuccess,
  onError,
}: UseCreateRegItemReservationProps) =>
  useMutation({
    mutationFn: (data: CreateReservationRequest) =>
      createRegItemReservation(data),
    onSuccess,
    onError,
  })
