import { ErrorResponseBody } from 'src/api/types'

export const fieldErrorsByFieldName = (
  errorResponse?: ErrorResponseBody
): Record<string, string | null | undefined> => {
  const { fields } = errorResponse?.error || {}
  if (!fields) return {}

  return fields?.reduce((hash, fieldError) => {
    hash[fieldError.name] = fieldError.messages?.join('\n')
    return hash
  }, {} as any)
}

export const removeFieldErrorsForField = (
  errors: ErrorResponseBody | undefined,
  field: string
): ErrorResponseBody | undefined => {
  if (!errors) return undefined

  const { error } = errors
  const { fields = [], ...rest } = error
  const filteredFields = fields.filter((f) => f.name !== field)

  return {
    error: {
      ...rest,
      fields: filteredFields,
    },
  }
}
