import React from 'react'
import { kebabCase } from 'lodash'
import { Badge, RadioButton } from 'baby-design'
import { RadioFieldProps } from './RadioField.types'
import css from './RadioField.styles.scss'

const RadioField: React.FC<RadioFieldProps> = ({
  consolidationChoiceInputProps,
  label,
  description = '',
  recommended = false,
  checked,
  onChange,
}) => {
  const inputId = `ConsolidatedShippingFormRadioField-${kebabCase(label)}`

  return (
    <div className={css.RadioField}>
      <div className={css.RadioField__buttonContainer}>
        <RadioButton
          size="md"
          type="primary"
          inputProps={{
            ...consolidationChoiceInputProps,
            onChange,
            checked,
            id: inputId,
          }}
        />
      </div>
      <div className={css.RadioField__label}>
        <div className={css.RadioField__label__primaryContainer}>
          <label
            className={css.RadioField__label__primaryText}
            htmlFor={inputId}
          >
            {label}
          </label>
          {recommended && (
            <Badge
              fill="eggplant"
              label="Recommended"
              size="small"
              variant="general"
            />
          )}
        </div>
        {description && (
          <span className={css.RadioField__label__secondaryText}>
            {description}
          </span>
        )}
      </div>
    </div>
  )
}

export default RadioField
