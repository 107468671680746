export class SearchTerm {
  public readonly value: string

  // Note we also keep these terms here: packs/registry/app/services/bl_registry/unified_search/product_ads.rb
  public static readonly babylistHealthPartialMatchKeywords = [
    'breast',
    'breastpump',
    'breasts',
    'nursing',
    'pump',
    'pumping',
    'pumps',
  ]

  public static readonly babylistHealthExactKeywords = [
    'baby buddha',
    'babybuddha',
    'bodily',
    'breastfeeding',
    'breastmilk collector',
    'dr brown',
    'dr browns',
    "dr. brown's",
    'elvie',
    'flange',
    'flanges',
    'kindred bravely',
    'lansinoh',
    'legendairy',
    'medela',
    'milk bag',
    'milk bags',
    'milk collection',
    'milk collection cup',
    'milk storage',
    'milk storage bags',
    'momcozy',
    'nipple cream',
    'nipple shield',
    'nipple shields',
    'replacement parts',
    'spectra',
    'willow',
  ]

  constructor(term: string) {
    this.value = term.trim().toLowerCase()
  }

  get hasBabylistHealthKeywords() {
    if (!this.value) return false
    const pattern = this.babylistHealthTermsPattern()
    const regex = new RegExp(pattern, 'i')
    return regex.test(this.value)
  }

  private babylistHealthTermsPattern() {
    const partialWords = SearchTerm.babylistHealthPartialMatchKeywords
      .map((term) => `${term}`)
      .join('|')
    const exactWords = SearchTerm.babylistHealthExactKeywords
      .map((term) => `${term}`)
      .join('|')
    return `\\b(${partialWords})\\b|^(${exactWords})$`
  }
}
