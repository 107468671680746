import classNames from 'classnames'
import { StoreNavCategory } from 'src/types/storeNavCategory'
import css from './side-nav.scss'
import { CategoryWithChildren } from './CategoryWithChildren'

export type OnCategoryClick = (category: StoreNavCategory) => null
interface SideNavProps {
  activeSlugSet?: string[]
  categoryTree: StoreNavCategory[]
  navCategory?: StoreNavCategory
  onCategoryClick?: OnCategoryClick
  sidebarTitle?: string
}

export const SideNav = ({
  activeSlugSet = [],
  categoryTree,
  navCategory,
  sidebarTitle,
  onCategoryClick,
}: SideNavProps) => (
  <nav className="hidden-xs">
    {sidebarTitle && (
      <>
        <div className="h6 text-bold mbs">{sidebarTitle}</div>
        <div className="mrs" />
      </>
    )}
    <ul
      className={classNames('catalog-side-nav list-unstyled', {
        [css.catalogMobileNav]: false,
      })}
    >
      {[navCategory, ...categoryTree]
        .filter((category) => category && !category.hidden)
        .map((category) =>
          category ? (
            <CategoryWithChildren
              activeSlugSet={activeSlugSet}
              category={category}
              key={category.urlSlug}
              parentSlugSet={[]}
              onCategoryClick={onCategoryClick}
            />
          ) : null
        )}
    </ul>
  </nav>
)
