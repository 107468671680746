import { useState } from 'react'
import { Reservation } from 'src/types/reservation'

interface MarkPurchaseFormFieldArgs<T> {
  name: keyof Reservation
  defaultValue?: T
  errorMessage?: (value: T | undefined) => string | undefined
}

export interface MarkPurchaseFormField<T> {
  clear: () => void
  error: string | undefined
  name: keyof Reservation
  updateValue: (val: T | undefined) => void
  value: T | undefined
  validate: () => boolean
}

const useMarkPurchaseFormField = <T>({
  defaultValue,
  errorMessage,
  name,
}: MarkPurchaseFormFieldArgs<T>): MarkPurchaseFormField<T> => {
  const [value, setValue] = useState<T | undefined>(defaultValue)
  const [error, setError] = useState<string>()

  const clear = () => {
    setValue(defaultValue)
    setError(undefined)
  }

  const validate = () => {
    const message = errorMessage?.(value)
    setError(message)
    return !message
  }

  const updateValue = (val: T | undefined) => {
    setError(undefined)
    setValue(val)
  }

  return {
    clear,
    error,
    name,
    value,
    updateValue,
    validate,
  }
}

export default useMarkPurchaseFormField
