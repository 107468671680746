import classNames from 'classnames'
import Select, {
  components,
  type ControlProps,
  type DropdownIndicatorProps,
  type GroupBase,
} from 'react-select'

import { useEffect, useState } from 'react'
import css from './SelectInput.styles.scss'

import { SelectInputCustomProps, SelectInputProps } from './SelectInput.types'

function Control<O, IM extends boolean, G extends GroupBase<O>>({
  children,
  ...props
}: ControlProps<O, IM, G>) {
  // @ts-ignore
  const { LeftIcon } = props.selectProps

  const { isDisabled, isFocused, menuIsOpen } = props
  return (
    <components.Control {...props}>
      {LeftIcon && (
        <span className={classNames(css.input__icon, css.input__left_icon)}>
          <LeftIcon {...{ isDisabled, isFocused, menuIsOpen }} />
        </span>
      )}
      {children}
    </components.Control>
  )
}

function DropdownIndicator<O, IM extends boolean, G extends GroupBase<O>>(
  props: DropdownIndicatorProps<O, IM, G>
) {
  // @ts-ignore
  // eslint-disable-next-line
  const { RightIcon } = props.selectProps

  const { isDisabled, isFocused } = props
  return (
    <components.DropdownIndicator {...props}>
      {RightIcon ? (
        <span className={classNames(css.input__icon, css.input__right_icon)}>
          <RightIcon {...{ isDisabled, isFocused }} />
        </span>
      ) : (
        <></>
      )}
    </components.DropdownIndicator>
  )
}

const selectSizeClassname = (size: SelectInputCustomProps['size']) => {
  switch (size) {
    case 'sm':
      return css.input__small
    case 'md':
      return css.input__medium
    case 'lg':
      return css.input__large
    default:
      return css.input__medium
  }
}

const selectTypeClassname = (type: SelectInputCustomProps['type']) => {
  if (type === 'inverted') {
    return css.input__inverted
  }

  return css.input__primary
}

const SelectInput = <
  Option,
  IsMulti extends boolean = false,
  Group extends GroupBase<Option> = GroupBase<Option>,
>({
  size,
  label,
  placeholder,
  status,
  LeftIcon,
  RightIcon,
  selectClassName,
  type = 'primary',
  onFocus = () => null,
  onBlur = () => null,
  ...props
}: SelectInputProps<Option, IsMulti, Group>) => {
  const [isFocused, setIsFocused] = useState<boolean>(false)

  const VariantSpecificInputClasses = classNames(
    selectSizeClassname(size),
    selectTypeClassname(type),
    {
      [css.input__error]: status?.type === 'error',
      [css.input__focused]: isFocused,
      [css.input__disabled]: props.isDisabled,
    }
  )

  const SelectInputClasses = classNames(
    css.SelectInput,
    VariantSpecificInputClasses,
    selectClassName
  )

  // TODO: Once we are on React 18 we can use the useId() hook to generate a unique ID for the tooltip
  // Until then this little trick will convert a random number to a base 36 string
  const [uuid1] = useState<string>(Math.random().toString(36).substring(2))
  const labelMessageId = uuid1
  const [uuid2] = useState<string>(Math.random().toString(36).substring(2))
  const errorMessageId = uuid2

  const shouldShowErrorMessage = status?.type === 'error' && status.message

  // The below is a workaround to prevent the select component from rendering on the server
  // due to a known SSR compatibility issue with react-select:
  // https://github.com/JedWatson/react-select/issues/5555
  const [clientSide, setClientSide] = useState(false)

  useEffect(() => {
    setClientSide(true)
  }, [])

  return (
    <div className={SelectInputClasses} data-testid="select-component">
      {label && (
        <div className={css.input__label} id={labelMessageId}>
          {label}
        </div>
      )}
      {clientSide && (
        <Select
          // @ts-ignore
          LeftIcon={LeftIcon}
          RightIcon={RightIcon}
          aria-errormessage={
            shouldShowErrorMessage ? errorMessageId : undefined
          }
          aria-invalid={status?.type === 'error'}
          aria-labelledby={labelMessageId}
          classNames={{
            control: () => css.input__control,
            valueContainer: () => css.input__value_container,
            placeholder: () => css.input__placeholder,
            singleValue: ({ data }) =>
              classNames(css.input__value, {
                [css.input__placeholder]: !(data as any).label,
              }),
            dropdownIndicator: () => css.input__dropdown_indicator,
            option: (state) =>
              classNames(css.input__option, {
                [css.input__placeholder]: !state.label,
                [css.input__option_focused]: state.isFocused,
                [css.input__option_selected]: state.isSelected,
              }),
            menu: () => css.input__menu,
            menuPortal: () =>
              classNames(css.input__menuPortal, VariantSpecificInputClasses),
          }}
          components={{
            IndicatorSeparator: null,
            Control,
            DropdownIndicator,
          }}
          formatOptionLabel={(data: any) => data.label || placeholder}
          placeholder={placeholder}
          onFocus={(e) => {
            setIsFocused(true)
            onFocus(e)
          }}
          // @ts-ignore
          onBlur={(e) => {
            setIsFocused(false)
            onBlur(e)
          }}
          {...props}
        />
      )}
      {shouldShowErrorMessage && (
        <div className={css.input__error_message} id={errorMessageId}>
          {status.message}
        </div>
      )}
    </div>
  )
}

export default SelectInput
