import classNames from 'classnames'

import Link from 'components/link'
import { helloBabyPostPath } from 'lib/urls'
import PostCard from '../PostCard'
import PostCardRow from '../post-card-row'

import css from './TopicSection.styles.scss'
import { StyledSubHeading } from '../../../components/headings'

interface TopicSectionProps {
  topic: Topic
  posts: Post[]
  showAllButton?: boolean
  showTopics?: boolean
  hideLastPostSm?: boolean
  teaserText?: string
}

const TopicSection = ({
  topic,
  posts,
  showAllButton = true,
  showTopics,
  hideLastPostSm = false,
  teaserText,
}: TopicSectionProps) => (
  <div
    className={classNames(css.topicContainer, 'clearfix pbxl mbxl')}
    key={`topic-container-${topic}`}
  >
    <StyledSubHeading className={classNames(css.topicHeader, 'h3 mbm mtn')}>
      {topic.name}
    </StyledSubHeading>
    {teaserText && <div className="mbl">{teaserText}</div>}
    <PostCard
      cardSize="medium"
      image={posts[0].featuredImage?.url}
      slug={posts[0].slug}
      teaserText={posts[0].teaser}
      title={posts[0].title}
    />
    <div className="clearfix">
      <PostCardRow
        className="mtxl"
        columns={3}
        hideLastPostSm={hideLastPostSm}
        posts={posts.slice(1, posts.length)}
        showTopics={showTopics}
      />
    </div>
    {showAllButton ? (
      <div className="text-center">
        <Link
          className={classNames(css.viewAllButton, 'btn-empty btn btn-info')}
          to={helloBabyPostPath(topic.slug)}
        >
          {topic.slug === 'sample-registries'
            ? 'View all sample registries'
            : `View all in ${topic.name}`}
        </Link>
      </div>
    ) : null}
  </div>
)

export default TopicSection
