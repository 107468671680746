import { QueryClient } from '@tanstack/react-query'
import { FETCH_REG_ITEMS_KEY } from 'src/api/queries'
import { RegItem } from 'src/types/regItem'
import { Reservation } from 'src/types/reservation'
import { regItemReservationsKey } from 'src/routes/(registry)/api/reservations'

type PagesArray = { regItems: RegItem[] }[]

type RegItemQueryData = { pages: PagesArray; pageParams: any } | undefined

const modifyRegItemStore = ({
  data,
  regItemId,
  modifyStoreCallback,
}: {
  data: RegItemQueryData
  regItemId: number
  modifyStoreCallback: (
    pagesArray: PagesArray,
    pageIndexWithRegItem: number
  ) => PagesArray
}) => {
  const oldPagesArray = [...(data?.pages || [])]
  let newPagesArray = [...oldPagesArray]

  const pageIndex = oldPagesArray.findIndex((page) => page.regItems[regItemId])
  if (pageIndex >= 0) {
    newPagesArray = modifyStoreCallback(newPagesArray, pageIndex)
  }

  return {
    pages: newPagesArray,
    pageParams: { ...data?.pageParams },
    updatedAt: new Date().toISOString(),
  }
}

const replaceRegItem = (
  pagesArray: PagesArray,
  pageIndexWithRegItem: number,
  updatedRegItem: RegItem
) => {
  const page = pagesArray[pageIndexWithRegItem]
  page.regItems[updatedRegItem.id] = updatedRegItem
  return pagesArray
}

export const optimisticallyUpdateRegItem = ({
  queryClient,
  updatedRegItem,
}: {
  queryClient: QueryClient
  updatedRegItem: RegItem
}) => {
  queryClient.setQueryData(
    [FETCH_REG_ITEMS_KEY, updatedRegItem.registry.id],
    (data: RegItemQueryData) =>
      modifyRegItemStore({
        data,
        regItemId: updatedRegItem.id,
        modifyStoreCallback: (pagesArray, pageIndexWithRegItem) =>
          replaceRegItem(pagesArray, pageIndexWithRegItem, updatedRegItem),
      })
  )
}

export const optimisticallyUpdateRegItems = ({
  queryClient,
  updatedRegItems,
  registryId,
}: {
  queryClient: QueryClient
  updatedRegItems: RegItem[]
  registryId: number
}) => {
  queryClient.setQueryData(
    [FETCH_REG_ITEMS_KEY, registryId],
    (data: RegItemQueryData) =>
      updatedRegItems.reduce(
        (queryData, updatedRegItem) =>
          modifyRegItemStore({
            data: queryData,
            regItemId: updatedRegItem.id,
            modifyStoreCallback: (pagesArray, pageIndexWithRegItem) =>
              replaceRegItem(pagesArray, pageIndexWithRegItem, updatedRegItem),
          }),
        data
      )
  )
}

export const optimisticallyDeleteRegItem = ({
  queryClient,
  deletedRegItem,
}: {
  queryClient: QueryClient
  deletedRegItem: RegItem
}) => {
  queryClient.setQueryData(
    [FETCH_REG_ITEMS_KEY, deletedRegItem.registry.id],
    (data: RegItemQueryData) =>
      modifyRegItemStore({
        data,
        regItemId: deletedRegItem.id,
        modifyStoreCallback: (pagesArray, pageIndexWithRegItem) => {
          delete pagesArray[pageIndexWithRegItem].regItems[deletedRegItem.id]

          return pagesArray
        },
      })
  )
}

export const invalidateRegItemsQuery = ({
  queryClient,
  registryId,
}: {
  queryClient: QueryClient
  registryId: number
}) => {
  queryClient.invalidateQueries({
    queryKey: [FETCH_REG_ITEMS_KEY, registryId],
  })
}

export const optimisticallySaveReservation = (
  queryClient: QueryClient,
  reservation: Reservation
) => {
  queryClient.setQueryData(
    regItemReservationsKey(reservation.regItemId),
    (data: Reservation[] = []) => [...data, reservation]
  )
}

export const invalidateRegItemReservationsQuery = (
  queryClient: QueryClient,
  regItemId: number
) => {
  queryClient.invalidateQueries({
    queryKey: regItemReservationsKey(regItemId),
    refetchType: 'none',
  })
}

export const hydrateReservationsCache = (
  queryClient: QueryClient,
  response: any
) => {
  const regItems = response.regItems
    ? (Object.values(response.regItems) as RegItem[])
    : []

  regItems.forEach((regItem: RegItem) => {
    if (regItem.reservations) {
      queryClient.setQueryData(
        regItemReservationsKey(regItem.id),
        () => regItem.reservations || []
      )
    }
  })
}
