import React from 'react'
import classNames from 'classnames'
import { ToastImageProps } from './ToastImage.types'
import css from './ToastImage.styles.scss'

const ToastImage: React.FC<ToastImageProps> = ({ alt, className, src }) => (
  <div className={classNames(css.ToastImage, className)}>
    {/* eslint-disable-next-line jsx-a11y/alt-text */}
    <img
      className={css.ToastImage__Background}
      data-testid="toast-image-background"
      role="presentation"
      src={src}
    />
    <img
      alt={alt}
      className={css.ToastImage__Foreground}
      data-testid="toast-image-foreground"
      src={src}
    />
  </div>
)

export default ToastImage
