/* eslint-disable import/no-extraneous-dependencies */
import React from 'react'
import Toast from 'baby-design/components/Toast/Toast'
import { CheckCircle } from 'baby-design/icons'
import { NotifyMeSuccessToastProps } from './NotifyMeSuccessToast.types'
import css from './NotifyMeSuccessToast.styles.scss'

const NotifyMeSuccessToast: React.FC<NotifyMeSuccessToastProps> = ({
  isOpen,
}) => (
  <Toast
    imageProps={{
      image: <CheckCircle filled className={css.NotifyMeSuccessToast__Image} />,
    }}
    isOpen={isOpen}
    text="We&rsquo;ll email you when this item is back in stock!"
    variant="basic"
  />
)

export default NotifyMeSuccessToast
