import { Button } from 'baby-design'
import ScrollLockModal from 'shared/ScrollLockModal'
import NiceModal, { useModal } from '@ebay/nice-modal-react'
import css from './ConfirmUnsavedChangesModal.styles.scss'

interface ConfirmUnsavedChangesModalProps {}

export default NiceModal.create(({}: ConfirmUnsavedChangesModalProps) => {
  const modal = useModal()

  const handleClose = () => {
    modal.resolve(false)
    modal.remove()
  }

  const handleLeaveClick = () => {
    //close modal and drawer
    modal.resolve(true)
    modal.remove()
  }

  const primaryButton = (
    <Button onClick={handleLeaveClick} size="md" variant="destructive">
      Leave
    </Button>
  )

  const cancelButton = (
    <Button onClick={handleClose} size="md" variant="secondary">
      Go Back
    </Button>
  )

  return (
    <ScrollLockModal
      handleClose={handleClose}
      isOpen={modal.visible}
      primaryButton={primaryButton}
      secondaryButton={cancelButton}
    >
      <div className={css.ConfirmUnsavedChangesModal}>
        <h2>Are you sure?</h2>
        <p>You have unsaved changes. Are you sure you want to close?</p>
      </div>
    </ScrollLockModal>
  )
})
