import { useEffect } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import classNames from 'classnames'
import { numberToCurrency } from 'lib/money'
import { CONFIG, UPDATE_CART_SUMMARY } from 'shared/constants'
import { setApplyRegistryDiscount } from 'cart/actions'
import { LinkButton } from 'components/buttons'
import { updateCart } from 'cart/api'
import { errorMessage } from 'lib/flash-message'
import { SHOULD_SAVE_REGISTRY_DISCOUNT_KEY } from 'cart/constants'

export const RegistryDiscountDisplay = ({
  amount,
  className,
  isDiscountActive,
  setApplyRegistryDiscount,
  eligibleForMultiuseDiscount,
}) => {
  const handleApply = (applyRegistryDiscount) => {
    updateCart({ applyRegistryDiscount })
      .then(() => {
        localStorage.setItem(
          SHOULD_SAVE_REGISTRY_DISCOUNT_KEY,
          !applyRegistryDiscount
        )
        setApplyRegistryDiscount(applyRegistryDiscount)
        PubSub.publish(UPDATE_CART_SUMMARY)
      })
      .catch(() => {
        errorMessage()
      })
  }

  useEffect(() => {
    const saveDiscountForLater = localStorage.getItem(
      SHOULD_SAVE_REGISTRY_DISCOUNT_KEY
    )
    if (!saveDiscountForLater) {
      handleApply(true)
    }
  }, [])

  return (
    <p className={classNames('h6', className)}>
      {isDiscountActive ? (
        <span className="flex">
          <span className="flex-grow">
            <span className="flex">
              - {CONFIG.store.registryDiscountPctDisplay} Registry Discount{' '}
              <br />
              <LinkButton
                className="display-inline pan man"
                onClick={() => handleApply(false)}
              >
                <i
                  className="fa fa-icn fa-close h7 phs"
                  data-testid="remove-registry-discount"
                />
              </LinkButton>
            </span>
            {eligibleForMultiuseDiscount ? null : (
              <span className="pls">(single-use)</span>
            )}
          </span>
          <span>-{numberToCurrency(amount)}</span>{' '}
        </span>
      ) : (
        <LinkButton
          className="man pan text-bold"
          onClick={() => handleApply(true)}
        >
          - Apply {CONFIG.store.registryDiscountPctDisplay} Registry Discount
        </LinkButton>
      )}
    </p>
  )
}

RegistryDiscountDisplay.propTypes = {
  amount: PropTypes.oneOfType([PropTypes.object, PropTypes.number]), // Big.js object
  className: PropTypes.string,
  isDiscountActive: PropTypes.bool.isRequired,
  setApplyRegistryDiscount: PropTypes.func.isRequired,
}

RegistryDiscountDisplay.defaultProps = {
  className: undefined,
}

const mapStateToProps = (state) => {
  return {
    isDiscountActive: state.cart.applyRegistryDiscount,
  }
}

export default connect(mapStateToProps, { setApplyRegistryDiscount })(
  RegistryDiscountDisplay
)
