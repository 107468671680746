import React from 'react'
import PurchasesSection from 'src/routes/(registry)/list/[slug]/components/EditRegItemDrawer/components/PurchasesSection/PurchasesSection'
import { numberToCurrency } from 'lib/money'
import RecordContributionModal from 'src/routes/(registry)/list/[slug]/components/EditRegItemDrawer/components/RecordContributionModal/RecordContributionModal'
import { CashFundIcon } from 'baby-design/icons'
import ContributionBullet from '../../../components/ContributionBullet/ContributionBullet'
import { CrowdfundContributionsSectionProps } from './CrowdfundContributionsSection.types'

const TITLE = 'Contributions'
const RECORD_CONTRIBUTION = 'Record a contribution'

const CrowdfundContributionsSection: React.FC<
  CrowdfundContributionsSectionProps
> = ({ regItem, reservations = [], onRegItemUpdated }) => {
  const totalContributions = reservations.reduce(
    (sum, reservation) => sum + (reservation.giftAmount || 0),
    0
  )

  const totalContributionsAsString = numberToCurrency(totalContributions, {
    truncateZeroCents: true,
    unit: '$',
  }) as string

  return (
    <PurchasesSection
      bullet={ContributionBullet}
      icon={CashFundIcon}
      initialButtonText={RECORD_CONTRIBUTION}
      markAsPurchasedModal={RecordContributionModal}
      regItem={regItem}
      reservations={reservations}
      status={`${totalContributionsAsString} funded`}
      title={TITLE}
      onRegItemUpdated={onRegItemUpdated}
    />
  )
}

export default CrowdfundContributionsSection
