import React, { cloneElement } from 'react'
import { InfoBannerProps } from './InfoBanner.types'
import { dataTestId } from '../../../../functions'
import css from './InfoBanner.styles.scss'

const InfoBanner: React.FC<InfoBannerProps> = ({ children, icon }) => {
  const childrenWithProps = cloneElement(children, {
    className: css.InfoBanner__text,
  })

  return (
    <div className={css.InfoBanner} data-testid={dataTestId('info-banner')}>
      <div className={css.InfoBanner__iconContainer}>{icon}</div>
      <div className={css.InfoBanner__content}>{childrenWithProps}</div>
    </div>
  )
}

export default InfoBanner
