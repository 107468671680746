import React from 'react'
import { Info } from '../../../../../../../../../baby-design/icons'
import css from './TransactionVisibilityBanner.styles.scss'

const TransactionVisibilityBanner: React.FC = () => (
  <div className={css.TransactionVisibilityBanner}>
    <Info className={css.TransactionVisibilityBanner__InfoIcon} />
    <p className={css.TransactionVisibilityBanner__Text}>
      Guests will send funds directly to the account(s) you provide. Babylist
      does not have any visibility into these funds, but guests will mark their
      contributions so you can keep track.
    </p>
  </div>
)

export default TransactionVisibilityBanner
