import { useState } from 'react'
import { termsOfUsePath, privacyPolicyPath } from 'lib/urls'
import Link from 'components/link'
import { useForm } from 'react-hook-form'
import { connect } from 'react-redux'
import { range } from 'lodash'
import { Panel } from 'react-bootstrap'
import DateSelect from 'components/date-select'
import { StyledSubHeading } from 'components/headings'
import { Checkbox, Form } from 'components/forms'
import { SaveButton } from 'components/buttons'
import { successMessage } from 'lib/flash-message'

import { today } from 'lib/date'
import { createValidator, required } from 'lib/form-validation'

import useFeatureFlag from 'shared/hooks/useFeatureFlag/useFeatureFlag'

export const EmailPreferencesForm = ({ submitData, initialValues, user }) => {
  const [submitting, setSubmitting] = useState(false)
  const defaultValues = {
    ...initialValues.user.emailPreferences,
    consentedToPromotionalOffers:
      initialValues.user.consentedToPromotionalOffers,
  }
  delete defaultValues.id
  const { handleSubmit, register, watch, setValue, getValues } = useForm({
    defaultValues,
  })
  const [showOptOutWarning, setShowOptOutWarning] = useState(false)
  const [isUnsubscribeFromAllChecked, setIsUnsubscribeFromAllChecked] =
    useState(false)

  const [arrivalDate, setArrivalDate] = useState(
    initialValues.user?.family?.arrivalDate
  )

  const unsubscribeFromAll = (e) => {
    const { checked } = e.target
    const fields = getValues()
    Object.keys(fields).forEach((key) =>
      checked ? setValue(key, false) : setValue(key, defaultValues[key])
    )
    if (checked) {
      setShowOptOutWarning(true)
    } else {
      setShowOptOutWarning(false)
    }
    setIsUnsubscribeFromAllChecked(checked)
  }

  const renderPregnancyWeeklyForm = () => {
    if (watch('pregnancyWeekly')) {
      const currentYear = today().getFullYear()

      return (
        <Panel className="panel-bg-muted">
          <Panel.Body>
            <div className="pas">
              <div className="h6 text-bold mvn">Bedazzle your emails ✨</div>
              <p className="h6 mvs">
                Personalize your emails by entering your arrival date.
              </p>
              <div style={{ maxWidth: 400 }}>
                <DateSelect
                  label="Arrival Date"
                  value={arrivalDate}
                  years={range(currentYear - 1, currentYear + 2).reverse()}
                  onChange={(e) => setArrivalDate(e)}
                />
              </div>
            </div>
          </Panel.Body>
        </Panel>
      )
    }
  }

  const onSubmit = (emailPreferences) => {
    const { consentedToPromotionalOffers, ...preferences } = emailPreferences
    const data = {
      user: {
        emailPreferences: { ...preferences },
        family: {
          arrivalDate,
        },
        consentedToPromotionalOffers,
      },
    }
    submitData(data).then(() => {
      successMessage('Your communication preferences have been updated!')
    })
  }

  const partnerOffersHelpText = (
    <>
      Promotional offers and updates sent from Babylist partners. See
      <Link url={termsOfUsePath} newWindow>
        {' '}
        Terms of Use{' '}
      </Link>
      and
      <Link url={privacyPolicyPath} newWindow>
        {' '}
        Privacy Policy{' '}
      </Link>
      for details.
      {initialValues.user.consentedToPromotionalOffers && (
        <div className="h6">
          Because you previously opted in to receiving offers and updates from
          approved Babylist partners, you can uncheck this setting to be opted
          out of sharing with any future partners only.
        </div>
      )}
      {initialValues.user.consentedToPromotionalOffers &&
        !showOptOutWarning &&
        !watch('consentedToPromotionalOffers') && (
          <div className="well h7">
            You may still receive emails from existing partners. To stop
            receiving these partners' emails, click the unsubscribe link within
            the email.
          </div>
        )}
    </>
  )

  const { flagValue: enableWxWMomsPreference } = useFeatureFlag(
    '24c_ff_marketing_wxw_preference_update',
    false
  )

  return (
    <div>
      <StyledSubHeading>Communication preferences</StyledSubHeading>

      <Form onSubmit={handleSubmit(onSubmit)}>
        {user.isGiftGiverOnly ? null : (
          <div>
            <div className="h6">
              We're so glad you're here! Choose which emails you want so we can
              customize your inbox.
            </div>

            <div className="mbxl">
              <Checkbox
                disabled={isUnsubscribeFromAllChecked}
                help="Personalized emails about your body, your baby and what you really need to know."
                inputRef={register}
                label={<b>Week-by-Week Pregnancy and Parenting Emails </b>}
                name="pregnancyWeekly"
              />
              {enableWxWMomsPreference && (
                <Checkbox
                  disabled={isUnsubscribeFromAllChecked}
                  help="Personalized emails that support you, the mom, during pregnancy and postpartum."
                  inputRef={register}
                  label={<b>Week-by-Week All About You Emails</b>}
                  name="pregnancyWeeklyMoms"
                />
              )}
              <Checkbox
                disabled={isUnsubscribeFromAllChecked}
                help="A once-a-week round-up of news and noteworthy stories from around the web."
                inputRef={register}
                label={<b>Hello Baby Weekly Newsletter</b>}
                name="newsletter"
              />
              <Checkbox
                disabled={isUnsubscribeFromAllChecked}
                help="Expert tips on how to build your baby registry and find gear for your baby's next stage, plus all the best sales."
                inputRef={register}
                label={<b>Registry & Shopping Emails</b>}
                name="babyRegistry101"
              />
              <Checkbox
                disabled={isUnsubscribeFromAllChecked}
                help="Exclusive deals and information sent from Babylist on behalf of our partners."
                inputRef={register}
                label={<b>Promotions of Brands We Love</b>}
                name="sponsored"
              />
              <Checkbox
                disabled={isUnsubscribeFromAllChecked}
                help="Changes to your registry, surveys and occasional updates about new Babylist features."
                inputRef={register}
                label={<b>Announcements</b>}
                name="announcements"
              />
            </div>
          </div>
        )}

        {renderPregnancyWeeklyForm()}

        {user.isRegistryUser ? (
          <div>
            <StyledSubHeading className="h5">Registry Alerts</StyledSubHeading>
            <div className="mbl">
              <Checkbox
                disabled={isUnsubscribeFromAllChecked}
                help="Get notified when someone purchases a gift from your registry, and when those gifts ship to you."
                inputRef={register}
                label={<b>Purchase Alerts</b>}
                name="reservationAlerts"
              />
              <Checkbox
                disabled={isUnsubscribeFromAllChecked}
                help="Get notified when there are price or stock changes for items on your registry."
                inputRef={register}
                label={<b>Price & Stock Alerts</b>}
                name="priceStockAlerts"
              />
            </div>
          </div>
        ) : null}

        {user.isGiftGiver ? (
          <div>
            <StyledSubHeading className="h5">
              Gift Giver Emails
            </StyledSubHeading>
            <div className="mbl">
              <Checkbox
                disabled={isUnsubscribeFromAllChecked}
                help="Age/stage appropriate clothes, toys and gear recommendations (plus deal alerts!) to help you give the best gifts. "
                inputRef={register}
                label={<b>Gift Advice</b>}
                name="giftGiverGiftAdvice"
              />
              <Checkbox
                disabled={isUnsubscribeFromAllChecked}
                help="Exclusive deals and information sent from Babylist on behalf of our partners"
                inputRef={register}
                label={<b>Promotions of Brands We Love</b>}
                name="giftGiverPartnerEmail"
              />
            </div>
          </div>
        ) : null}

        {user.isDMEUser && (
          <div>
            <StyledSubHeading className="h5">Babylist Health</StyledSubHeading>
            <div className="mbl">
              <Checkbox
                disabled={isUnsubscribeFromAllChecked}
                help="Emails letting you know it's time to order your free breast pump replacement parts through insurance."
                inputRef={register}
                label={<b>Replacement Parts Emails</b>}
                name="healthReplacementParts"
              />
              <Checkbox
                disabled={isUnsubscribeFromAllChecked}
                help="Exclusive Babylist Health offers and order reminders."
                inputRef={register}
                label={<b>Promotions & Cart Reminder Emails</b>}
                name="healthPromotions"
              />
            </div>
          </div>
        )}

        <StyledSubHeading className="h5">
          Communications from Babylist Partners
        </StyledSubHeading>
        <Checkbox
          disabled={isUnsubscribeFromAllChecked}
          id="consentedToPromotionalOffers"
          help={partnerOffersHelpText}
          inputRef={register}
          label={<b>Third-Party Partner Offers & Updates</b>}
          name="consentedToPromotionalOffers"
        />

        <hr className="mbl" />
        <Checkbox
          label={<b>Unsubscribe From All</b>}
          onChange={unsubscribeFromAll}
        />

        {initialValues.user.consentedToPromotionalOffers &&
          showOptOutWarning && (
            <div className="well h7">
              You previously opted-in to Partner Offer & Update emails. You may
              still receive emails from existing partners. To stop receiving
              these partners' emails, click the unsubscribe link within the
              email.
            </div>
          )}

        <hr className="mbl mtl" />
        <SaveButton pill submitting={submitting}>
          Save Changes
        </SaveButton>
      </Form>
    </div>
  )
}

const dueDateValidator = createValidator({
  'user.family.arrivalDate': [required],
})

const validate = (data) => {
  if (data.user.emailPreferences.pregnancyWeekly) {
    return dueDateValidator(data)
  }
  return {}
}

const mapStateToProps = (state, ownProps) => ({
  ...ownProps,
  initialValues: {
    user: {
      emailPreferences: state.user.emailPreferences,
      family: state.user.family,
      isGiftGiver: state.user.isGiftGiver,
      isDMEUser: state.user.isBLDMECustomer,
      giftGiverRegistries: state.user.giftGiverRegistries,
      consentedToPromotionalOffers: state.user.consentedToPromotionalOffers,
    },
  },
  user: {
    email: state.user.email,
    isDMEUser: state.user.isBLDMECustomer,
    isRegistryUser: state.user.registries?.length !== 0,
    isGiftGiver: state.user.isGiftGiver,
    isGiftGiverOnly: state.user.isGiftGiverOnly,
    giftGiverRegistries: state.user.giftGiverRegistries,
    consentedToPromotionalOffers: state.user.consentedToPromotionalOffers,
  },
})

const mapDispatchToProps = (dispatch, ownProps) => ({
  submitData: (data) => ownProps._handleSubmit(data, dispatch),
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(EmailPreferencesForm)
