import { PriceDetails } from 'src/types/priceDetails'
import { PriceTagProps } from 'baby-design/components/PriceTag/PriceTag'

const getPriceTagPropsFromPriceDetails = (
  priceDetails?: PriceDetails,
  options?: { showCalloutTypes?: string[] }
): PriceTagProps => {
  if (!priceDetails) {
    return {
      currentlyInActiveSale: false,
      callouts: [],
    }
  }

  const { msrp, listPrice, salePrice, saleAttributes } = priceDetails

  // Determine which current, min, and max to use based on sale status
  const isActiveSale = saleAttributes?.saleType === 'active_sale'
  const applicablePricing = isActiveSale ? salePrice : listPrice

  const currentPrice = applicablePricing?.price || listPrice?.price
  const minPrice = applicablePricing?.priceRange?.minPrice
  const maxPrice = applicablePricing?.priceRange?.maxPrice

  // Filter to only include callouts of the specified types if the option is passed
  const saleCallouts = saleAttributes?.saleCallouts || []
  const filteredSaleCallouts = options?.showCalloutTypes
    ? saleCallouts.filter((callout) =>
        options.showCalloutTypes?.includes(callout.type)
      )
    : saleCallouts

  return {
    ...(msrp?.price && { msrp: msrp.price }),
    ...(minPrice && { minPrice }),
    ...(maxPrice && { maxPrice }),
    ...(currentPrice && { currentPrice }),
    currentlyInActiveSale: isActiveSale,
    callouts: filteredSaleCallouts,
  }
}

export default getPriceTagPropsFromPriceDetails
