import NiceModal from '@ebay/nice-modal-react'
import { InfoButton } from 'components/buttons'
import * as BLConstants from 'shared/constants'
import EZAddToCartGiftGiverInfoModal from './EZAddToCartGiftGiverInfoModal'

interface CheckoutButtonProps {
  text: string
}

const EZAddToCartCheckoutButton = ({ text }: CheckoutButtonProps) => {
  const onClick = () => {
    NiceModal.show(EZAddToCartGiftGiverInfoModal)
      .then((resp: any) => {
        window.location.href = resp.checkoutPageUrl
      })
      .catch(({ alertsSet, response }) => {
        if (!alertsSet) {
          PubSub.publish(BLConstants.FLASH_MESSAGE, {
            variant: 'danger',
            message:
              response?.error?.message || BLConstants.SYSTEM_ERROR_MESSAGE,
          })
        }
      })
  }

  return (
    <InfoButton block pill onClick={onClick}>
      {text}
    </InfoButton>
  )
}

export default EZAddToCartCheckoutButton
