import classNames from 'classnames'

import { TypeCloudinaryImageJSON } from 'hello_baby/types/guides'
import {
  cloudinaryImageURL,
  cloudinaryAspectRatioTransform,
} from 'hello_baby/lib/cloudinary'
// eslint-disable-next-line import/no-extraneous-dependencies
import { Image } from 'baby-design'
import RichTextBody from 'hello_baby/components/RichTextBody'

import { Document } from '@contentful/rich-text-types'

import css from './Image.styles.scss'

export interface TypePostEmbedImageFields {
  format: 'Normal' | 'Left' | 'Right'
  image: TypeCloudinaryImageJSON | TypeCloudinaryImageJSON[]
  altText: string
  galleryCaption?: Document
  imageTitle: string
}

interface EmbedImageProps {
  fields: TypePostEmbedImageFields
  aspectRatio?: number
}

const EmbedImage: React.FC<EmbedImageProps> = ({
  fields,
  aspectRatio,
}: EmbedImageProps) => {
  let url = cloudinaryImageURL(fields.image)
  let aspectRatioStyle = {}
  if (!url) return null

  if (aspectRatio && url.search('ar_') === -1) {
    url = cloudinaryAspectRatioTransform(url, aspectRatio)

    aspectRatioStyle = {
      aspectRatio,
      width: '100%',
    }
  }

  return (
    <figure
      className={classNames({
        [css.figure_image_wrapping]:
          fields.format === 'Right' || fields.format === 'Left',
      })}
    >
      <Image
        alt={fields.altText || 'Babylist Guides Image'}
        className={classNames('img-responsive', {
          [css.wrap_right_image]: fields.format === 'Right',
          [css.wrap_left_image]: fields.format === 'Left',
        })}
        src={url}
        style={aspectRatioStyle}
      />
      <figcaption className={css.captionText}>
        {fields.galleryCaption && (
          <RichTextBody
            hyperlinkClassName={fields.galleryCaption ? 'imageGallery' : ''}
            richTextBody={fields.galleryCaption}
          />
        )}
      </figcaption>
    </figure>
  )
}

export default EmbedImage
