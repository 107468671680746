import React from 'react'
import { PaymentPreferencesFormProps } from 'src/routes/(registry)/list/[slug]/components/CashFundPreferencesDrawer/components/PaymentPreferencesForm/PaymentPreferencesForm.types'
import PaymentPreferenceFormGroup from 'src/routes/(registry)/list/[slug]/components/CashFundPreferencesDrawer/components/PaymentPreferenceFormGroup/PaymentPreferenceFormGroup'
import { CashFundPaymentPreference } from 'src/types/registry'
import TransactionVisibilityBanner from 'src/routes/(registry)/list/[slug]/components/CashFundPreferencesDrawer/components/TransactionVisibilityBanner/TransactionVisibilityBanner'
import {
  fieldErrorsByFieldName,
  removeFieldErrorsForField,
} from 'src/api/helpers'
import PaymentPreferenceRequiredCallout from 'src/routes/(registry)/list/[slug]/components/CashFundPreferencesDrawer/components/PaymentPreferenceRequiredCallout/PaymentPreferenceRequiredCallout'
import css from './PaymentPreferencesForm.styles.scss'

const PaymentPreferencesForm: React.FC<PaymentPreferencesFormProps> = ({
  errors,
  originComponent = '',
  onPreferencesUpdated,
  preferences,
  setErrors,
  showAppliesToAllFundsWarning = false,
  showPreferenceRequired = false,
}) => {
  const handlePreferenceChange = (
    newPreference: CashFundPaymentPreference,
    index: number
  ) => {
    if (!onPreferencesUpdated) return

    const newPreferences: CashFundPaymentPreference[] = []
    if (preferences.paymentPreferences) {
      newPreferences.push(...preferences.paymentPreferences)
      newPreferences[index] = newPreference
    } else {
      newPreferences.push(newPreference)
    }

    onPreferencesUpdated({
      ...preferences,
      paymentPreferences: newPreferences,
    })
  }

  const descriptionClassName = (): string => {
    const suffixText = originComponent ? `__${originComponent}` : ''
    return css[`PaymentPreferencesForm__Description${suffixText}`]
  }

  const clearError = (field: string) =>
    setErrors(removeFieldErrorsForField(errors, field))

  return (
    <div className={css.PaymentPreferencesForm__Container}>
      {showPreferenceRequired && (
        <PaymentPreferenceRequiredCallout
          className={css.PaymentPreferencesForm__Callout}
          message="Select at least one payment type"
        />
      )}
      <div className={css.PaymentPreferencesForm}>
        <p className={descriptionClassName()}>
          Select how you want to receive cash for this fund
        </p>
        <div className={css.PaymentPreferencesForm__InputGroupContainer}>
          {preferences.paymentPreferences?.map((preference, index) => (
            <PaymentPreferenceFormGroup
              clearError={clearError}
              errors={fieldErrorsByFieldName(errors)}
              key={preference.key}
              preference={preference}
              onPreferenceChanged={(newPreference) =>
                handlePreferenceChange(newPreference, index)
              }
            />
          ))}
          {showAppliesToAllFundsWarning && (
            <p className={css.PaymentPreferencesForm__AllFundsWarning}>
              Please note that any changes will apply to all cash funds on your
              registry.
            </p>
          )}
        </div>
        <TransactionVisibilityBanner />
      </div>
    </div>
  )
}

export default PaymentPreferencesForm
