import * as Urls from 'lib/urls'
import fetch from 'lib/fetch'
import { RegItem } from 'src/types/regItem'
import { SaveCashFundPreferencesRequest } from 'src/routes/(registry)/api/types'
import { RegistrySortFilterOptions } from 'src/types/registrySortFilterOptions'
import { Reservation } from 'src/types/reservation'
import { apiV3ProductAvailabilitySubscriptionsPath } from 'lib/urls'

export const FETCH_REGISTRY_KEY = 'FETCH_REGISTRY'
export const fetchRegistry = async (
  registryId: string | number | undefined
) => {
  const response = await fetch(Urls.apiV3RegistryPath(registryId), {
    method: 'GET',
  })
  return response.registry
}

export const FETCH_REG_ITEMS_PAGE_SIZE = 50
export const FETCH_REG_ITEMS_KEY = 'FETCH_REG_ITEMS'
export const fetchRegItems = async (
  registryId: string | number | undefined,
  page = 1,
  limit = FETCH_REG_ITEMS_PAGE_SIZE
) => {
  const offset = ((page - 1) * limit).toString()
  const searchParams = new URLSearchParams({
    limit: limit.toString(),
    offset,
  })

  const response = await fetch(
    `${Urls.apiV3RegItemsPath(registryId)}?${searchParams}`,
    { method: 'GET' }
  )
  return response
}

export const fetchRegItem = async (
  regItemId: number,
  registryId: number
): Promise<RegItem> => {
  return await fetch(Urls.apiV3RegItemPath(regItemId, registryId), {
    method: 'GET',
  })
}

export const saveRegItem = (
  registryId: number,
  regItemId: number,
  regItem: any
) => {
  return fetch(Urls.apiV3RegItemPath(regItemId, registryId), {
    method: 'PATCH',
    body: JSON.stringify({ regItem }),
  })
}

export const deleteRegItem = (registryId: number, regItemId: number) => {
  return fetch(Urls.apiV3RegItemPath(regItemId, registryId), {
    method: 'DELETE',
  })
}

export const updateCategory = (registryId: number, category: any) => {
  return fetch(Urls.apiV3RegistryCategoryPath(registryId, category.id), {
    method: 'PUT',
    body: JSON.stringify({ category }),
  })
}

export const createCategory = (registryId: number, category: any) => {
  return fetch(Urls.apiV3RegistryCategoriesPath(registryId), {
    method: 'POST',
    body: JSON.stringify({ category }),
  })
}

export const deleteCategory = (registryId: number, categoryId: number) => {
  return fetch(Urls.apiV3RegistryCategoryPath(registryId, categoryId), {
    method: 'DELETE',
  })
}

export const createReservation = (reservation: object, params = {}) => {
  return fetch(Urls.apiV3ReservationsPath, {
    method: 'POST',
    body: JSON.stringify({ ...params, reservation }),
  })
}

export const getRegItemGroupGiftContributions = (
  registryId: number,
  regItemId: number
) => {
  return fetch(Urls.apiV3RegItemGroupGiftsPath(registryId, regItemId), {
    method: 'GET',
  })
}

export const redeemGroupGifts = (data: { fundIds: string[] }) => {
  return fetch(Urls.apiV3GroupGiftRedemptionsPath, {
    body: JSON.stringify(data),
    method: 'POST',
  })
}

export const addCartItem = (cartItem: {
  productId: number
  quantity: number
  regItemId?: number
}) => {
  return fetch(Urls.apiV3CartItemsPath, {
    body: JSON.stringify({ cartItem }),
    method: 'POST',
  })
}

export const addProductAvailabilitySubscription = (productId: number) =>
  fetch(apiV3ProductAvailabilitySubscriptionsPath, {
    body: JSON.stringify({ product_id: productId }),
    method: 'POST',
  })

export const createRegItem = async (registryId: number, regItem: any) => {
  return await fetch(Urls.apiV3RegItemsPath(registryId), {
    method: 'POST',
    body: JSON.stringify({ regItem }),
  })
}

export const UPDATE_REG_ITEM_KEY = 'UPDATE_REG_ITEM'
export const updateRegItem = async (regItem: any) => {
  const response = await fetch(
    Urls.apiV3RegItemPath(regItem.id, regItem.registry.id),
    {
      method: 'PATCH',
      body: JSON.stringify({ regItem }),
    }
  )
  return response
}

export const saveCashFundPreferences = (data: SaveCashFundPreferencesRequest) =>
  fetch(Urls.apiV3CashFundPreferencesPath, {
    body: JSON.stringify(data),
    method: 'POST',
  })

export const FETCH_MINIMAL_REG_ITEMS_KEY = 'FETCH_MINIMAL_REG_ITEMS'
export const fetchMinimalRegItems = async (
  registryId: number | undefined
): Promise<RegItem[]> =>
  fetch(Urls.apiV3MinimalRegItemsPath(registryId), {
    method: 'GET',
  })

export const FETCH_REGISTRY_SORT_FILTER_OPTIONS_KEY =
  'FETCH_REGISTRY_SORT_FILTER_OPTIONS'
export const fetchRegistrySortFilterOptions = async (
  registryId: number | undefined
): Promise<RegistrySortFilterOptions> =>
  fetch(Urls.apiV3RegistrySortFilterOptionsPath(registryId), {
    method: 'GET',
  })

export const FETCH_RESERVATIONS_KEY = 'FETCH_RESERVATIONS'
export const fetchReservations = async (
  registryId: number | undefined,
  isGuestView = true
): Promise<Reservation[]> =>
  fetch(Urls.apiV3ReservedRegItemsPath(registryId, isGuestView), {
    method: 'GET',
  })
