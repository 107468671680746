import { FC } from 'react'
import { IconButton, StoreIcon, LoadingDots, PriceTag } from 'baby-design'
import { Chevron, EditPencil } from 'baby-design/icons'
import { Offer } from 'src/types/offer'
import { getPriceTagPropsFromPriceDetails } from 'src/utils/productHelpers'
import { formatDisplayPrice } from '../../../../utils/productHelpers'

import css from './BuyingOption.styles.scss'

const LegacyPriceTag = ({ price }: { price: string | number | undefined }) => (
  <div className={css.BuyingOption__price}>{formatDisplayPrice(price)}</div>
)

interface BuyingOptionProps {
  editOnClick?: () => void
  offer: Offer
}

export const BuyingOption: FC<BuyingOptionProps> = ({ editOnClick, offer }) => {
  let priceTagProps = {}
  if (offer.priceDetails) {
    priceTagProps = getPriceTagPropsFromPriceDetails(offer.priceDetails)
  }

  return (
    <div className={css.BuyingOption}>
      <a
        className={css.BuyingOption__icon}
        href={offer.url}
        rel="noreferrer"
        target="_blank"
      >
        <StoreIcon store={{ name: offer.storeIconName }} />
      </a>
      <div className={css.BuyingOption__name}>
        <a href={offer.url} rel="noreferrer" target="_blank">
          {offer.storeDisplayName || <LoadingDots />}
        </a>
        {editOnClick && (
          <IconButton
            size="xs"
            variant="standard-default"
            onClick={editOnClick}
          >
            <EditPencil />
          </IconButton>
        )}
      </div>
      {offer.priceDetails ? (
        <PriceTag {...priceTagProps} size="sm" variant="compact" />
      ) : (
        <LegacyPriceTag price={offer.price} />
      )}
      <a
        className={css.BuyingOption__chevron_link}
        href={offer.url}
        rel="noreferrer"
        target="_blank"
      >
        <Chevron className={css.BuyingOption__chevron} />
      </a>
    </div>
  )
}
