import React from 'react'
import { ToastLinkProps } from './ToastLink.types'
import { TextLink } from '../../../../index'
import css from './ToastLink.styles.scss'

const ToastLink: React.FC<ToastLinkProps> = ({ text, ...props }) => (
  <>
    <TextLink
      underline
      className={css.ToastLink__MobileOnly}
      data-testid="toast-link-mobile"
      size="sm"
      variant="inverted"
      {...props}
    >
      {text}
    </TextLink>
    <TextLink
      underline
      className={css.ToastLink__HiddenMobile}
      data-testid="toast-link-desktop"
      size="lg"
      variant="inverted"
      {...props}
    >
      {text}
    </TextLink>
  </>
)

export default ToastLink
