import PropTypes from 'prop-types'
import FacebookShareButton from './facebook-share-button'
import PinterestShareButton from './pinterest-share-button'
import classNames from 'classnames'
import css from './share-button.scss'

const ShareButtons = ({ className, description, media, shareText, url }) => (
  <div
    className={classNames(
      className,
      css.shareButtonContainer,
      'flex-vertical-center'
    )}
  >
    {shareText ? <span className={css.shareText}>{shareText}</span> : null}
    <div className={css.shareButtons} style={{ display: 'inline-block' }}>
      <FacebookShareButton url={url} />
      <PinterestShareButton description={description} media={media} url={url} />
    </div>
  </div>
)

ShareButtons.propTypes = {
  className: PropTypes.string,
  description: PropTypes.string,
  media: PropTypes.string,
  shareText: PropTypes.string,
  url: PropTypes.string.isRequired,
}

export default ShareButtons
