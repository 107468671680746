/* eslint-disable import/no-extraneous-dependencies */
import { FC } from 'react'
import classNames from 'classnames'
import {
  IconButton,
  StoreIcon,
  LoadingDots,
  PriceTag,
  Button,
} from 'baby-design'
import { EditPencil, LinkOut, ShoppingCartEmpty } from 'baby-design/icons'
import { Offer } from 'src/types/offer'
import { getPriceTagPropsFromPriceDetails } from 'src/utils/productHelpers'
import { formatDisplayPrice } from '../../../../utils/productHelpers'

import css from './SelfPurchaseBuyingOption.styles.scss'

const LegacyPriceTag = ({ price }: { price: string | number | undefined }) => (
  <div className={css.SelfPurchaseBuyingOption__price}>
    {formatDisplayPrice(price)}
  </div>
)

interface SelfPurchaseBuyingOptionProps {
  editOnClick?: () => void
  handleAddToCart: () => void
  isAddingToCart: boolean
  offer: Offer
  useHorizontalPadding: boolean
}

export const SelfPurchaseBuyingOption: FC<SelfPurchaseBuyingOptionProps> = ({
  editOnClick,
  handleAddToCart,
  isAddingToCart,
  offer,
  useHorizontalPadding,
}) => {
  let priceTagProps = {}
  if (offer.priceDetails) {
    priceTagProps = getPriceTagPropsFromPriceDetails(offer.priceDetails)
  }

  return (
    <div
      className={classNames(css.SelfPurchaseBuyingOption, {
        [css['SelfPurchaseBuyingOption--horizontalPadding']]:
          useHorizontalPadding,
      })}
    >
      <div className={css.SelfPurchaseBuyingOption__icon}>
        <StoreIcon store={{ name: offer.storeIconName }} />
      </div>
      <div className={css.SelfPurchaseBuyingOption__info}>
        <div className={css.SelfPurchaseBuyingOption__nameAndPrice}>
          <a
            className={css.SelfPurchaseBuyingOption__name}
            href={offer.url}
            rel="noreferrer"
            target="_blank"
          >
            {offer.storeDisplayName || <LoadingDots />}
          </a>
          {offer.priceDetails ? (
            <PriceTag
              {...priceTagProps}
              className={css.SelfPurchaseBuyingOption__designSystemPriceTag}
              size="xs"
              variant="compact"
            />
          ) : (
            <LegacyPriceTag price={offer.price} />
          )}
        </div>
        {editOnClick && (
          <IconButton
            className={css.SelfPurchaseBuyingOption__secondaryLinkEditButton}
            size="xs"
            variant="standard-default"
            onClick={editOnClick}
          >
            <EditPencil />
          </IconButton>
        )}
        {offer.isBabylist ? (
          <Button
            className={classNames(
              css.SelfPurchaseBuyingOption__purchaseButton,
              css.SelfPurchaseBuyingOption__addToCartButton
            )}
            icon={{ side: 'right', icon: <ShoppingCartEmpty />, size: 'xl' }}
            loading={isAddingToCart}
            size="sm"
            variant="de-emphasized"
            onClick={handleAddToCart}
          >
            Add
          </Button>
        ) : (
          <Button
            className={css.SelfPurchaseBuyingOption__purchaseButton}
            href={offer.url}
            icon={{ side: 'right', icon: <LinkOut /> }}
            rel="noreferrer"
            size="sm"
            target="_blank"
            variant="tertiary"
          >
            View
          </Button>
        )}
      </div>
    </div>
  )
}
