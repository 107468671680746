import { useQueryClient } from '@tanstack/react-query'
import css from './EditSecondaryLinkDrawer.style.scss'
import { RegItem, SecondaryLink } from 'src/types/regItem'
import { RightDrawer, NavLink, TextInput } from 'baby-design'
import CurrencyInput from 'src/routes/(registry)/components/form/CurrencyInput'

import { useEffect, useState } from 'react'
import { Offer } from 'src/types/offer'
import NiceModal, { useModal } from '@ebay/nice-modal-react'
import useSaveRegItem from '../../hooks/useSaveRegItem'
import { optimisticallyUpdateRegItem } from '../../utils/registryHelpers'
interface EditSecondaryLinkDrawerProps {
  secondaryOffer: Offer
  regItem: RegItem
}

export default NiceModal.create(
  ({ secondaryOffer, regItem }: EditSecondaryLinkDrawerProps) => {
    const queryClient = useQueryClient()
    const modal = useModal()
    const {
      saveRegItemMutation,
      onSave,
      setRegItemChanges,
      handleClose,
      hasUnsavedChanges,
    } = useSaveRegItem({ regItem, modal })

    const [secondaryLinkField, setSecondaryLinkFields] =
      useState<SecondaryLink>({
        id: secondaryOffer.id,
        url: secondaryOffer.normalUrl || '',
        price: `${secondaryOffer.price}`,
      })

    useEffect(() => {
      setSecondaryLinkFields({
        id: secondaryOffer.id,
        url: secondaryOffer.normalUrl || '',
        price: `${secondaryOffer.price}`,
      })
    }, [secondaryOffer])

    const updateSecondaryLinkFields = (changes: object) => {
      setSecondaryLinkFields({ ...secondaryLinkField, ...changes })
      setRegItemChanges({
        secondaryLinks: [{ ...secondaryLinkField, ...changes }],
      })
    }

    const onDelete = () => {
      // To remove a secondary link via the current API we must persist the secondary link with an empty URL
      const secondaryLinksForRequest = regItem.secondaryLinks.map((link) => {
        if (link.id === secondaryLinkField.id) {
          return { ...link, url: '' }
        }

        return link
      })

      // To remove a secondary link optimistically in state we remove it from the secondaryLinks array
      const secondaryLinksForOptimisticUpdate = regItem.secondaryLinks.filter(
        (link) => link.id !== secondaryLinkField.id
      )

      optimisticallyUpdateRegItem({
        queryClient,
        updatedRegItem: {
          ...regItem,
          secondaryLinks: secondaryLinksForOptimisticUpdate,
        },
      })

      saveRegItemMutation.mutate({
        ...regItem,
        secondaryLinks: secondaryLinksForRequest,
      })
    }

    return (
      <RightDrawer
        handleClose={handleClose}
        handleDismount={modal.remove}
        isOpen={modal.visible}
        title="Edit Secondary Link"
        mainIcon="back"
        contextualIcon={
          <NavLink
            variant="eggplant"
            size="md"
            onClick={onSave}
            disabled={saveRegItemMutation.isLoading || !hasUnsavedChanges}
          >
            Save
          </NavLink>
        }
      >
        <div className={css.secondary_link_drawer__body}>
          <TextInput
            className={css.secondary_link_drawer__url}
            inputProps={{
              disabled: saveRegItemMutation.isLoading,
              value: secondaryLinkField.url,
              onChange: (e) =>
                updateSecondaryLinkFields({ url: e.target.value }),
            }}
            label="Product Link"
            size="md"
          />
          <CurrencyInput
            className={css.secondary_link_drawer__price}
            inputProps={{
              disabled: saveRegItemMutation.isLoading,
              placeholder: '$19.99',
              value: secondaryLinkField.price,
              onChange: (e) =>
                updateSecondaryLinkFields({ price: e.target.value }),
            }}
            label="Price"
            size="md"
          />
        </div>
        <div className={css.secondary_link_drawer__divider} />
        <div className={css.secondary_link_drawer__delete}>
          <a
            className={css.secondary_link_drawer__delete_link}
            onClick={onDelete}
          >
            Delete item
          </a>
        </div>
      </RightDrawer>
    )
  }
)
