import { Switch } from 'baby-design'
import { RegItem } from 'src/types/regItem'
import { FAVORITE_COPY } from 'registry/constants'
import css from './Favorite.style.scss'

interface EditItemDetailsFavoriteSettingProps {
  regItemFields: RegItem
  updateRegItemFields: (changes: Record<string, unknown>) => void
}

export const EditItemDetailsFavoriteSetting = ({
  regItemFields,
  updateRegItemFields,
}: EditItemDetailsFavoriteSettingProps) => {
  const { isFavorite } = regItemFields

  return (
    <div className={css.Favorite}>
      <div
        className={css.Favorite__label}
        id="edit-item-details-favorite-label"
      >
        <div className={css.Favorite__heading}>{FAVORITE_COPY}</div>
        <div className={css.Favorite__subheading}>
          Mark this item as {FAVORITE_COPY.toLowerCase()}.
        </div>
      </div>
      <Switch
        aria-labelledby="edit-item-details-favorite-label"
        checked={isFavorite}
        onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
          updateRegItemFields({ isFavorite: e.target.checked })
        }
      />
    </div>
  )
}
