import React from 'react'
import classNames from 'classnames'

import css from './Switch.styles.scss'

import { SwitchProps } from './Switch.types'
import Check from '../../../icons/Check.svg'

const Switch: React.FC<SwitchProps> = ({
  className,
  onClick = () => null,
  ...props
}) => {
  const SwitchClasses = classNames(css.switch, className)

  return (
    <div className={SwitchClasses}>
      <input
        className={classNames(css.switch__checkbox_reset, css.switch__input)}
        type="checkbox"
        onClick={(e) => {
          e.currentTarget.blur()
          onClick(e)
        }}
        {...props}
      />
      <div className={classNames(css.switch__slider)}>
        <Check className={classNames(css.switch__check)} />
      </div>
    </div>
  )
}

export default Switch
