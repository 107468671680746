import { LeftArrow } from 'baby-design/icons'
import { Button } from 'baby-design'
import { IneligibilityReason } from './IneligibilityReason'

import css from './InsuranceEligibilityValidatorTool.styles.scss'

interface ResetIneligibilityButtonProps {
  onClick: () => void
  reason: IneligibilityReason
}

const copy = {
  [IneligibilityReason.StateNotSupported]: 'Try another state',
  [IneligibilityReason.InsuranceNotSupported]: 'Try another insurance',
}

export default function IneligibilityButton({
  onClick,
  reason,
}: ResetIneligibilityButtonProps) {
  const text = copy[reason]
  return (
    <Button
      block
      className={css.InsuranceEligibilityValidatorTool__ineligibility_button}
      data-testid="ineligibility-button"
      icon={{ icon: <LeftArrow />, side: 'left' }}
      size="md"
      variant="inverted"
      onClick={onClick}
    >
      {text}
    </Button>
  )
}
