import { createContext, ReactNode, useState } from 'react'

interface Alert {
  message: string
}

export interface EZAddToCartAlertsContextType {
  alerts: Array<Alert>
  addAlert: (alert: Alert) => void
  clearAlerts: () => void
}

const EZAddToCartAlertsContext = createContext<EZAddToCartAlertsContextType>(
  {} as EZAddToCartAlertsContextType
)

interface EZAddToCartAlertsProviderProps {
  children: ReactNode
}

export const EZAddToCartAlertsProvider: React.FC<
  EZAddToCartAlertsProviderProps
> = ({ children }) => {
  const [alerts, setAlerts] = useState<Alert[]>([])

  const addAlert = (alert: Alert) =>
    setAlerts((prevAlerts) => [...prevAlerts, alert])

  const clearAlerts = () => setAlerts([])

  const value: EZAddToCartAlertsContextType = {
    alerts,
    addAlert,
    clearAlerts,
  }

  return (
    <EZAddToCartAlertsContext.Provider value={value}>
      {children}
    </EZAddToCartAlertsContext.Provider>
  )
}

export default EZAddToCartAlertsContext
