import React from 'react'

interface PriceRangeProps {
  className?: string
}

const PriceRange: React.FC<PriceRangeProps> = ({
  className,
}: PriceRangeProps) => (
  <svg
    className={className}
    fill="none"
    height="24"
    viewBox="0 0 24 24"
    width="24"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      clipRule="evenodd"
      d="M12.2539 3C12.2539 2.58579 11.9181 2.25 11.5039 2.25C11.0897 2.25 10.7539 2.58579 10.7539 3V4.54338C9.65511 4.66566 8.65221 5.05395 7.87 5.63995C6.91276 6.35706 6.25 7.40544 6.25 8.62757C6.25 9.84802 6.90837 10.8969 7.86369 11.6154C8.64636 12.2041 9.65124 12.5935 10.7539 12.7149V17.9714C10.3386 17.9156 9.93286 17.7965 9.55123 17.6169C8.95388 17.3359 8.43321 16.9151 8.03333 16.3904C7.78226 16.0609 7.31166 15.9974 6.9822 16.2484C6.65275 16.4995 6.5892 16.9701 6.84027 17.2995C7.38634 18.0161 8.09723 18.5905 8.91263 18.9742C9.49457 19.248 10.1175 19.4189 10.7539 19.4813V20.9996C10.7539 21.4138 11.0897 21.75 11.5039 21.75C11.9181 21.75 12.2539 21.4142 12.2539 21V19.4617C13.3499 19.3382 14.3498 18.95 15.13 18.3654C16.0873 17.6482 16.7502 16.5999 16.7502 15.3787C16.7502 14.1573 16.0871 13.1095 15.1297 12.3928C14.3495 11.8087 13.3497 11.4211 12.2539 11.2977V6.03561C12.6659 6.09175 13.0684 6.21035 13.4471 6.38854C14.0444 6.66957 14.5649 7.09048 14.9644 7.61544C15.2152 7.94507 15.6858 8.00895 16.0154 7.75811C16.345 7.50728 16.4089 7.03672 16.1581 6.70709C15.6123 5.98991 14.9014 5.41504 14.0858 5.03127C13.5067 4.75882 12.887 4.5884 12.2539 4.5255V3ZM10.7539 6.05531C9.96961 6.16853 9.28436 6.45462 8.76935 6.84044C8.09624 7.34469 7.75 7.98409 7.75 8.62757C7.75 9.27272 8.09517 9.91265 8.76529 10.4166C9.28006 10.8038 9.96642 11.0908 10.7539 11.2032V6.05531ZM12.2539 12.8099V17.9495C13.035 17.8352 13.7173 17.5495 14.2306 17.165C14.9041 16.6603 15.2502 16.0209 15.2502 15.3787C15.2502 14.7366 14.9043 14.0977 14.2308 13.5936C13.7176 13.2094 13.0352 12.924 12.2539 12.8099Z"
      fill="#252324"
      fillRule="evenodd"
    />
  </svg>
)

export default PriceRange
