import React, { useEffect, useRef } from 'react'
import { ModalContentProps } from './ModalContent.types'
import css from './ModalContent.styles.scss'

const ModalContent: React.FC<ModalContentProps> = ({
  children,
  scrollToTopOnContentChange,
}) => {
  const contentRef = useRef<HTMLDivElement>(null)

  useEffect(() => {
    if (scrollToTopOnContentChange) {
      contentRef.current?.scrollTo(0, 0)
    }
  }, [children, scrollToTopOnContentChange])

  return (
    <div
      className={css.ModalContent}
      data-testid="modal-content"
      ref={contentRef}
    >
      {children}
    </div>
  )
}

export default ModalContent
