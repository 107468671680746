import { Component } from 'react'
import PropTypes from 'prop-types'
import css from './share-button.scss'

/*
 * Assumes pinit.js is loaded somewhere on the page !
 */

class PinterestShareButton extends Component {
  componentDidMount() {
    if (window.parsePinBtns) {
      // re-parses pinterest buttons added via js
      // see: http://stackoverflow.com/questions/9352021/how-can-i-rerender-pinterests-pin-it-button
      window.parsePinBtns()
    }
  }

  render() {
    const { description, media, url } = this.props
    return (
      <a
        className={[css.shareButton, css.pinterest].join(' ')}
        href={`https://www.pinterest.com/pin/create/button/?url=${encodeURIComponent(
          url
        )}&media=${encodeURIComponent(
          media || ''
        )}&description=${encodeURIComponent(description || '')}`}
        data-pin-custom="true"
        data-pin-description={description}
        data-pin-media={media}
        data-pin-url={url}
      >
        <i className="fa fa-pinterest" aria-hidden="true" />
      </a>
    )
  }
}

PinterestShareButton.propTypes = {
  description: PropTypes.string,
  media: PropTypes.string,
  url: PropTypes.string.isRequired,
}

export default PinterestShareButton
