import React from 'react'
import IconButton from '../../../IconButton/IconButton'
import Close from '../../../../icons/Close.svg'
import { CloseButtonProps } from './CloseButton.types'
import css from './CloseButton.styles.scss'

const CloseButton: React.FC<CloseButtonProps> = ({ handleClose }) => (
  <IconButton
    aria-label="Close callout"
    className={css.CloseButton}
    data-testid="callout-close-button"
    size="sm"
    variant="standard-default"
    onClick={handleClose}
  >
    <Close className={css.CloseButton__icon} />
  </IconButton>
)

export default CloseButton
