import PropTypes from 'prop-types'
import css from './share-button.scss'
import classNames from 'classnames'

/*
 * Assumes the FB JS SDK is loaded and init'ed somewhere on the page !
 */

const FacebookShareButton = ({ url }) => {
  const clickHandler = () => {
    if (window?.FB) {
      window.FB.ui({ method: 'share', href: url })
    }
  }

  return (
    <a
      className={classNames(css.shareButton, css.facebook)}
      onClick={clickHandler}
    >
      <i className="fa fa-facebook" aria-hidden="true" />
    </a>
  )
}

FacebookShareButton.propTypes = {
  url: PropTypes.string.isRequired,
}

export default FacebookShareButton
