import React from 'react'
import classNames from 'classnames'

import css from './ChecklistProgress.styles.scss'

import { ChecklistProgressProps } from './ChecklistProgress.types'
import CircularProgressBar from '../CircularProgressBar/CircularProgressBar'
import Checklist from '../../icons/Checklist.svg'

const ChecklistProgress: React.FC<ChecklistProgressProps> = ({
  className,
  value,
}) => {
  const ChecklistProgressClasses = classNames(css.ChecklistProgress, className)

  return (
    <div className={ChecklistProgressClasses}>
      <div className={css.ChecklistProgress__clipboardContainer}>
        <Checklist filled className={css.ChecklistProgress__clipboard} />
      </div>
      <div className={css.ChecklistProgress__container}>
        <CircularProgressBar value={value} />
      </div>
    </div>
  )
}

export default ChecklistProgress
