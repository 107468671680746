import React, { useEffect, useState } from 'react'
import { Dropdown } from 'baby-design'
import { useWindowSize } from 'lib/hooks'
import {
  DropdownOption,
  MenuPosition,
  PaymentMethodDropdownProps,
} from './PaymentMethodDropdown.types'
import { PAYMENT_METHOD_OPTIONS } from './constants'
import css from './PaymentMethodDropdown.scss'

const MAX_MOBILE_SCREEN_WIDTH = 768

const PaymentMethodDropdown: React.FC<PaymentMethodDropdownProps> = ({
  onPaymentMethodChanged,
  paymentMethod,
}) => {
  const { width } = useWindowSize()
  const [menuPlacement, setMenuPlacement] = useState<MenuPosition>('auto')

  const selectedOption = PAYMENT_METHOD_OPTIONS.find(
    (option: DropdownOption) => option?.value === paymentMethod
  )

  useEffect(() => {
    if (width) {
      setMenuPlacement(width < MAX_MOBILE_SCREEN_WIDTH ? 'top' : 'auto')
    }
  }, [width])

  const label = (
    <>
      Payment method{' '}
      <span className={css.PaymentMethodDropdown__Optional}>(optional)</span>
    </>
  )

  return (
    <Dropdown
      label={label}
      menuPlacement={menuPlacement}
      menuPortalTarget={document.body}
      options={PAYMENT_METHOD_OPTIONS}
      placeholder="Select an option"
      size="md"
      value={selectedOption}
      onChange={(option) => onPaymentMethodChanged(option?.value || undefined)}
    />
  )
}

export default PaymentMethodDropdown
