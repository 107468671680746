import { forEach, get } from 'lodash'

export const getScrollTracker = (...offsetClasses) => {
  const { clientHeight } = document.documentElement
  let offsetHeight = 0
  forEach(offsetClasses, (offsetClass) => {
    const offsetElement = document.getElementsByClassName(offsetClass)[0]
    if (offsetElement) {
      offsetHeight += offsetElement.scrollHeight
    }
  })
  let scrollAreaHeight = null
  const scrollTracker = {
    getPercentScrolled: (scrollTrackingElement) => {
      scrollAreaHeight =
        scrollAreaHeight || get(scrollTrackingElement, 'scrollHeight')
      if (scrollAreaHeight) {
        const scrollTop =
          document.documentElement.scrollTop || document.body.scrollTop
        const percentScrolled =
          ((scrollTop - offsetHeight + clientHeight) / scrollAreaHeight) * 100
        return percentScrolled
      }
      return 0
    },
    getDistanceScrolled: () => {
      const scrollTop =
        document.documentElement.scrollTop || document.body.scrollTop
      const distanceScrolled = scrollTop - offsetHeight
      return distanceScrolled
    },
    resetScrollArea: () => {
      scrollAreaHeight = null
      return scrollAreaHeight
    },
  }
  return scrollTracker
}
