import Link from 'baby-ui/foundation/Link/Link'
import css from './styles.scss'
import FeaturedIconSVG from '../../Icons/FeaturedIcon'

export interface TypeTableRowFields {
  title: string
  label: string
  text: string
  linkUrl?: string
  featured: boolean
  customFeaturedIconUrl?: string
}

interface TableRowProps {
  fields: TypeTableRowFields
}

const TableRow: React.FC<TableRowProps> = ({ fields }: TableRowProps) => (
  <tr>
    <td className={css['Table__label-cell']}>{fields.label}</td>
    <td className={css['Table__icon-cell']}>
      {fields.featured &&
        (fields.customFeaturedIconUrl ? (
          <img
            alt="Featured Icon"
            height="24px"
            src={fields.customFeaturedIconUrl}
            width="24px"
          />
        ) : (
          <FeaturedIconSVG />
        ))}
    </td>
    <td className={css['Table__text-cell']}>
      {fields.linkUrl ? (
        <Link href={fields.linkUrl}>{fields.text}</Link>
      ) : (
        fields.text
      )}
    </td>
  </tr>
)

export default TableRow
