import { RegItem } from 'src/types/regItem'
import { NavLink, RightDrawer } from 'baby-design'
import { useEffect } from 'react'
import NiceModal, { useModal } from '@ebay/nice-modal-react'
import useSaveRegItem from '../../hooks/useSaveRegItem'
import ProductInfoDrawerFields from 'src/routes/(registry)/list/[slug]/components/ProductInfoDrawer/components/ProductInfoDrawerFields/ProductInfoDrawerFields'

interface ProductInfoDrawerProps {
  regItem: RegItem
}

export default NiceModal.create(({ regItem }: ProductInfoDrawerProps) => {
  const modal = useModal()
  const {
    saveRegItemMutation,
    onSave,
    regItemChanges,
    setRegItemChanges,
    handleClose,
    hasUnsavedChanges,
  } = useSaveRegItem({ regItem, modal })

  useEffect(() => {
    setRegItemChanges({})
  }, [modal.visible])

  const updateRegItemChanges = (fields: object) => {
    setRegItemChanges({ ...regItemChanges, ...fields })
  }

  const regItemFields = { ...regItem, ...regItemChanges }

  const showPrice =
    !regItem.isExternalRegistry &&
    !regItem.isFavor &&
    !regItem.isGiftCard &&
    !regItem.isCrowdfund

  const allowImageEdit = !regItem.isGroupGift

  return (
    <RightDrawer
      handleClose={handleClose}
      handleDismount={modal.remove}
      isOpen={modal.visible}
      title="Product Info"
      contextualIcon={
        <NavLink
          variant="eggplant"
          size="md"
          onClick={onSave}
          disabled={saveRegItemMutation.isLoading || !hasUnsavedChanges()}
        >
          Save
        </NavLink>
      }
      mainIcon="back"
    >
      <ProductInfoDrawerFields
        regItemFields={regItemFields}
        onUpdateRegItemFields={updateRegItemChanges}
        isPriceEditable={regItem.isPriceEditable}
        isTitleEditable={regItem.isTitleEditable}
        allowImageEdit={allowImageEdit}
        showPrice={showPrice}
      />
    </RightDrawer>
  )
})
