import NiceModal from '@ebay/nice-modal-react'
import { useCallback, useEffect, useState } from 'react'
import { RegItem } from 'src/types/regItem'
import { GroupGiftUnredeemedFundsModal } from '../components/GroupGiftUnredeemedFundsModal'

const useGroupGiftUnredeemedFundsModal = (regItem: RegItem) => {
  const [
    shouldShowGroupGiftUnredeemedFundsModal,
    setShouldShowGroupGiftUnredeemedFundsModal,
  ] = useState(false)

  const showModal = useCallback(() => {
    NiceModal.show(GroupGiftUnredeemedFundsModal, { regItem })
  }, [regItem])

  useEffect(() => {
    let timeout: NodeJS.Timeout
    if (shouldShowGroupGiftUnredeemedFundsModal) {
      timeout = setTimeout(() => {
        showModal()
        setShouldShowGroupGiftUnredeemedFundsModal(false)
      }, 1000)
    }

    return () => {
      clearTimeout(timeout)
    }
  }, [shouldShowGroupGiftUnredeemedFundsModal, regItem, showModal])

  const openUnredeemedFundsModalWithDelay = () => {
    setShouldShowGroupGiftUnredeemedFundsModal(true)
  }

  return {
    openUnredeemedFundsModalWithDelay,
    openUnredeemedFundsModal: showModal,
  }
}

export default useGroupGiftUnredeemedFundsModal
