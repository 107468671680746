import { FC, useState } from 'react'
import classnames from 'classnames'
import InsuranceEligibilityValidator from './InsuranceEligibilityValidator'
import { handleEligibilityCheck } from './helpers'
import IneligibilityButton from './IneligibilityButton'
import css from './InsuranceEligibilityValidatorTool.styles.scss'
import DismissButton from './DismissButton'
import IneligibilityHeading from './IneligibilityHeading'
import { IneligibilityReason } from './IneligibilityReason'
import DefaultHeading from './DefaultHeading'
import EligibilityModal from './EligibilityModal'

export type InsuranceEligibilityValidatorToolVariant = 'guide' | 'feed'

interface InsuranceEligibilityValidatorToolProps {
  variant: InsuranceEligibilityValidatorToolVariant
  dismissalContentfulId?: string
}

const InsuranceEligibilityValidatorTool: FC<
  InsuranceEligibilityValidatorToolProps
> = ({ variant, dismissalContentfulId }) => {
  const [isDismissed, setIsDismissed] = useState(false)
  const [ineligibilityReason, setIneligibilityReason] =
    useState<IneligibilityReason>()
  const [eligibleStateId, setEligibleStateId] = useState<number>()
  const [eligibleInsuranceId, setEligibleInsuranceId] = useState<number>()
  const [showEligibleModal, setShowEligibleModal] = useState(false)

  if (isDismissed) return null

  return (
    <div
      className={classnames(css.InsuranceEligibilityValidatorTool, 'mbxl', {
        [css.InsuranceEligibilityValidatorTool__variant_guide]:
          variant === 'guide',
        [css.InsuranceEligibilityValidatorTool__variant_feed]:
          variant === 'feed',
      })}
    >
      {dismissalContentfulId && (
        <DismissButton
          id={dismissalContentfulId}
          onDismiss={() => setIsDismissed(true)}
        />
      )}

      <EligibilityModal
        eligibleInsuranceId={eligibleInsuranceId}
        eligibleStateId={eligibleStateId}
        isOpen={showEligibleModal}
        variant={variant}
        onClose={() => {
          setShowEligibleModal(false)
        }}
      />

      <div className={css.InsuranceEligibilityValidatorTool__container}>
        <div className={css.InsuranceEligibilityValidatorTool__content}>
          {ineligibilityReason ? (
            <IneligibilityHeading reason={ineligibilityReason} />
          ) : (
            <DefaultHeading />
          )}

          {ineligibilityReason ? (
            <IneligibilityButton
              reason={ineligibilityReason}
              onClick={() => setIneligibilityReason(undefined)}
            />
          ) : (
            <InsuranceEligibilityValidator
              variant={variant}
              onEligibilityCheck={handleEligibilityCheck({
                onIneligible: setIneligibilityReason,
                onEligible: ({ state, insurance }) => {
                  setEligibleStateId(state?.id)
                  setEligibleInsuranceId(insurance?.id)
                  setShowEligibleModal(true)
                },
              })}
            />
          )}
        </div>
      </div>
    </div>
  )
}

export default InsuranceEligibilityValidatorTool
