import {
  every,
  filter,
  find,
  flatten,
  get,
  groupBy,
  isEqual,
  keys,
  map,
  orderBy,
  partition,
  reject,
  sortBy,
  uniq,
  uniqWith,
} from 'lodash'
import Big from 'big.js'
import * as BLConstants from 'shared/constants'

export const prioritizedStores = {
  Babylist: 1,
  'Pottery Barn Kids': 2,
  Wayfair: 3,
  Walmart: 4,
  'Buy Buy Baby': 5,
  Nordstrom: 6,
  Target: 7,
  'Crate and Barrel': 8,
  Etsy: 9,
  Amazon: 10,
}

export const getBestOfferStore = (offers) => {
  const offerStores = map(offers, 'storeName')
  let bestMatchRanking = null
  let bestMatchStore = offerStores[0]

  offerStores.forEach((store) => {
    const match = prioritizedStores[store]
    if (match && (!bestMatchRanking || match < bestMatchRanking)) {
      bestMatchStore = store
      bestMatchRanking = match
    }
  })

  return bestMatchStore
}

export const initializePurchasingStore = (reservation) => {
  // group gift funds only purchasable through Babylist
  // always purchasable regardless of product's store availability
  if (reservation.isGroupGiftContribution) {
    reservation.store = 'Babylist'
    return reservation
  }

  const offers = offersWithLinks(reservation.regItem.offers)
  // only consider items that have offers with valid URLs
  if (!offers.length) {
    return reservation
  }

  // Remove BabyList offer if it cannot be purchased from Babylist and we have other offers
  // If we don't have other offers, keep the babylist offer.
  // A warning will be displayed on the checkout page
  if (
    !reservation.isPurchasableOnBabylist &&
    reservation.regItem.offers.length > 1
  ) {
    reservation.regItem.offers = reject(offers, (o) => {
      return o.storeName == 'Babylist'
    })
  }

  const bestOfferStore = getBestOfferStore(reservation.regItem.offers)

  reservation.store = bestOfferStore
  reservation.prevStore = null
  return reservation
}

export const offersWithLinks = (offers) => {
  return filter(offers, 'affiliateUrl')
}

export const pluralizeWithFirstNames = (
  firstNamesStr,
  pluralExtension,
  singularFallback
) => {
  if (firstNamesStr) {
    return `${firstNamesStr}${pluralExtension}`
  }
  return singularFallback
}

export const purchasedReservations = (reservations) => {
  return filter(reservations, reservationIsPurchased)
}

export const reservationIsPurchased = (res) => res.isPurchased == true

export const reservationsWithoutLinks = (reservations) => {
  return filter(
    reservations,
    (res) => !offersWithLinks(res.regItem.offers).length
  )
}

export const sortStoresByPriority = (stores) => {
  return sortBy(stores, (store) => {
    if (store in prioritizedStores) {
      return prioritizedStores[store].toString()
    }
    return store.toLowerCase()
  })
}

export const uncompletedActiveReservations = (
  reservations,
  maintenanceMode = false,
  disableBabylistOffer = false
) => {
  let filteredReservations = filter(reservations, (r) => {
    if (
      maintenanceMode &&
      r.regItem.offers &&
      every(r.regItem.offers, ['storeName', 'Babylist'])
    ) {
      return false
    }
    return (
      (r.isGroupGiftContribution || r.regItem.isPurchasable) &&
      !r.isPurchased &&
      r.isActive &&
      !r.unpurchasable
    )
  })

  if (maintenanceMode || disableBabylistOffer) {
    filteredReservations = filteredReservations.map((r) => {
      if (r.store == 'Babylist' && r.regItem.offers) {
        const newStore = get(
          r.regItem.offers.filter((o) => o.storeName != 'Babylist')[0],
          'storeName'
        )
        if (newStore) {
          r.store = newStore
        }
      }
      return r
    })
  }

  return sortBy(filteredReservations, (reservation) => {
    // Sort based on our preferred stores if possible, falling back to alpha
    if (reservation.store in prioritizedStores) {
      return prioritizedStores[reservation.store].toString() // Make everything a string so it will integrate with the fallback alpha sort
    }
    if (reservation.store) {
      return reservation.store.toLowerCase()
    }
  })
}

export const uncompletedExternalReservations = (
  reservations,
  maintenanceMode = false,
  disableBabylistOffer = false
) => {
  return filter(
    uncompletedActiveReservations(
      reservations,
      maintenanceMode,
      disableBabylistOffer
    ),
    (r) => r.store != 'Babylist'
  )
}

export const getBabylistReservations = (reservations) => {
  return filter(
    uncompletedActiveReservations(reservations),
    (r) => r.store === 'Babylist'
  )
}

export const getCrowdfundReservations = (reservations) => {
  return filter(reservations, (r) => r.regItem.isCrowdfund)
}

export const getFavorReservations = (reservations) => {
  return filter(reservations, (r) => r.regItem.isFavor)
}

export const getGiftCardImageUrl = (id, giftCardProducts) => {
  return find(giftCardProducts, (gc) => gc.id === id)?.image?.thumbnailUrl
}

export const getGiftCardProductId = (price, giftCardProducts) => {
  return find(
    giftCardProducts,
    (gc) => parseFloat(gc.price) === parseFloat(price)
  )?.id
}

export const getGiftCardsByType = (giftCardProducts) =>
  groupBy(giftCardProducts, 'productType')

export const getUnpurchasableReservations = (reservations) => {
  return filter(reservations, (r) => r.unpurchasable)
}

export const getRegCartItems = (cartItems) =>
  filter(cartItems, (i) => i.type === BLConstants.CONFIG.cart.typeRegistry)

export const getStoreCartItems = (cartItems) =>
  filter(cartItems, (i) => i.type === BLConstants.CONFIG.cart.typeStore)

export const getStoreCartItemsByRegistrySlug = (cartItems) =>
  groupBy(
    getStoreCartItems(cartItems),
    (cartItem) => cartItem.regItem?.registryUrlSlug || ''
  )

export const getStores = (reservations) => {
  const uncompletedReservationsByStore = groupBy(
    uncompletedActiveReservations(reservations),
    'store'
  )
  const reservationStores = keys(uncompletedReservationsByStore)
  return sortStoresByPriority(uniq(reservationStores))
}

export const getTotalQuantity = (
  reservations = [],
  cart = null,
  amazonCart = null
) => {
  let sum = 0
  if (amazonCart && amazonCart.items) {
    amazonCart.items.forEach((item) => {
      sum += item.quantity
    })
  }
  if (cart && cart.items) {
    const inStockItems = filter(cart.items, (i) => !i.isOutOfStock)
    inStockItems.forEach((item) => {
      sum += item.quantity
    })
  }
  reservations.forEach((reservation) => {
    if (reservation.regItem && reservation.regItem.isPurchasable) {
      sum += reservation.quantity
    }
  })
  return sum
}

export const buildCartItems = (
  amazonCart,
  cart,
  reservations,
  storeName,
  includeRegistryDiscount = false
) => {
  const items = []

  if (amazonCart && amazonCart.items) {
    amazonCart.items.forEach(({ id, imageUrl, price, title, quantity }) => {
      items.push({
        imageUrl,
        price,
        name: title,
        quantity,
        type: BLConstants.CONFIG.cart.typeAmazonCart,
        uuid: `amazonCart-${id}`,
      })
    })
  }

  if (cart && cart.items) {
    cart.items.forEach((item) => {
      items.push({
        callouts: item.callouts,
        canPurchaseWithUserCredit: item.canPurchaseWithUserCredit,
        bundleComponents: item.bundleComponents,
        createdAtS: item.createdAtS,
        fsaHsaEligible: item.fsaHsaEligible,
        productId: item.productId,
        imageUrl: item.imageUrl,
        isElectronicGiftCard: item.isElectronicGiftCard,
        isEligibleForFreeShipping: item.isEligibleForFreeShipping,
        isEligibleForRegistryDiscount: item.isEligibleForRegistryDiscount,
        isGiftCard: item.isGiftCard && item.giftCardOptions?.length > 0,
        isPurchasableOnBabylist: true,
        isPurchasable: true,
        isPurchased: false,
        isTaxable: item.isTaxable,
        giftCardOptions: item.giftCardOptions,
        name: item.name,
        price: item.price,
        quantity: item.quantity,
        quantityLimit: item.quantityLimit,
        registryDiscountPrice:
          item.isEligibleForRegistryDiscount && includeRegistryDiscount
            ? getRegistryDiscountPrice(item.price)
            : null,
        extendedDeliveryTimeMessage: item.extendedDeliveryTimeMessage,
        type: BLConstants.CONFIG.cart.typeStore,
        uuid: item.uuid,
        storeUrl: item.storeUrl,
        saleType: item.priceDetails?.saleAttributes?.saleType,
        priceDetails: item.priceDetails || {},
        regItem: item.regItem,
      })
    })
  }

  if (reservations) {
    reservations.forEach((reservation) => {
      let price
      if (
        reservation.regItem.isCashGift ||
        reservation.regItem.isGiftCard ||
        reservation.isGroupGiftContribution
      ) {
        price = reservation.giftAmount
      } else {
        const offer = storeName
          ? reservation.regItem.offers.find(
              (offer) => offer.storeName == storeName
            )
          : reservation.regItem.offers[0]
        price = offer ? offer.price : 0
      }

      const { isPurchasableOnBabylist } = reservation
      const giftCardImgUrl = reservation.regItem.isGiftCard
        ? getGiftCardImageUrl(
            reservation.productId,
            reservation.giftCardOptions
          )
        : null

      items.push({
        canPurchaseWithUserCredit:
          isPurchasableOnBabylist &&
          !reservation.regItem.isCashGift &&
          !reservation.regItem.isGiftCard &&
          !reservation.isGroupGiftContribution,
        bundleComponents: reservation.bundleComponents,
        createdAtS: new Date(reservation.createdAt).getTime() / 1000,
        giftAmount: reservation.giftAmount,
        imageUrl: giftCardImgUrl || reservation.regItem.imgUrl,
        isEligibleForFreeShipping:
          isPurchasableOnBabylist &&
          !reservation.regItem.isCashGift &&
          !reservation.regItem.isGiftCard &&
          !reservation.isGroupGiftContribution,
        isEligibleForRegistryDiscount:
          isPurchasableOnBabylist && reservation.isEligibleForRegistryDiscount,
        isDigital: reservation.regItem.isDigitalItem,
        isPurchasableOnBabylist,
        isGiftCard:
          reservation.regItem.isGiftCard &&
          reservation.giftCardOptions?.length > 0,
        isPurchased: reservation.isPurchased,
        isPurchasable: reservation.regItem.isPurchasable,
        isTaxable: isPurchasableOnBabylist
          ? reservation.regItem.isTaxable
          : null,
        name:
          reservation.isGroupGiftContribution || reservation.regItem.isGiftCard
            ? reservation.displayTitle
            : reservation.regItem.title,
        price,
        quantity: reservation.quantity,
        registryDiscountPrice:
          reservation.regItem.isEligibleForRegistryDiscount &&
          includeRegistryDiscount &&
          isPurchasableOnBabylist
            ? getRegistryDiscountPrice(price)
            : null,
        reservationId: reservation.id,
        reservationToken: reservation.token,
        type: BLConstants.CONFIG.cart.typeRegistry,
        productId: reservation.productId,
        regItemId: reservation.regItemId,
      })
    })
  }

  // Initially we will have cart items without an createdAtS b/c of legacy session data
  // flatten and partition can be removed shortly after launch
  return flatten(
    partition(orderBy(items, ['createdAtS'], ['desc']), 'createdAtS')
  )
}

export const calculateTax = (price, taxRate) => {
  const itemTax = Big(price).times(taxRate)
  return Big(Math.floor(itemTax.times(100))).div(100)
}

export const getAmountEligibleForUserCredit = (
  cartItems,
  taxRate = 0,
  includeRegistryDiscount = false
) => {
  let amount = getShippingCost(cartItems, includeRegistryDiscount)

  const eligibleItems = cartItems.filter(
    (cartItem) => cartItem.canPurchaseWithUserCredit
  )
  amount = amount.plus(getSubTotal(eligibleItems))
  if (includeRegistryDiscount) {
    amount = amount.minus(getRegistryDiscountSubtotal(eligibleItems))
  }

  amount = amount.plus(
    getEstimatedTax(eligibleItems, Big(taxRate), includeRegistryDiscount)
  )
  return amount
}

export const getEstimatedTax = (
  cartItems,
  taxRate = 0,
  includeRegistryDiscount = false
) => {
  if (taxRate == 0) {
    return Big(0)
  }

  let estTax = Big(0)
  cartItems.forEach((cartItem) => {
    if (cartItem.isTaxable) {
      if (cartItem.price) {
        const itemTax = calculateTax(
          getPrice(cartItem, includeRegistryDiscount),
          taxRate
        )
        estTax = estTax.plus(itemTax.times(cartItem.quantity))
      }
    }
  })

  return estTax
}

export const getPrice = (cartItem, includeRegistryDiscount = false) => {
  const basePrice = Big(cartItem.price)
  return includeRegistryDiscount && cartItem.isEligibleForRegistryDiscount
    ? basePrice.minus(getRegistryDiscount(basePrice))
    : basePrice
}

export const getRegistryDiscountPrice = (price) =>
  price && Big(price).minus(getRegistryDiscount(Big(price)))

export const getRegistryDiscount = (price) =>
  // Round UP so that we always provide at least the promised discount amount
  price &&
  Big(
    Math.ceil(
      Big(price)
        .times(Big(BLConstants.CONFIG.store.registryDiscountPct))
        .times(100)
    )
  ).div(100)

export const getRegistryDiscountSubtotal = (cartItems) => {
  let registryDiscount = Big(0)

  cartItems
    .filter((cartItem) => cartItem.isEligibleForRegistryDiscount)
    .forEach((cartItem) => {
      const amount = getRegistryDiscount(Big(cartItem.price)).times(
        cartItem.quantity
      )
      registryDiscount = registryDiscount.plus(amount)
    })
  return registryDiscount
}

export const getShippingCost = (
  cartItems,
  includeRegistryDiscount = false,
  productSurcharge = null
) => {
  const itemsEligibleForFreeShipping = cartItems.filter(
    (cartItem) => cartItem.isEligibleForFreeShipping
  )
  let shippingSubtotal = getSubTotal(itemsEligibleForFreeShipping)
  if (includeRegistryDiscount) {
    shippingSubtotal = shippingSubtotal.minus(
      getRegistryDiscountSubtotal(itemsEligibleForFreeShipping)
    )
  }

  if (productSurcharge) {
    return new Big(productSurcharge)
  }

  if (
    itemsEligibleForFreeShipping.length &&
    shippingSubtotal.lt(BLConstants.FREE_SHIPPING_THRESHOLD)
  ) {
    return new Big(BLConstants.STANDARD_SHIPPING_PRICE)
  }
  return new Big(0)
}

export const getSubTotal = (cartItems) => {
  let subTotal = new Big(0)

  cartItems
    .filter((cartItem) => !cartItem.isPurchased && cartItem.price)
    .forEach((cartItem) => {
      const amount = new Big(cartItem.price).times(cartItem.quantity)
      subTotal = subTotal.plus(amount)
    })

  return subTotal
}

export const getRegistrySlugsAndTitles = ({
  storeCartItems,
  registry,
  reservations,
}) => {
  const registries = []
  let includeBlankRegistry = false
  if (registry && reservations && reservations.length > 0) {
    registries.push({ title: registry.title, urlSlug: registry.urlSlug })
  }
  storeCartItems.forEach((cartItem) => {
    if (cartItem.regItem) {
      registries.push({
        title: cartItem.regItem.registryTitle,
        urlSlug: cartItem.regItem.registryUrlSlug,
      })
    } else {
      includeBlankRegistry = true
    }
  })
  const uniqRegistries = uniqWith(registries, isEqual)
  if (includeBlankRegistry) {
    uniqRegistries.push(null)
  }
  return uniqRegistries
}
