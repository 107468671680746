// eslint-disable-next-line import/no-extraneous-dependencies
import { Dropdown } from 'baby-design'
import { useState } from 'react'
import { ProductComparisonProps } from './ProductComparison.types'
import css from './ProductComparison.styles.scss'
import ComparisonProduct from './components/ComparisonProduct'

const ProductComparison = ({ fields }: ProductComparisonProps) => {
  const { comparisonProducts } = fields

  const maxColumns =
    comparisonProducts.length < 4 ? comparisonProducts.length : 4

  const [columnIndexStates, setColumnIndexStates] = useState(
    [...Array(maxColumns)].map((_, i) => i * 1)
  )

  const comparisonProductOptions = comparisonProducts.map(
    (comparisonProduct, index) => ({
      label: comparisonProduct.fields.product.fields.name,
      value: index,
    })
  )

  const updateColumnIndexStates = (
    index: number,
    newValue: number | undefined
  ) => {
    if (typeof newValue === 'undefined') return

    const oldColumnIndexStates = [...columnIndexStates]
    oldColumnIndexStates[index] = newValue
    setColumnIndexStates(oldColumnIndexStates)
  }

  const comparisonAttributes =
    comparisonProducts[0].fields.comparisonFields.map(
      (field) => field.fields.attributeName
    )

  return (
    <section>
      <div className={css.ProductComparison__headertext}>
        {fields.headerText}
      </div>
      <div className={css.ProductComparison__gridContainer}>
        <div className={css.ProductComparison__column}>
          {comparisonAttributes.map((attribute) => (
            <div
              className={css.ProductComparison__attributeCell}
              key={attribute}
            >
              {attribute}
            </div>
          ))}
        </div>
        {[...Array(maxColumns)].map((_, index) => (
          <div className={css.ProductComparison__column}>
            <Dropdown
              className={css.ProductComparison__dropdown}
              defaultValue={comparisonProductOptions[index]}
              options={comparisonProductOptions}
              size="sm"
              onChange={(selectedOption) => {
                updateColumnIndexStates(index, selectedOption?.value)
              }}
            />
            <ComparisonProduct
              comparisonAttributes={comparisonAttributes}
              fields={comparisonProducts[columnIndexStates[index]].fields}
            />
          </div>
        ))}
      </div>
    </section>
  )
}

export default ProductComparison
