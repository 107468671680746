import React from 'react'
import classNames from 'classnames'
import { MOBILE_VARIANTS } from '../../constants'
import { ModalActionsProps } from './ModalActions.types'
import css from './ModalActions.styles.scss'

const { value: BASIC_VALUE, class: BASIC_CLASS } = MOBILE_VARIANTS.basic

const ModalActions: React.FC<ModalActionsProps> = ({
  additionalLink,
  alignButton,
  mobileVariant,
  primaryButton,
  secondaryButton,
}) => {
  const topWrapperClass = classNames(css.ModalActions, {
    [css[`ModalActions--${BASIC_CLASS}`]]: mobileVariant === BASIC_VALUE,
    [css['ModalActions--additionalLink']]: additionalLink,
    [css['ModalActions--noAdditionalLink']]: !additionalLink,
    [css['ModalActions--rightAlignButton']]: alignButton === 'right',
    [css['ModalActions--leftAlignButton']]: alignButton === 'left',
  })

  return (
    <div className={topWrapperClass} data-testid="modal-actions">
      {additionalLink ? (
        <>
          <div className={css.ModalActions__additionalLink}>
            {additionalLink}
          </div>
          <div className={css.ModalActions__buttons}>
            {secondaryButton}
            {primaryButton}
          </div>
        </>
      ) : (
        <div className={css.ModalActions__buttons}>
          {secondaryButton}
          {primaryButton}
        </div>
      )}
    </div>
  )
}

export default ModalActions
