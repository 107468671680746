import TableRow, { TypeTableRowFields } from './TableRow'
import css from './styles.scss'

interface TableRowEntryReference {
  fields: TypeTableRowFields
  sys: Record<string, unknown>
}

interface TypePostTableSectionFields {
  title: string
  tableTitle?: string
  tableRows: TableRowEntryReference[]
}

export interface TableSectionProps {
  fields: TypePostTableSectionFields
}

const TableSection: React.FC<TableSectionProps> = ({
  fields,
}: TableSectionProps) => {
  if (!fields.tableRows) {
    return null
  }
  return (
    <section>
      <table className={css.Table}>
        {!!fields.tableTitle && <caption>{fields.tableTitle}</caption>}
        <tbody>
          {fields.tableRows.map((row) => (
            <TableRow fields={row.fields} key={row.fields.title} />
          ))}
        </tbody>
      </table>
    </section>
  )
}

export default TableSection
