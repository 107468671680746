import { track } from 'lib/analytics'
import { registryProperties } from 'src/lib/tracking/shared/bundles/registryProperties'
import { RegItem } from 'src/types/regItem'
import { EventPropertiesProps } from 'src/lib/tracking/shared/bundles/eventProperties'
import { CurrentUser } from '../../../../../global'

type TrackRegistryItemDetailsViewedProps = EventPropertiesProps & {
  currentUser: CurrentUser | undefined
  regItem: RegItem
  tracker: any
}

export const trackRegistryItemDetailsViewed = ({
  currentUser,
  regItem,
  tracker,
  ...eventProperties
}: TrackRegistryItemDetailsViewedProps) => {
  tracker.trackEvent({
    event: track.registryItemDetailsViewed,
    ...eventProperties,
    ...registryProperties(currentUser),
    regItemId: regItem.id,
    regItemPrice: regItem.price || 0,
    regItemQuantity: regItem.quantity || 0,
  })
}
