import { useState, useEffect } from 'react'

/**
 * **ATT: This is a single purpose hook to solve a specific edge case for this component.**
 *
 * @param shiftBy
 */
export default function usePosition(shiftBy: number) {
  const [styles, setStyles] = useState<{
    [key: string]: string | number
  }>({})

  /**
   * Because `bowserFlags` won't detect the device type (mobile or desktop) until the payload is
   * handed to the client, the position direction (top or bottom) will be set to 0 initially. And won't
   * update the `style` after the component is rendered by the browser.
   *
   * The reason behind this is that when SSR, React does not update the styles of a node even if they changed.
   * This behavior was introduced in React 16. This version does not guarantee that the result
   * will be correct if there is a mismatch. React 16 inserts extra nodes but does not patch up
   * the styles because it would be pretty expensive and negate the performance benefits. It is
   * expected of developers to fix mismatches in development so that the app can be fast in production.
   */
  useEffect(() => {
    setStyles({ bottom: shiftBy })
  }, [shiftBy])

  return styles
}
