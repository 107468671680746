import React from 'react'
import { RadioButton } from '../../../../../../../../../../../baby-design'
import { PurchaserFormGroupProps } from './PurchaserFormGroup.types'
import css from './PurchaserFormGroup.styles.scss'

const PurchaserFormGroup: React.FC<PurchaserFormGroupProps> = ({
  children,
  label,
  id,
  selected,
  onSelected,
}) => {
  const handleSelectionChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.checked) {
      onSelected?.()
    }
  }

  return (
    <div className={css.PurchaserFormGroup}>
      <RadioButton
        className={css.PurchaserFormGroup__RadioButton}
        size="lg"
        inputProps={{
          id,
          checked: selected,
          name: 'purchaser_option',
          onChange: handleSelectionChange,
        }}
      />
      <div className={css.PurchaserFormGroup__Content}>
        <label
          className={css.PurchaserFormGroup__RadioButton__Label}
          htmlFor={id}
        >
          {label}
        </label>
        {selected && (
          <div className={css.PurchaserFormGroup__FormElements}>{children}</div>
        )}
      </div>
    </div>
  )
}

export default PurchaserFormGroup
