import React from 'react'
import classNames from 'classnames'
import { MOBILE_VARIANTS } from '../../constants'
import { ModalHeaderProps } from './ModalHeader.types'
import css from './ModalHeader.styles.scss'

const { value: BASIC_VALUE, class: BASIC_CLASS } = MOBILE_VARIANTS.basic

const ModalHeader: React.FC<ModalHeaderProps> = ({
  icon,
  mobileVariant,
  subTitle,
  title,
}) => {
  const topWrapperClass = classNames(css.ModalHeader, {
    [css[`ModalHeader--${BASIC_CLASS}`]]: mobileVariant === BASIC_VALUE,
  })

  return (
    <header className={topWrapperClass} data-testid="modal-header">
      {icon && (
        <div className={css.ModalHeader__icon__wrapper}>
          <div className={css.ModalHeader__icon}>{icon}</div>
        </div>
      )}
      {title && (
        <div className={css.ModalHeader__title__wrapper}>
          <h1 className={css.ModalHeader__title}>{title}</h1>
        </div>
      )}
      {subTitle && (
        <div className={css.ModalHeader__subTitle__wrapper}>
          <h2 className={css.ModalHeader__subTitle}>{subTitle}</h2>
        </div>
      )}
    </header>
  )
}

export default ModalHeader
