import { Node } from '@contentful/rich-text-types'
import { isHelloBabyUrl } from 'lib/urls'
import { ReactNode } from 'react'

export interface HyperlinkProps {
  node: Node
  children: ReactNode
  className?: string
}

const Hyperlink: React.FC<HyperlinkProps> = ({
  node,
  children,
  className = '',
}: HyperlinkProps) => {
  const uri = node.data?.uri

  if (!uri) return null
  if (isHelloBabyUrl(uri) || uri.startsWith('#')) {
    return (
      <a className={className} href={uri}>
        {children}
      </a>
    )
  }
  return (
    <a
      className={className}
      href={uri}
      rel="noopener noreferrer"
      target="_blank"
    >
      {children}
    </a>
  )
}

export default Hyperlink
