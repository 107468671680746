import css from './Video.styles.scss'

interface PostEmbedVideoFields {
  url: string
  title?: string
}

interface EmbedVideoProps {
  fields: PostEmbedVideoFields
}

const EmbedVideo: React.FC<EmbedVideoProps> = ({ fields }: EmbedVideoProps) => {
  const url = new URL(fields.url)
  let videoId = url.searchParams.get('v')

  if (!videoId) {
    const pathParts = url.pathname.split('/')
    videoId = pathParts[pathParts.length - 1]
  }

  return (
    <div
      className={css.Embed__Youtube}
      data-testid={`richTextBody-video-${videoId}`}
    >
      <iframe
        allowFullScreen
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
        frameBorder="0"
        src={`https://www.youtube.com/embed/${videoId}?feature=oembed&modestbranding=1&showinfo=0&rel=0&controls=1`}
        title={fields.title || ''}
      />
    </div>
  )
}

export default EmbedVideo
