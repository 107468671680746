import React from 'react'
import Toast from 'baby-design/components/Toast/Toast'
import { CartItemAddedToastProps } from 'shared/CartItemAddedToast/CartItemAddedToast.types'
import { DEFAULT_PRODUCT_IMAGE_PATH } from 'shared/constants'
import { reservationsPath } from 'lib/urls'

const CartItemAddedToast: React.FC<CartItemAddedToastProps> = ({
  isOpen,
  onDismiss,
  product,
}) => {
  const productImage = product.images[0]?.url || DEFAULT_PRODUCT_IMAGE_PATH
  const handleClick = () => {
    window.location.href = reservationsPath
  }

  return (
    <Toast
      cta={{
        text: 'View cart',
        onClick: handleClick,
      }}
      eyebrow="Added to cart"
      imageProps={{
        imageSrc: productImage,
        imageAlt: product.name,
      }}
      isOpen={isOpen}
      title={product.name}
      variant="rich"
      onDismiss={onDismiss}
    />
  )
}

export default CartItemAddedToast
