import { TextLink } from 'baby-design'
import { absoluteUrl, reservationsPath } from 'lib/urls'
import css from './InCartLink.styles.scss'

const InCartLink = () => {
  return (
    <div className={css.InCartLink}>
      <TextLink variant="eggplant" href={absoluteUrl(reservationsPath)}>
        In Cart
      </TextLink>
    </div>
  )
}

export default InCartLink
