import { PureComponent, useState } from 'react'
import PropTypes from 'prop-types'

import MobileNavModal from 'shared/mobile-nav-modal'
import CartSummary from 'cart/components/cart-summary'
import classNames from 'classnames'
import { camelCase, mapKeys } from 'lodash'
import {
  absoluteUrl,
  faqPath,
  loginPath,
  loginPathWithRedirect,
  newRegistryPath,
} from 'lib/urls'
import { initializePurchasingStore } from 'cart/lib'
import {
  ACTIVE_NAV_SECTION_EVENT,
  HIDE_MOBILE_NAV_MODAL_EVENT,
} from 'shared/constants'
import { track, useTracking, withContextualizedTracking } from 'lib/analytics'
import { setCurrentUser } from 'shared/utils/setCurrentUser/setCurrentUser'
import withCurrentUser from 'shared/HOC/withCurrentUser'
import MessageCenterMenu from 'components/global-nav/components/MessageCenterMenu'
import { Button, TextLink } from 'baby-design'
import useFeatureFlag from 'shared/hooks/useFeatureFlag/useFeatureFlag'
import css from './global-nav.scss'
import AccountMenu from './components/AccountMenu/AccountMenu'
import ToggleNav from './components/ToggleNav'
import SiteNavSection from './components/SiteNavSection'
import SearchBar from './components/SearchBar/DesktopSearchBar'
import MobileSearchBar from './components/SearchBar/MobileSearchBar'
import Logo from './components/Logo/Logo'

const chooseEventLocation = (activeNavItem) => {
  switch (activeNavItem) {
    case 'Home':
      return track.EventLocation.HOME_PAGE
    case 'Registry':
      return track.EventLocation.REGISTRY
    case 'Holiday':
      return track.EventLocation.NONE
    case 'Guides':
      return track.EventLocation.GUIDE
    case 'Store':
      return track.EventLocation.STORE
    default:
      return track.EventLocation.NONE
  }
}

@withContextualizedTracking((props) => {
  // use to track cart opened eventLocation
  const { activeNavItem } = props
  const eventLocation = chooseEventLocation(activeNavItem)
  return { eventLocation }
})
class GlobalNav extends PureComponent {
  constructor(props) {
    super(props)
    this.state = {
      activeNavItem: props.activeNavItem,
      sideNavVisible: false,
    }
    this.handleToggleNav = this.handleToggleNav.bind(this)
  }

  componentDidMount() {
    PubSub.subscribe(HIDE_MOBILE_NAV_MODAL_EVENT, () => {
      this.setState({ sideNavVisible: false })
    })
    PubSub.subscribe(ACTIVE_NAV_SECTION_EVENT, (event) => {
      this.setState({ activeNavItem: event })
    })
    PubSub.subscribe('switchNavState', (user) => {
      const formattedUser = mapKeys(user, (_, key) => camelCase(key))
      setCurrentUser(formattedUser)
    })
  }

  handleToggleNav() {
    this.setState({
      sideNavVisible: !this.state.sideNavVisible,
    })
  }

  render() {
    const {
      currentCart,
      currentUser,
      hideCartSummary,
      isRegistryGuestView,
      showMinimalView,
      showAcquisitionView,
      isLoggedIn,
      ...restOfProps
    } = this.props

    const { activeNavItem, sideNavVisible } = this.state

    return (
      <StatelessGlobalNav
        activeNavItem={activeNavItem}
        currentCart={currentCart}
        currentUser={currentUser}
        handleToggleNav={this.handleToggleNav}
        hideCartSummary={hideCartSummary}
        isLoggedIn={isLoggedIn}
        isRegistryGuestView={isRegistryGuestView}
        showAcquisitionView={showAcquisitionView}
        showMinimalView={showMinimalView}
        sideNavVisible={sideNavVisible}
        {...restOfProps}
      />
    )
  }
}

GlobalNav.propTypes = {
  activeNavItem: PropTypes.string,
  currentCart: PropTypes.object,
  showMinimalView: PropTypes.bool,
  userCanViewRegistry: PropTypes.bool,
  isLoggedIn: PropTypes.bool,
}

const StatelessGlobalNav = (props) => {
  const {
    currentCart,
    currentUser,
    hideCartSummary,
    isRegistryGuestView,
    showMinimalView,
    showAcquisitionView,
    isLoggedIn,
    activeNavItem,
    sideNavVisible,
    handleToggleNav,
  } = props

  const tracker = useTracking()

  const trackClick = (eventCta) => {
    tracker.trackEvent({
      event: track.navbarClicked,
      eventCta,
      eventLocation: track.EventLocation.WEB_TOP_NAV,
    })
  }

  const eventLocation = chooseEventLocation(activeNavItem)

  return (
    <nav className={css.globalNav}>
      <div className={css.flexRow}>
        <Logo trackClick={trackClick} />

        <SiteNavSection
          activeNavItem={activeNavItem}
          className={css.GlobalNav__SiteNavSection}
          handleToggleNav={handleToggleNav}
          isLoggedIn={isLoggedIn || !!currentUser}
          showAcquisitionView={showAcquisitionView}
          showMinimalView={isRegistryGuestView || showMinimalView}
          trackClick={trackClick}
        />

        <ul className={css.GlobalNav__ActionsNavSection}>
          <li className={classNames(css.GlobalNav__Search)}>
            <SearchBar
              className={css.GlobalNav__SearchBar}
              eventLocation={eventLocation}
              isLoggedIn={isLoggedIn || !!currentUser}
            />
          </li>
          <ActionsNavSection
            cart={currentCart}
            hideCartSummary={hideCartSummary}
            isLoggedIn={isLoggedIn || !!currentUser}
            showAcquisitionView={showAcquisitionView}
            showMinimalView={isRegistryGuestView || showMinimalView}
            trackClick={trackClick}
          />
        </ul>
        <MobileNavModal
          {...props}
          show={sideNavVisible}
          onHide={handleToggleNav}
        />
      </div>
      <MobileSearchBar
        className={css.GlobalNav__MobileSearchBar}
        eventLocation={eventLocation}
        isLoggedIn={isLoggedIn || !!currentUser}
      />
    </nav>
  )
}

const ActionsNavSection = ({
  cart,
  hideCartSummary,
  showMinimalView,
  isLoggedIn,
  showAcquisitionView,
  trackClick,
}) => {
  const reservations = cart.reservations.map((res) =>
    initializePurchasingStore(res)
  )
  // type ActiveMenu = 'account' | 'messageCenter' | 'cart'
  const [activeMenu, setActiveMenu] = useState('account')

  return (
    <>
      {!showAcquisitionView && !isLoggedIn && (
        <li className={css.fullNavOnly}>
          <TextLink
            className={css.actionLink}
            href={faqPath}
            rel="noopener noreferrer"
            size="md"
            target="_blank"
            weight="regular"
            onClick={() => trackClick('Help')}
          >
            Help
          </TextLink>
        </li>
      )}

      {!showMinimalView &&
        (isLoggedIn ? (
          <li className={css.fullNavOnly}>
            <AccountMenu
              activeMenu={activeMenu}
              trackNavbarClicked={trackClick}
              onMenuOpen={() => setActiveMenu('account')}
            />
          </li>
        ) : (
          // logged out
          <>
            <li className={css.fullNavOnly}>
              <TextLink
                className={css.actionLink}
                href={loginPath}
                size="md"
                weight="regular"
                onClick={(e) => {
                  e.preventDefault()
                  window.location.href = loginPathWithRedirect(
                    window.location.pathname
                  )
                }}
              >
                Log In
              </TextLink>
            </li>
            <li className={css.fullNavOnly}>
              <Button
                className={css.actionLink}
                href={absoluteUrl(newRegistryPath)}
                size="sm"
                variant="primary"
                onClick={() => trackClick('Sign Up')}
              >
                Sign Up
              </Button>
            </li>
          </>
        ))}

      {isLoggedIn && (
        <li
          className={css.GlobalNav__MessageCenter}
          style={{ position: 'relative' }}
        >
          <MessageCenterMenu
            activeMenu={activeMenu}
            onMenuOpen={() => setActiveMenu('messageCenter')}
          />
        </li>
      )}

      {!showAcquisitionView && (
        <li style={{ position: 'relative' }}>
          <CartSummary
            activeMenu={activeMenu}
            cart={cart.cart}
            disableDropdown={hideCartSummary}
            registryUrlSlug={cart.registryUrlSlug}
            reservations={reservations}
            onMenuOpen={() => setActiveMenu('cart')}
          />
        </li>
      )}
    </>
  )
}

ActionsNavSection.propTypes = {
  cart: PropTypes.object,
  showMinimalView: PropTypes.bool,
  isLoggedIn: PropTypes.bool,
  showAcquisitionView: PropTypes.bool,
}

export default withCurrentUser(GlobalNav)
export { SiteNavSection, ActionsNavSection, Logo, ToggleNav }
