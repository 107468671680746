import NiceModal, { useModal } from '@ebay/nice-modal-react'
// eslint-disable-next-line import/no-extraneous-dependencies
import { RightDrawer } from 'baby-design'

export const GiftDetailsDrawer = NiceModal.create(() => {
  const modal = useModal()

  const handleBack = () => {
    modal.resolve(undefined)
    modal.hide()
  }

  return (
    <RightDrawer
      handleClose={handleBack}
      isOpen={modal.visible}
      mainIcon="back"
      title="Gift details"
    >
      <p>Insert gift details here...</p>
    </RightDrawer>
  )
})
