import { ReactNode, CSSProperties, useState, useRef, useEffect } from 'react'
import css from './LineClamped.styles.scss'

interface LineClampedProps {
  showMoreComponent?: ReactNode
  children: ReactNode
  lines: number
}

const LineClamped: React.FC<LineClampedProps> = ({
  children,
  lines,
  showMoreComponent,
}: LineClampedProps) => {
  const contentRef = useRef<HTMLDivElement>(null)
  const [isClamped, setClamped] = useState(false)

  useEffect(() => {
    const checkClamped = () => {
      if (contentRef?.current) {
        setClamped(
          contentRef.current.scrollHeight > contentRef.current.clientHeight
        )
      }
    }

    // Check on initial render, then on resizes
    checkClamped()
    window.addEventListener('resize', checkClamped)

    // Remove event listener on cleanup
    return () => window.removeEventListener('resize', checkClamped)
  }, [children, lines])

  return (
    <>
      <div
        className={css.LineClamped}
        ref={contentRef}
        style={{ '--lines': lines } as CSSProperties}
      >
        {children}
      </div>
      {isClamped && showMoreComponent}
    </>
  )
}

export default LineClamped
