import React from 'react'
import Link from 'components/link'
import { Registry } from 'src/types/registry'

interface RegistryLinkProps {
  registry: Registry | null | undefined
}

const RegistryLink: React.FC<RegistryLinkProps> = ({ registry }) => (
  <>
    {registry ? (
      <Link newWindow url={`/list/${registry.urlSlug}`}>
        <span
          dangerouslySetInnerHTML={{
            __html: registry.title ? registry.title : 'your registry',
          }}
        />
      </Link>
    ) : (
      'your registry'
    )}
    !
  </>
)

export default RegistryLink
