import NiceModal, { useModal } from '@ebay/nice-modal-react'
import { ReactElement, useEffect } from 'react'

export interface ShowToastProps {
  onDismiss?: () => void
  onShow?: () => void
  render: (
    isOpen: boolean,
    handleDismiss: () => void
  ) => ReactElement<any, any> | null
  timeout?: number
}

let dismissActiveToast: (() => void) | null

export const showToast = ({
  onDismiss,
  onShow,
  render,
  timeout,
}: ShowToastProps) => {
  const WrappedToast = NiceModal.create(() => {
    const toast = useModal()

    const handleDismiss = () => {
      onDismiss?.()
      toast.hide()
      dismissActiveToast = null
    }

    useEffect(() => {
      if (dismissActiveToast) {
        dismissActiveToast()
      }

      if (timeout) {
        const timer = setTimeout(() => {
          handleDismiss()
        }, timeout)

        dismissActiveToast = () => {
          clearTimeout(timer)
          handleDismiss()
        }

        return () => clearTimeout(timer)
      }

      dismissActiveToast = handleDismiss
    }, [])

    useEffect(() => {
      if (toast.visible) {
        onShow?.()
      }
    }, [toast])

    return render(toast.visible, handleDismiss)
  })

  return NiceModal.show(WrappedToast)
}
