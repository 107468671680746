// Disabling these because the onClick here is essentially a scroll helper.
// (The "interaction" here is not applicable(?) to screen readers, etc.)
/* eslint-disable jsx-a11y/click-events-have-key-events, jsx-a11y/no-noninteractive-element-interactions */
import { ReactNode, useState, useRef, useEffect } from 'react'
import { throttle } from 'lodash'

import classNames from 'classnames'
import css from './Carousel.styles.scss'

interface CarouselProps {
  children: ReactNode
  headerText?: string
  horizontalMobileScroll?: boolean
  className?: string
}

const Carousel: React.FC<CarouselProps> = ({
  children,
  className,
  headerText,
  horizontalMobileScroll = false,
}: CarouselProps) => {
  const carouselRef = useRef<HTMLUListElement>(null)

  const [nextAvailable, setNextAvailable] = useState(true)
  const [previousAvailable, setPreviousAvailable] = useState(false)

  const getAllItemElements = () =>
    Array.from(carouselRef.current?.children || [])

  const scrollToCenterOfIndex = (index: number) => {
    const items = getAllItemElements()
    const item = items[index] as HTMLLIElement

    const itemMidPointOffset = // Scroll offset that centers the item
      item.offsetLeft +
      item.clientWidth / 2 -
      (carouselRef.current?.clientWidth || 0) / 2

    carouselRef.current?.scrollTo({
      left: itemMidPointOffset,
      behavior: 'smooth',
    })
  }

  const getCountOfElementsToLeftOfCenter = (offset = 0) => {
    const items = getAllItemElements() as HTMLLIElement[]
    const list = carouselRef.current
    const scrollPosition = list?.scrollLeft || 0

    const parentMidPoint = scrollPosition + (list?.clientWidth || 0) / 2

    return items.reduce((elementsToLeft, item) => {
      const itemMidPoint = item.offsetLeft + item.clientWidth / 2

      return elementsToLeft + (itemMidPoint <= parentMidPoint + offset ? 1 : 0)
    }, 0)
  }

  const scrollToClicked = (event: React.MouseEvent<HTMLElement>) => {
    const item = (event.target as HTMLElement).closest(
      `.${css.Carousel__item}`
    ) as HTMLLIElement

    if (!item) {
      return
    }

    const index = getAllItemElements().indexOf(item)
    scrollToCenterOfIndex(index)
  }

  const scrollToPrevious = () => {
    const closestIndex = getCountOfElementsToLeftOfCenter(-1)

    scrollToCenterOfIndex(closestIndex - 1)
  }

  const scrollToNext = () => {
    const closestIndex = getCountOfElementsToLeftOfCenter()

    scrollToCenterOfIndex(closestIndex)
  }

  const updateButtonStates = () => {
    if (!carouselRef.current) {
      return
    }

    const { clientWidth, scrollLeft, scrollWidth } = carouselRef.current

    setPreviousAvailable(scrollLeft !== 0)
    setNextAvailable(scrollWidth - scrollLeft !== clientWidth)
  }

  useEffect(() => {
    requestAnimationFrame(() => {
      updateButtonStates()
    })
  }, [])

  const onScroll = throttle(updateButtonStates, 300)

  return (
    <div className={css.Carousel}>
      <div
        className={classNames(css.Carousel__headerContainer, {
          [css['Carousel__headerContainer--withText']]: !!headerText,
        })}
      >
        {!!headerText && (
          <header className={css.Carousel__headerText}>{headerText}</header>
        )}
        <div className={css.Carousel__buttons}>
          <CarouselButton
            direction="Previous"
            isEnabled={previousAvailable}
            onClick={scrollToPrevious}
          />
          <CarouselButton
            direction="Next"
            isEnabled={nextAvailable}
            onClick={scrollToNext}
          />
        </div>
      </div>
      <div
        className={classNames(css.Carousel__listContainer, className, {
          [css['Carousel__listContainer--next-available']]: nextAvailable,
          [css['Carousel__listContainer--previous-available']]:
            previousAvailable,
        })}
      >
        <ul
          className={classNames(css.Carousel__list, {
            [css['Carousel__list--horizontal-mobile-scroll']]:
              horizontalMobileScroll,
          })}
          ref={carouselRef}
          onClick={scrollToClicked}
          onScroll={onScroll}
        >
          {children}
        </ul>
      </div>
    </div>
  )
}

export default Carousel

interface CarouselButtonProps {
  direction: 'Next' | 'Previous'
  isEnabled: boolean
  onClick: () => void
}

const CarouselButton: React.FC<CarouselButtonProps> = ({
  direction,
  isEnabled,
  onClick,
}: CarouselButtonProps) => (
  <button
    className={classNames(
      css.Carousel__button,
      css[`Carousel__button--${direction.toLowerCase()}`],
      {
        [css[`Carousel__button--enabled`]]: isEnabled,
      }
    )}
    disabled={!isEnabled}
    type="button"
    onClick={onClick}
  >
    <span className="sr-only">{direction}</span>
  </button>
)
