import NiceModal, { useModal } from '@ebay/nice-modal-react'
// eslint-disable-next-line import/no-extraneous-dependencies
import { NavLink, RightDrawer } from 'baby-design'
import ProductInfoDrawerFields from 'src/routes/(registry)/list/[slug]/components/ProductInfoDrawer/components/ProductInfoDrawerFields/ProductInfoDrawerFields'
import useRegItemChanges, {
  RegItemChanges,
} from 'src/routes/(registry)/list/[slug]/hooks/useRegItemChanges'
import ConfirmUnsavedChangesModal from '../ConfirmUnsavedChangesModal/ConfirmUnsavedChangesModal'
import { ProductInfoDrawerProps } from './ProductInfoDrawer.types'

export const ProductInfoDrawer = NiceModal.create(
  ({ regItem }: ProductInfoDrawerProps) => {
    const modal = useModal()

    const { regItemChanges, updateRegItemFields, hasUnsavedChanges } =
      useRegItemChanges({ regItem })

    const regItemFields = {
      ...regItem,
      ...regItemChanges,
    }

    const closeModal = (changes: RegItemChanges | undefined) => {
      modal.resolve(changes)
      modal.hide()
    }

    const handleClose = async () => {
      if (hasUnsavedChanges()) {
        const confirmUnsavedChanges: boolean | undefined = await NiceModal.show(
          ConfirmUnsavedChangesModal
        )

        if (!confirmUnsavedChanges) {
          return
        }
      }

      closeModal(undefined)
    }

    const onSave = () => closeModal({ ...regItem, ...regItemFields })

    return (
      <RightDrawer
        contextualIcon={
          <NavLink
            disabled={!hasUnsavedChanges()}
            size="md"
            variant="eggplant"
            onClick={onSave}
          >
            Save
          </NavLink>
        }
        handleClose={handleClose}
        handleDismount={modal.remove}
        isOpen={modal.visible}
        mainIcon="back"
        title="Product Info"
      >
        <ProductInfoDrawerFields
          allowImageEdit
          isPriceEditable={regItem.isPriceEditable}
          isTitleEditable={regItem.isTitleEditable}
          regItemFields={regItemFields}
          showPrice={false}
          onUpdateRegItemFields={updateRegItemFields}
        />
      </RightDrawer>
    )
  }
)
