import { RegItemFieldsProps } from 'src/routes/(registry)/list/[slug]/components/EditRegItemDrawer/fields/RegItemFields.types'
import useMarkPurchasedEnabled from 'src/routes/(registry)/list/[slug]/components/EditRegItemDrawer/hooks/useMarkPurchasedEnabled'
import usePrivateItemsEnabled from 'src/routes/(registry)/list/[slug]/components/EditRegItemDrawer/hooks/usePrivateItemsEnabled'
import { EditItemDetailsQuantitySetting } from '../components/QuantitySetting'
import { EditItemDetailsFavoriteSetting } from '../components/Favorite'
import { EditItemDetailsGroupGiftSetting } from '../components/GroupGift'
import { EditItemDetailsMarkPurchasedSetting } from '../components/MarkPurchased'

import Image from 'components/image'
import { Textarea, IconButton, Badge } from 'baby-design'
import { EditPencil } from 'baby-design/icons'

import css from './fields.style.scss'
import { RegItem } from 'src/types/regItem'
import { numberToCurrency } from 'lib/money'
import { CategoryDropdown } from '../components/CategoryDropdown'
import { EditItemDetailsBuyingOptions } from '../../BuyingOptionsWithAddButton'
import MarkAsPurchasedDrawer from '../../MarkAsPurchasedDrawer/MarkAsPurchasedDrawer'
import NiceModal from '@ebay/nice-modal-react'
import ProductInfoDrawer from '../../ProductInfoDrawer/ProductInfoDrawer'
import EnableGroupGiftModal from '../../EnableGroupGiftModal/EnableGroupGiftModal'
import EditRegItemDrawer from '../EditRegItemDrawer'
import AddSecondaryLinkDrawer from '../../AddSecondaryLinkDrawer/AddSecondaryLinkDrawer'
import EditSecondaryLinkDrawer from '../../EditSecondaryLinkDrawer/EditSecondaryLinkDrawer'
import { Offer } from 'src/types/offer'
import RegItemPurchasesSection from './RegItemFields/components/RegItemPurchasesSection'
import EditItemDetailsPrivateItemSetting from 'src/routes/(registry)/list/[slug]/components/EditRegItemDrawer/components/PrivateItem/PrivateItem'

export function RegItemFields({
  regItem,
  categories,
  regItemFields,
  reservations,
  updateRegItemFields,
  clearRegItemFields,
  handleDelete,
  onAddToCartSuccess,
  onRegItemUpdated,
}: RegItemFieldsProps) {
  const isMarkPurchasedEnabled = useMarkPurchasedEnabled()
  const isPrivateItemsEnabled = usePrivateItemsEnabled()

  const openProductInfoDrawer = async () => {
    const response: RegItem | undefined = await NiceModal.show(
      ProductInfoDrawer,
      { regItem: regItem }
    )
    if (response) {
      NiceModal.show(EditRegItemDrawer, { regItem: response })
    }
  }

  const openMarkPurchasedDrawer = async () => {
    const response: RegItem | undefined = await NiceModal.show(
      MarkAsPurchasedDrawer,
      { regItem: regItem }
    )
    if (response) {
      NiceModal.show(EditRegItemDrawer, { regItem: response })
    }
  }

  const handleGroupGiftChange = async () => {
    const response: RegItem | undefined = await NiceModal.show(
      EnableGroupGiftModal,
      { regItem: regItem }
    )
    if (response) {
      clearRegItemFields('quantity')
      NiceModal.show(EditRegItemDrawer, { regItem: response })
    }
  }

  const openAddSecondaryLinkDrawer = async () => {
    const response: RegItem | undefined = await NiceModal.show(
      AddSecondaryLinkDrawer,
      { regItem: regItem }
    )
    if (response) {
      NiceModal.show(EditRegItemDrawer, { regItem: response })
    }
  }

  const openEditSecondaryLinkDrawer = async (offer: Offer) => {
    const response: RegItem | undefined = await NiceModal.show(
      EditSecondaryLinkDrawer,
      {
        secondaryOffer: offer,
        regItem: regItem,
      }
    )
    if (response) {
      NiceModal.show(EditRegItemDrawer, { regItem: response })
    }
  }

  const PurchasedBadge = () => (
    <Badge
      label="Purchased"
      variant="general"
      fill="standard-grey"
      size="small"
    />
  )

  const badge = regItem.hasAtLeastOnePurchase ? <PurchasedBadge /> : undefined

  return (
    <div className={css.fields}>
      <div className={css.fields__item_intro}>
        <div className={css.fields__item_intro_img_container}>
          <Image
            src={regItemFields.imgUrl}
            alt={regItemFields.title}
            className={css.fields__item_intro_img}
          />
          <div className={css.reg_item_card__img_badge}>{badge}</div>
        </div>
        <div className={css.fields__item_intro_info}>
          <div className={css.fields__item_title}>{regItemFields.title}</div>
          {regItemFields.price !== null && (
            <div className={css.fields__item_price}>
              {numberToCurrency(regItemFields.price)}
            </div>
          )}
        </div>
        <IconButton
          variant="fill-inverted"
          size="sm"
          className={css.fields__image_edit}
          onClick={openProductInfoDrawer}
        >
          <EditPencil />
        </IconButton>
      </div>
      <div className={css.fields__item_details}>
        <div className={css.fields__item_input}>
          <CategoryDropdown
            regItemFields={regItemFields}
            categories={categories}
            updateRegItemFields={updateRegItemFields}
          />
          <Textarea
            label="Notes to friends and family "
            size="md"
            value={regItemFields.description || undefined}
            onChange={(e) =>
              updateRegItemFields({ description: e.target.value })
            }
            placeholder="Let your gift givers know what size or color you would like"
          />
        </div>
        <EditItemDetailsQuantitySetting
          regItemFields={regItemFields}
          updateRegItemFields={updateRegItemFields}
          handleDelete={handleDelete}
        />
        {isMarkPurchasedEnabled && (
          <RegItemPurchasesSection
            regItem={regItemFields}
            reservations={reservations}
            onRegItemUpdated={onRegItemUpdated}
          />
        )}
        <EditItemDetailsFavoriteSetting
          regItemFields={regItemFields}
          updateRegItemFields={updateRegItemFields}
        />
        {isPrivateItemsEnabled && !regItemFields.isGroupGift && (
          <EditItemDetailsPrivateItemSetting
            categories={categories}
            regItemFields={regItemFields}
            updateRegItemFields={updateRegItemFields}
          />
        )}
        {regItemFields.showGroupGiftToggle && (
          <EditItemDetailsGroupGiftSetting
            regItemFields={regItemFields}
            disabled={!regItem.canEnableGroupGift}
            handleGroupGiftChange={handleGroupGiftChange}
          />
        )}
        {!isMarkPurchasedEnabled && regItemFields.quantityNeeded > 0 && (
          <EditItemDetailsMarkPurchasedSetting
            isReserved={regItemFields.isReserved}
            openMarkPurchasedDrawer={openMarkPurchasedDrawer}
          />
        )}
        <EditItemDetailsBuyingOptions
          openAddSecondaryLinkDrawer={openAddSecondaryLinkDrawer}
          openEditSecondaryLinkDrawer={openEditSecondaryLinkDrawer}
          regItem={regItemFields}
          onAddToCartSuccess={onAddToCartSuccess}
        />
      </div>
      <div className={css.fields__divider} />
      <div className={css.fields__item_delete}>
        <a className={css.fields__delete_link} onClick={handleDelete}>
          Delete item
        </a>
      </div>
    </div>
  )
}
