import { FC } from 'react'
import * as Sentry from '@sentry/react'
import { MutationFunction, useMutation } from '@tanstack/react-query'
import { Close } from 'baby-design/icons'
import { dismissFeedItem } from 'registry/api'
import css from './InsuranceEligibilityValidatorTool.styles.scss'

interface DismissButtonProps {
  id: string
  onDismiss: () => void
}

interface DismissFeedItemAPIRequestBody {
  contentItemId: string
}

const DismissButton: FC<DismissButtonProps> = ({ id, onDismiss }) => {
  const { mutate } = useMutation({
    mutationFn: dismissFeedItem as MutationFunction<
      unknown,
      DismissFeedItemAPIRequestBody
    >,
    onError: (e) => {
      Sentry.captureException(e)
    },
    onSettled: onDismiss,
  })

  return (
    <button
      aria-label="Dismiss"
      className={css.InsuranceEligibilityValidatorTool__dismisser}
      title="Dismiss"
      type="button"
      onClick={() => mutate({ contentItemId: id })}
    >
      <Close />
    </button>
  )
}

export default DismissButton
