/* eslint-disable react/prop-types */
import { forwardRef } from 'react'
import { UI } from '../../../global'
import TransitionBuilder, { TransitionBuilderProps } from '../TransitionBuilder'
import makeComposableTransitionComponent from '../makeComposableTransitionComponent'

export type FadeOutProps = Omit<TransitionBuilderProps, 'Transitions'>

function makeFadeOutProps({
  animateOnEnter,
  animateOnExit,
  duration,
  delay,
}: UI.TransitionPropMakerParams) {
  return {
    animate: { opacity: 1 },
    exit: animateOnExit ? { opacity: 0 } : undefined,
    initial: animateOnEnter ? { opacity: 0 } : false,
    transition: { duration, delay },
  }
}

const FadeOut = forwardRef<HTMLDivElement, FadeOutProps>(function FadeOut(
  { show, ...rest },
  ref
) {
  return (
    <TransitionBuilder
      Transitions={[{ makeProps: makeFadeOutProps }]}
      ref={ref}
      show={show}
      {...rest}
    />
  )
})

export default makeComposableTransitionComponent(FadeOut, makeFadeOutProps)
