import NiceModal, { useModal } from '@ebay/nice-modal-react'
import useMarkPurchaseFormField from 'src/routes/(registry)/list/[slug]/components/EditRegItemDrawer/hooks/useMarkPurchaseFormField'
import useMarkPurchaseModal from 'src/routes/(registry)/list/[slug]/components/EditRegItemDrawer/hooks/useMarkPurchaseModal'
import { RecordContributionModalProps } from 'src/routes/(registry)/list/[slug]/components/EditRegItemDrawer/components/RecordContributionModal/RecordContributionModal.types'
import TextInput from 'baby-design/components/input/TextInput/TextInput'
import MarkPurchasedModal from 'src/routes/(registry)/list/[slug]/components/EditRegItemDrawer/components/MarkPurchasedModal/MarkPurchasedModal'
import { MarkAsPurchasedProps } from 'src/routes/(registry)/list/[slug]/hooks/useMarkAsPurchased'
import PaymentMethodDropdown from './components/PaymentMethodDropdown/PaymentMethodDropdown'
import css from './RecordContributionModal.styles.scss'

const MODAL_TITLE = 'Record a contribution'
const MODAL_SUBTITLE =
  "Add contributions you've received so you can see them in your gift tracker."

const NAME_FIELD_ERROR = 'Enter a name'
const MISSING_AMOUNT_FIELD_ERROR = 'Enter the amount you were gifted'
const INVALID_AMOUNT_FIELD_ERROR = 'Enter a valid dollar amount (e.g. 12.34)'

const RecordContributionModal = NiceModal.create(
  ({ regItem }: RecordContributionModalProps) => {
    const modal = useModal()
    const nameField = useMarkPurchaseFormField<string>({
      name: 'name',
      errorMessage: (val: string | undefined) =>
        !val ? NAME_FIELD_ERROR : undefined,
    })
    const {
      value: contributorName,
      updateValue: setContributorName,
      error: contributorNameError,
    } = nameField

    const amountField = useMarkPurchaseFormField<string>({
      name: 'giftAmount',
      errorMessage: (val: string | undefined) => {
        if (!val) return MISSING_AMOUNT_FIELD_ERROR
        const num = Number(val)
        if (Number.isNaN(num) || num <= 0) {
          return INVALID_AMOUNT_FIELD_ERROR
        }
        return undefined
      },
    })
    const {
      value: amount,
      updateValue: setAmount,
      error: amountError,
    } = amountField

    const paymentMethodField = useMarkPurchaseFormField<string>({
      name: 'paymentMethod',
    })
    const { value: paymentMethod, updateValue: setPaymentMethod } =
      paymentMethodField

    const {
      closeModal,
      handleMarkAsPurchased,
      handleSubmit,
      isCreatingReservation,
    } = useMarkPurchaseModal({
      fields: () => [nameField, amountField, paymentMethodField],
      isInitiallySelfPurchase: false,
      modal,
      regItem,
    })

    const onSubmit = () => {
      handleMarkAsPurchased((formValues) => {
        const {
          paymentMethod: method,
          giftAmount,
          ...otherFormValues
        } = formValues

        return {
          ...otherFormValues,
          giftAmount: Number(giftAmount),
          cashFundContributionAttributes: {
            paymentMethod: method || null,
          },
        } as MarkAsPurchasedProps
      })
    }

    return (
      <MarkPurchasedModal
        isLoading={isCreatingReservation}
        isOpen={modal.visible}
        subtitle={MODAL_SUBTITLE}
        title={MODAL_TITLE}
        onCloseModal={closeModal}
        onSubmit={() => handleSubmit(onSubmit)}
      >
        <div className={css.RecordContributionModal__FormFields}>
          <TextInput
            inputProps={{
              value: contributorName,
              onChange: (e) => setContributorName(e.target.value),
            }}
            label="Contributor's name"
            size="md"
            status={
              contributorNameError
                ? { type: 'error', message: contributorNameError }
                : undefined
            }
          />
          <TextInput
            inputProps={{
              value: amount,
              onChange: (e) => setAmount(e.target.value),
            }}
            label="Amount"
            prefix="$"
            size="md"
            status={
              amountError ? { type: 'error', message: amountError } : undefined
            }
          />
          <PaymentMethodDropdown
            paymentMethod={paymentMethod}
            onPaymentMethodChanged={setPaymentMethod}
          />
        </div>
      </MarkPurchasedModal>
    )
  }
)

export default RecordContributionModal
