import { useMutation, useQueryClient } from '@tanstack/react-query'
import { saveRegItem } from 'src/api/queries'
import { RegItem } from 'src/types/regItem'
import { errorMessage, successMessage } from 'lib/flash-message'
import {
  invalidateRegItemsQuery,
  optimisticallyUpdateRegItem,
} from 'src/routes/(registry)/list/[slug]/utils/registryHelpers'
import ConfirmUnsavedChangesModal from '../components/ConfirmUnsavedChangesModal/ConfirmUnsavedChangesModal'
import NiceModal from '@ebay/nice-modal-react'
import useRegItemChanges, {
  RegItemChanges,
} from 'src/routes/(registry)/list/[slug]/hooks/useRegItemChanges'
import useCategorizeAnyRegAdd from 'src/hooks/useCategorizeAnyRegAdd'
import { showChangesSavedToast } from 'src/routes/(registry)/list/[slug]/components/EditRegItemDrawer/components/ChangesSavedToast/showChangesSavedToast'

type UseSaveRegItemProps = {
  regItem: RegItem
  modal: any
}

const useSaveRegItem = ({ regItem, modal }: UseSaveRegItemProps) => {
  const queryClient = useQueryClient()
  const {
    regItemChanges,
    setRegItemChanges,
    hasUnsavedChanges,
    updateRegItemFields,
    clearRegItemFields,
  } = useRegItemChanges({ regItem })
  const isCategorizeAnyAddEnabled = useCategorizeAnyRegAdd()

  const saveRegItemMutation = useMutation({
    mutationFn: (regItemDiff: RegItemChanges) =>
      saveRegItem(regItem.registry.id, regItem.id, regItemDiff),
    onSuccess: ({ regItem: newRegItem }: { regItem: RegItem }) => {
      setRegItemChanges({})
      invalidateRegItemsQuery({
        queryClient,
        registryId: newRegItem.registry.id,
      })

      if (isCategorizeAnyAddEnabled) {
        showChangesSavedToast()
      } else {
        successMessage(`${newRegItem.title} was successfully saved!`)
      }

      modal.resolve(newRegItem)
      modal.hide()
    },
    onError: (error: any) => {
      errorMessage(
        error?.error?.message ||
          'Failed to save. Try refreshing the page. If the problem persists, contact support.'
      )
    },
  })

  const onSave = () => {
    const newRegItem = { ...regItem, ...regItemChanges }

    optimisticallyUpdateRegItem({
      queryClient,
      updatedRegItem: newRegItem,
    })

    saveRegItemMutation.mutate(newRegItem)
  }

  const handleClose = async () => {
    if (saveRegItemMutation.isLoading) {
      return
    }

    if (hasUnsavedChanges()) {
      const confirmUnsavedChanges: boolean | undefined = await NiceModal.show(
        ConfirmUnsavedChangesModal
      )

      if (!confirmUnsavedChanges) {
        return
      }
    }

    modal.resolve(undefined)
    modal.hide()
  }

  return {
    regItemChanges,
    setRegItemChanges,
    hasUnsavedChanges,
    updateRegItemFields,
    clearRegItemFields,
    saveRegItemMutation,
    handleClose,
    onSave,
  }
}

export default useSaveRegItem
