import { useQuery } from '@tanstack/react-query'
import { getInsuranceCatalog } from 'bl-health/requests'
import { useState } from 'react'
import { track, useTracking } from 'lib/analytics'
import { InsuranceEligibilityValidatorToolVariant } from '../InsuranceEligibilityValidatorTool'

interface Option {
  value: number
  label: string
}

type StateOption = Option & APIResponse.InsuranceCatalog

type InsuranceOption = Option & APIResponse.Insurance

interface ValidationErrors {
  state?: string
  insurance?: string
}

export interface EligibilityMetadata {
  insurance: InsuranceOption | null
  state: StateOption | null
}

export interface InsuranceEligibilityValidatorHookParams {
  onEligibilityCheck: (metadata: EligibilityMetadata) => void
  variant: InsuranceEligibilityValidatorToolVariant
}

export default function useInsuranceEligibilityValidator({
  onEligibilityCheck,
  variant,
}: InsuranceEligibilityValidatorHookParams) {
  const { data } = useQuery({
    queryKey: ['dme-insurance-catalog'],
    queryFn: getInsuranceCatalog,
  })
  const [errors, setErrors] = useState<ValidationErrors>({})
  const [selectedState, setSelectedState] = useState<StateOption | null>(null)
  const [selectedInsurance, setSelectedInsurance] =
    useState<InsuranceOption | null>(null)
  const tracker = useTracking()

  const eventLocation =
    variant === 'guide' ? track.EventLocation.GUIDE : track.EventLocation.FEED

  const stateOptions =
    data?.map((state) => ({
      ...state,
      value: state.id,
      label: state.stateName,
    })) || []

  const insuranceOptions =
    selectedState?.insurances?.map((insurance) => ({
      ...insurance,
      value: insurance.id,
      label: insurance.company,
    })) || []

  function onSubmit() {
    const errors: ValidationErrors = {}

    if (!selectedState) {
      errors.state = 'Please select your state'
    } else if (!selectedInsurance) {
      errors.insurance = 'Please select your insurance carrier'
    }

    onEligibilityCheck({
      state: selectedState,
      insurance: selectedInsurance,
    })

    setErrors(errors)

    tracker.trackEvent({
      event: track.eligibilityButtonClicked,
      eventLocation,
    })
  }

  function onStateChange(option: StateOption | null) {
    setSelectedState(option)
    setSelectedInsurance(null)
    setErrors({})

    if (option) {
      tracker.trackEvent({
        event: track.selectedState,
        state: option.stateName,
        eventLocation,
      })
    }
  }

  function onInsuranceChange(option: InsuranceOption | null) {
    setSelectedInsurance(option)
    setErrors({})

    if (option) {
      tracker.trackEvent({
        event: track.selectedInsurancePlan,
        plan: option.company,
        eventLocation,
      })
    }
  }

  return {
    errors,
    insuranceOptions,
    onInsuranceChange,
    onStateChange,
    onSubmit,
    selectedInsurance,
    selectedState,
    stateOptions,
  }
}
