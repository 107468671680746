import React, { cloneElement, useState } from 'react'
import classNames from 'classnames'
import { CSSTransition } from 'react-transition-group'
import { ToastTransitionProps } from './ToastTransition.types'
import css from './ToastTransition.styles.scss'

const CLASSES = {
  enter: css['ToastTransition--enter'],
  enterDone: css['ToastTransition--enter-done'],
  exit: css['ToastTransition--exit'],
  exitDone: css['ToastTransition--exit-done'],
}

const ToastTransition: React.FC<ToastTransitionProps> = ({
  children,
  in: isOpen,
  timeout = 300,
}) => {
  /*
    Handling the transition class with callbacks allows persistence of the value for
    stateClass between renders. This is necessary to avoid the class being reset when
    viewing dimensions change.
  */
  const [stateClass, setStateClass] = useState(CLASSES.exitDone)
  const childrenWithProps = cloneElement(children, {
    className: classNames(
      children.props.className,
      css.ToastTransition__child,
      stateClass
    ),
  })

  return (
    <CSSTransition
      mountOnEnter
      unmountOnExit
      appear={isOpen}
      in={isOpen}
      timeout={timeout}
      onEnter={() => setStateClass(CLASSES.enter)}
      onEntered={() => setStateClass(CLASSES.enterDone)}
      onExit={() => setStateClass(CLASSES.exit)}
      onExited={() => setStateClass(CLASSES.exitDone)}
    >
      {childrenWithProps}
    </CSSTransition>
  )
}

export default ToastTransition
