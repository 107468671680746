/* eslint-disable react/jsx-props-no-spreading */
import PropTypes from 'prop-types'
import classNames from 'classnames'
import Clickable from 'components/clickable'
import {
  FavoriteOnIcon,
  FavoriteOffIcon,
  GroupGiftOnIcon,
  GroupGiftOffIcon,
} from 'components/icons'
import { FAVORITE_COPY } from 'registry/constants'
import css from './badge.scss'

const BadgeProps = {
  isOn: PropTypes.bool.isRequired,
}

const ToggleProps = {
  disabled: PropTypes.bool,
  isOn: PropTypes.bool.isRequired,
  onClick: PropTypes.func.isRequired,
  onDisabledClick: PropTypes.func,
}

const DefaultToggleProps = {
  disabled: false,
  onDisabledClick: undefined,
}

const BadgeLayout = ({
  ariaLabel,
  className,
  children,
  disabled,
  onClick,
  onDisabledClick,
  overlay,
  position,
  shadow,
  size,
}) => {
  const overlayPosition = css[position]
  const badgeSize = css[size]
  const classes = classNames(
    className,
    badgeSize,
    shadow ? css.shadow : null,
    overlay ? css.overlaid : null,
    overlayPosition
  )

  return onClick || onDisabledClick ? (
    <Clickable
      aria-label={ariaLabel}
      className={classes}
      onClick={disabled ? onDisabledClick : onClick}
    >
      {children}
    </Clickable>
  ) : (
    <div className={classes}>{children}</div>
  )
}

BadgeLayout.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
  onClick: PropTypes.func,
  overlay: PropTypes.bool, // set to overlay within parent container
  position: PropTypes.oneOf([
    'tl', // top left
    'tr', // top right
    'bl', // bottom left
    'br', // bottom right
  ]),
  shadow: PropTypes.bool,
  size: PropTypes.oneOf(['sm', 'md', 'lg']),
}

BadgeLayout.defaultProps = {
  className: null,
  onClick: null,
  overlay: false,
  position: 'tl',
  shadow: false,
  size: 'md',
}

const BadgeLabel = ({ text }) => <span className={css.label}>{text}</span>

BadgeLabel.propTypes = {
  text: PropTypes.string.isRequired,
}

const FavoriteControl = ({ isOn }) => (
  <>
    {isOn ? (
      <FavoriteOnIcon
        alt={`Click to unmark this item as a '${FAVORITE_COPY}'.`}
      />
    ) : (
      <FavoriteOffIcon
        alt={`Click to mark this item as a '${FAVORITE_COPY}'.`}
      />
    )}
  </>
)

FavoriteControl.propTypes = {
  isOn: PropTypes.bool.isRequired,
}

const FavoriteBadge = ({ isOn, ...badgeProps }) => {
  return (
    <BadgeLayout {...badgeProps}>
      <div
        className={classNames(
          css.badge,
          css.favoriteBadge,
          isOn ? css.favoriteEnabled : null
        )}
      >
        <BadgeLabel text={`${FAVORITE_COPY}!`} />
      </div>
    </BadgeLayout>
  )
}

FavoriteBadge.propTypes = BadgeProps

const FavoriteToggle = ({ disabled, isOn, ...badgeProps }) => {
  return (
    <BadgeLayout disabled={disabled} {...badgeProps}>
      <div
        className={classNames(
          css.badge,
          css.favoriteBadge,
          disabled ? css.disabled : null,
          isOn ? css.favoriteEnabled : null
        )}
      >
        {disabled ? (
          <FavoriteControl isOn={false} />
        ) : (
          <FavoriteControl isOn={isOn} />
        )}
      </div>
    </BadgeLayout>
  )
}

FavoriteToggle.propTypes = ToggleProps
FavoriteToggle.defaultProps = DefaultToggleProps

const GroupGiftControl = ({ isOn }) => (
  <>
    {isOn ? (
      <GroupGiftOnIcon alt="Click to disable group gifting for this item." />
    ) : (
      <GroupGiftOffIcon alt="Click to enable group gifting for this item." />
    )}
  </>
)

GroupGiftControl.propTypes = {
  isOn: PropTypes.bool.isRequired,
}

const GroupGiftBadge = ({ isOn, ...badgeProps }) => {
  return (
    <BadgeLayout {...badgeProps}>
      <div
        className={classNames(
          css.badge,
          css.groupGiftBadge,
          isOn ? css.groupGiftEnabled : null
        )}
      >
        <BadgeLabel text="Group Gift" />
      </div>
    </BadgeLayout>
  )
}

GroupGiftBadge.propTypes = BadgeProps

const GroupGiftToggle = ({ disabled, isOn, ...badgeProps }) => {
  return (
    <BadgeLayout disabled={disabled} {...badgeProps}>
      <div
        className={classNames(
          css.badge,
          css.groupGiftBadge,
          disabled ? css.disabled : null,
          isOn ? css.groupGiftEnabled : null
        )}
      >
        {disabled ? (
          <GroupGiftControl isOn={false} />
        ) : (
          <GroupGiftControl isOn={isOn} />
        )}
      </div>
    </BadgeLayout>
  )
}

GroupGiftToggle.propTypes = ToggleProps
GroupGiftToggle.defaultProps = DefaultToggleProps

export { FavoriteBadge, FavoriteToggle, GroupGiftBadge, GroupGiftToggle }
