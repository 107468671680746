import { useCallback } from 'react'
import Image from 'components/image'
import { InfoButton } from 'components/buttons'
import { absoluteUrl, reservationsPath } from 'lib/urls'
// @ts-ignore
import { track, useTracking } from 'lib/analytics'
import { FREE_SHIPPING_THRESHOLD } from 'shared/constants'
import { PriceTag } from 'baby-design'
import { Offer } from 'src/types/offer'
import { getPriceTagPropsFromPriceDetails } from 'src/utils/productHelpers'
import ExternalProductOffer from './ExternalProductOffer'
import styles from './BabylistOffer.scss'

interface BabylistOfferWithDeliveryEstimatesProps {
  offer: Offer
  regItem: any
  registry: any
  onClick: () => void
  inCart: boolean
  trackedCreateReservation: () => void
  submitting?: boolean
}

interface OfferProps {
  id: number
}

const getFreeShippingElement = (regItem: any) => {
  if (!regItem.price) return null

  if (regItem.price >= 45) {
    return (
      <div className={styles.BabylistOffer__freeShipOver45}>
        <span>Free shipping* 🚚</span>
        <span>Free returns 💗</span>
      </div>
    )
  }

  return (
    <div className={styles.BabylistOffer__freeShip}>
      <span>Free shipping on orders over ${FREE_SHIPPING_THRESHOLD}* 🚚</span>
      <span>Free returns 💗</span>
    </div>
  )
}

// eslint-disable-next-line complexity
export const BabylistOfferWithDeliveryEstimates = ({
  offer,
  regItem,
  registry,
  onClick,
  inCart,
  trackedCreateReservation,
  submitting = false,
}: BabylistOfferWithDeliveryEstimatesProps) => {
  const tracker = useTracking()

  const { isAvailableOnBabylist, offers } = regItem

  const priceTagProps = getPriceTagPropsFromPriceDetails(offer.priceDetails)

  const showDeliveryEstimates =
    isAvailableOnBabylist &&
    !!regItem.deliveryEstimate &&
    !regItem.isElectronicGiftCard

  const formattedDeliveryEstimate = () => {
    if (!regItem.deliveryEstimate?.message) return null

    const deliveryEstimateSplit = regItem?.deliveryEstimate?.message?.split('-')
    const min = deliveryEstimateSplit[0]
    const max = deliveryEstimateSplit[1]
    return (
      <>
        <span>{min} - </span>
        <span className={styles.BabylistOffer__nowrap}>{max}</span>
      </>
    )
  }

  let estimateMessage
  if (regItem?.deliveryEstimate?.arrivesBeforeShower) {
    estimateMessage = 'This gift will be delivered before the baby shower.'
  } else if (regItem?.deliveryEstimate?.arrivesBeforeArrivalDate) {
    estimateMessage =
      "This gift will be delivered before baby's expected arrival date."
  }

  const handleAddToCart = useCallback(() => {
    if (typeof onClick !== undefined) {
      onClick()
    }

    tracker.trackEvent({
      event: track.registryProductOfferClicked,
      minEstimate: regItem.deliveryEstimate?.min,
      maxEstimate: regItem.deliveryEstimate?.max,
      offerIds: regItem.offers.map(({ id }: OfferProps) => id),
      regItemId: regItem.id,
      showerDate: registry.showerInfo?.showers[0]?.date,
      willArriveBeforeArrivalDate:
        registry.deliveryEstimate?.arrivesBeforeArrivalDate,
      willArriveBeforeShower: regItem.deliveryEstimate?.arrivesBeforeShower,
      store: 'Babylist',
    })
  }, [onClick])

  return (
    <div className={styles.BabylistOffer}>
      <div className={styles.BabylistOffer__top}>
        <div className={styles.BabylistOffer__shopHeader}>
          <div className={styles.BabylistOffer__logo}>
            <Image
              src="//images.babylist.com/image/upload/v1631884941/bl_store_icon_o8u8vu.png"
              width={30}
            />
            <div className={styles.BabylistOffer__title}>Babylist Shop</div>
          </div>
          <div className={styles.BabylistOffer__price}>
            <PriceTag {...priceTagProps} variant="compact" />
          </div>
        </div>

        {showDeliveryEstimates && (
          <div className={styles.BabylistOffer__estimate}>
            <span className={styles.BabylistOffer__estimateTitle}>
              Estimated Delivery{' '}
              <Image
                src="//images.babylist.com/image/upload/v1680540311/Icon_Truck_uvjchg.svg"
                width={22}
              />
            </span>
            <span className={styles.BabylistOffer__estimateDate}>
              {formattedDeliveryEstimate()}
            </span>
            {estimateMessage && (
              <span className={styles.BabylistOffer__estimateEvent}>
                {estimateMessage}
              </span>
            )}
          </div>
        )}

        <div>
          {!isAvailableOnBabylist && (
            <small className={styles.BabylistOffer__outOfStock}>
              Item is currently out of stock
            </small>
          )}

          {inCart ? (
            <InfoButton
              block
              empty
              pill
              className="mtm pam"
              href={absoluteUrl(reservationsPath)}
            >
              In Cart
            </InfoButton>
          ) : (
            <>
              <InfoButton
                block
                pill
                className="mtm pam"
                disabled={!isAvailableOnBabylist}
                submitting={submitting}
                onClick={handleAddToCart}
              >
                Add to Cart
              </InfoButton>

              {getFreeShippingElement(regItem)}
            </>
          )}
        </div>
      </div>
      {offers ? (
        <div className={styles.BabylistOffer__offers}>
          <ExternalProductOffer
            offers={offers}
            regItem={regItem}
            registry={registry}
            trackedCreateReservation={trackedCreateReservation}
          />
        </div>
      ) : null}
    </div>
  )
}
