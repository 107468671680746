import StickyHeader from 'components/sticky-header'
import PostLink from '../../../PostLink'

interface PostNavBarProps {
  nextPost?: Post
  minimalView: boolean
  previousPost?: Post
  title: string
  children: React.ReactNode
}

const PostNavBar = ({
  children,
  nextPost,
  minimalView,
  previousPost,
  title,
}: PostNavBarProps) => {
  const previousPostButton = () =>
    previousPost && (
      <PostLink className="hidden-xs" direction="left" post={previousPost} />
    )

  const nextPostButton = () =>
    nextPost && (
      <div className="hidden-xs full-width text-right">
        <PostLink direction="right" post={nextPost} />
      </div>
    )

  return (
    <StickyHeader
      hideTitle={minimalView}
      leftActionComponent={previousPostButton()}
      rightActionComponent={nextPostButton()}
      title={title}
    >
      {children}
    </StickyHeader>
  )
}

export default PostNavBar
