import React from 'react'
import classNames from 'classnames'

import css from './QuantityPicker.styles.scss'

import { QuantityPickerProps } from './QuantityPicker.types'
import { GarbageBin, Plus, Minus } from '../../icons'

const QuantityPicker: React.FC<QuantityPickerProps> = ({
  value,
  min = 1,
  max,
  onPlusClick = () => null,
  onMinusClick = () => null,
  onTrashClick = () => null,
  variant,
  type,
  size,
  className,
}) => {
  const QuantityPickerClasses = classNames(
    css.quantity_picker,
    css[`quantity_picker--variant-${variant}`],
    css[`quantity_picker--size-${size}`],
    className
  )

  const displayValue = value === null ? '∞' : value

  const isMinValue = value === null || value <= min

  const isMaxValue = value === null ? false : !!max && value >= max

  const shouldDisplayTrash = type === 'cart' && isMinValue

  return (
    <div className={QuantityPickerClasses}>
      {shouldDisplayTrash ? (
        <button
          aria-label="Trash"
          className={css.quantity_picker__button}
          type="button"
          onClick={() => onTrashClick(value)}
        >
          <GarbageBin className={css.quantity_picker__trash} />
        </button>
      ) : (
        <button
          aria-label="Minus"
          className={css.quantity_picker__button}
          disabled={isMinValue}
          type="button"
          onClick={() => onMinusClick(value as number)}
        >
          <Minus className={css.quantity_picker__minus} />
        </button>
      )}
      <div aria-label="Quantity" className={css.quantity_picker__quantity}>
        {displayValue}
      </div>
      <button
        aria-label="Plus"
        className={css.quantity_picker__button}
        disabled={isMaxValue}
        type="button"
        onClick={() => onPlusClick(value as number)}
      >
        <Plus className={css.quantity_picker__plus} />
      </button>
    </div>
  )
}

export default QuantityPicker
