export const MOBILE_VARIANTS = {
  basic: {
    value: 'basic' as const,
    class: 'mobile-basic',
  },
  rich: {
    value: 'rich' as const,
    class: 'mobile-rich',
  },
}
