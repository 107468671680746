import React from 'react'

interface ArrowTopProps {
  className?: string
}

const ArrowTop: React.FC<ArrowTopProps> = ({ className }) => (
  <svg
    className={className}
    fill="none"
    height="24"
    viewBox="0 0 24 24"
    width="24"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      clipRule="evenodd"
      d="M11.4697 4.46967C11.7626 4.17678 12.2374 4.17678 12.5303 4.46967L18.5303 10.4697C18.8232 10.7626 18.8232 11.2374 18.5303 11.5303C18.2374 11.8232 17.7626 11.8232 17.4697 11.5303L12.75 6.81066L12.75 19C12.75 19.4142 12.4142 19.75 12 19.75C11.5858 19.75 11.25 19.4142 11.25 19L11.25 6.81066L6.53033 11.5303C6.23744 11.8232 5.76256 11.8232 5.46967 11.5303C5.17678 11.2374 5.17678 10.7626 5.46967 10.4697L11.4697 4.46967Z"
      fill="#252324"
      fillRule="evenodd"
    />
  </svg>
)

export default ArrowTop
