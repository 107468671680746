import React from 'react'
import { CONSOLIDATED_SHIPPING_COPY } from 'src/constants'
import { ExplainerImage } from './components'
import { dataTestId } from '../../functions'
import css from './Intro.styles.scss'

const Intro: React.FC = () => (
  <div className={css.Intro} data-testid={dataTestId('intro')}>
    <div className={css.Intro__textContainer}>
      <h1 className={css.Intro__title}>{CONSOLIDATED_SHIPPING_COPY}</h1>
      <h2 className={css.Intro__description}>
        Babylist combines your gifts into fewer boxes so it's easier for you to
        manage shipments.
      </h2>
    </div>
    <ExplainerImage />
  </div>
)

export default Intro
