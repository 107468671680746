import { Switch } from 'baby-design'
import { RegItem } from 'src/types/regItem'
import React from 'react'
import { BaseRegItemFieldsProps } from 'src/routes/(registry)/list/[slug]/components/EditRegItemDrawer/fields/RegItemFields.types'
import css from './PrivateItem.style.scss'

type EditItemDetailsPrivateItemSettingProps = Pick<
  BaseRegItemFieldsProps,
  'categories'
> & {
  regItemFields: RegItem
  updateRegItemFields: (changes: Record<string, unknown>) => void
}

const EditItemDetailsPrivateItemSetting = ({
  categories,
  regItemFields,
  updateRegItemFields,
}: EditItemDetailsPrivateItemSettingProps) => {
  const { private: isPrivate } = regItemFields

  const category = categories.find(
    (cat) => cat.value === regItemFields.categoryId
  )

  return (
    <div className={css.PrivateItem}>
      <div
        className={css.PrivateItem__label}
        id="edit-item-details-private-item-label"
      >
        <div className={css.PrivateItem__heading}>Private</div>
        <div className={css.PrivateItem__subheading}>
          {category?.isPrivate
            ? 'This item is hidden from guests because it is in a private category.'
            : 'This item will be hidden from guests.'}
        </div>
      </div>
      {!category?.isPrivate && (
        <Switch
          aria-labelledby="edit-item-details-private-item-label"
          checked={isPrivate}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
            updateRegItemFields({ private: e.target.checked })
          }
        />
      )}
    </div>
  )
}

export default EditItemDetailsPrivateItemSetting
