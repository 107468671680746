import { useState } from 'react'
import Image from 'components/image'
import { InfoButton, LinkButton } from 'components/buttons'
import { numberToCurrency } from 'lib/money'
import { ProgressBar } from 'components/progress-bar'
import { BabylistOffer } from 'registry/components/add-or-buy/components/BabylistOffer'
import styleMaps from 'shared/style-maps'
import { OfferCard } from './OfferCard'

export const GroupGiftOffer = ({
  regItem,
  registry,
  openFundThisGiftModal,
  openAddToCartModal,
  trackedCreateReservation,
  submitting = false,
}) => {
  const [showBabylist, setShowBabylist] = useState(false)
  const { fundedAmount, goalAmount, offers } = regItem
  const { firstNames } = registry

  let percentFunded = 100
  if (goalAmount && goalAmount > 0) {
    percentFunded = Math.min((fundedAmount / goalAmount) * 100, 100)
  }
  const color = styleMaps.COLORS.salmon
  const babylistOffer = offers && offers.find((o) => o.isBabylist)
  const hideBabylistOffer = fundedAmount > 0

  return (
    <OfferCard>
      <div>
        <div className="flex">
          <div>
            <Image
              src="//images.babylist.com/image/upload/v1631884708/GROUP_GIFT_ICON_whfu5w.png"
              width={44}
            />
          </div>
          <div className="flex-grow col plm pbl">
            <div className="text-bold h5 flex grow mbn">Group Gift</div>
          </div>
        </div>
        <div>
          <div className="h6 mvn">
            {numberToCurrency(fundedAmount)} of {numberToCurrency(goalAmount)}
            <div className="ptm pbl">
              <ProgressBar
                backgroundColor="#eee"
                color={color}
                height={8}
                percent={percentFunded}
                radius={24}
              />
            </div>
          </div>
        </div>
        <div>
          <InfoButton
            block
            pill
            className="pam"
            onClick={openFundThisGiftModal}
          >
            Fund This Gift
          </InfoButton>
        </div>
        {!showBabylist && babylistOffer && !hideBabylistOffer && (
          <div className="mhl bts flex col align-center">
            <LinkButton
              className="text-bold ptl pbn"
              onClick={() => setShowBabylist(true)}
            >
              Or Buy This Item
            </LinkButton>
          </div>
        )}
      </div>
      {showBabylist && (
        <div className="bts mtl ptl">
          <BabylistOffer
            offer={babylistOffer}
            regItem={regItem}
            registry={registry}
            registryFirstNames={firstNames}
            trackedCreateReservation={trackedCreateReservation}
            onClick={openAddToCartModal}
            submitting={submitting}
          />
        </div>
      )}
    </OfferCard>
  )
}

GroupGiftOffer.defaultProps = {
  fundedAmount: 0,
  goalAmount: 1,
  regItem: {},
}
