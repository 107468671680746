import { RegItem } from 'src/types/regItem'
import { useRef } from 'react'
import { useForm } from 'react-hook-form'
import { GroupGiftModal as Modal } from 'components/modal'
import css from './EditGroupGiftModal.style.scss'
import { Input } from 'components/forms'
import { between } from 'lib/form-validation'
import { InfoButton } from 'components/buttons'
import { saveRegItem } from 'src/api/queries'
import { useMutation, useQueryClient } from '@tanstack/react-query'
import { errorMessage, successMessage } from 'lib/flash-message'
import NiceModal, { useModal } from '@ebay/nice-modal-react'
import {
  invalidateRegItemsQuery,
  optimisticallyUpdateRegItem,
} from '../../utils/registryHelpers'

interface EditGroupGiftModalProps {
  regItem: RegItem
}

export default NiceModal.create(({ regItem }: EditGroupGiftModalProps) => {
  const modal = useModal()

  const goalAmountInputRef = useRef<HTMLInputElement | null>(null)
  const { register, handleSubmit, errors } = useForm({
    defaultValues: {
      goalAmount: regItem.goalAmount.toFixed(2),
    },
  })

  const queryClient = useQueryClient()
  const saveRegItemMutation = useMutation({
    mutationFn: (data: { goalAmount: string }) => {
      const groupGiftFields = {
        isGroupGift: true,
        goalAmount: Number(data.goalAmount),
        quantity: 1,
      }

      optimisticallyUpdateRegItem({
        queryClient,
        updatedRegItem: { ...regItem, ...groupGiftFields },
      })

      return saveRegItem(regItem.registry.id, regItem.id, groupGiftFields)
    },
    onSuccess: ({ regItem: newRegItem }: { regItem: RegItem }) => {
      invalidateRegItemsQuery({
        queryClient,
        registryId: newRegItem.registry.id,
      })
      successMessage(`${newRegItem.title} was successfully saved!`)

      modal.resolve(newRegItem)
      modal.remove()
    },
    onError: (error: any) => {
      errorMessage(
        error?.error?.message ||
          'Failed to save. Try refreshing the page. If the problem persists, contact support.'
      )
    },
  })

  const onClose = () => {
    if (!saveRegItemMutation.isLoading) {
      modal.resolve(undefined)
      modal.remove()
    }
  }

  return (
    <Modal
      show={modal.visible}
      onHide={onClose}
      title="Edit Group Gift Goal"
      className={css.group_gift_modal__modal}
      backdropProps={{
        style: { zIndex: 9995 },
      }}
    >
      <div className={css.group_gift_modal__body}>
        <p>
          Sometimes item prices change. We'll keep the value of this group gift
          steady.
        </p>

        <p>
          We don't add on shipping or sales tax to the value of the group gift,
          but you can add an additional amount below to help with that.
        </p>

        <p>
          You can update the total value of the group gift fund goal below at
          any time.
        </p>
        <form
          onSubmit={handleSubmit((data) => saveRegItemMutation.mutate(data))}
        >
          <Input
            type="text"
            name="goalAmount"
            label="Group Gift Goal"
            inputRef={(e: any) => {
              goalAmountInputRef.current = e
              register(e, {
                required: 'Please enter a goal amount',
                validate: between(1, 10000, '$'),
                pattern: {
                  value: /^\d*(\.\d{0,2})?$/,
                  message: 'Please enter a valid goal amount',
                },
              })
            }}
            error={errors.goalAmount && errors.goalAmount?.message}
            style={{ backgroundColor: 'transparent' }}
          />
          <InfoButton pill block type="submit" disabled={!!errors.goalAmount}>
            Set Custom Goal
          </InfoButton>
        </form>
      </div>
    </Modal>
  )
})
