import classNames from 'classnames'
import { useQuery } from '@tanstack/react-query'

import { Button, Image, TextLink } from 'baby-design'
import { LinkOut } from 'baby-design/icons'

import { STORE_PATH } from 'bl-health/constants'
import { getInsuranceValidatorCarouselProducts } from 'bl-health/requests'
import { track, useTracking } from 'lib/analytics'
import ScrollLockModal from 'shared/ScrollLockModal'
import css from './EligibilityModal.styles.scss'
import { InsuranceEligibilityValidatorToolVariant } from './InsuranceEligibilityValidatorTool'

interface EligibleModalProps {
  isOpen: boolean
  eligibleStateId?: number
  eligibleInsuranceId?: number
  onClose: () => void
  variant: InsuranceEligibilityValidatorToolVariant
}

export default function EligibilityModal({
  eligibleStateId,
  eligibleInsuranceId,
  isOpen,
  onClose,
  variant,
}: EligibleModalProps) {
  const tracker = useTracking()

  const { data: carouselProducts } = useQuery({
    queryFn: getInsuranceValidatorCarouselProducts,
    queryKey: ['getInsuranceValidatorCarouselProducts'],
  })

  const linkParams = new URLSearchParams()
  if (eligibleStateId && eligibleInsuranceId) {
    linkParams.append('state_id', eligibleStateId.toString())
    linkParams.append('insurance_id', eligibleInsuranceId.toString())
  }
  const link = `/health${STORE_PATH}?${linkParams.toString()}`

  const eventLocation =
    variant === 'guide' ? track.EventLocation.GUIDE : track.EventLocation.FEED

  return (
    <ScrollLockModal
      showCloseButton
      handleClose={onClose}
      isOpen={isOpen}
      primaryButton={
        <Button
          block
          href={link}
          icon={{ side: 'right', icon: <LinkOut /> }}
          size="md"
          target="_blank"
          variant="primary"
          onClick={() => {
            tracker.trackEvent({
              event: track.chooseBreastPumpButtonClicked,
              eventLocation,
            })
          }}
        >
          Choose your breast pump
        </Button>
      }
    >
      <h1>You're eligible - let's find you the perfect pump!</h1>
      <div className={css.EligibilityModal__subtitle}>
        Select your breast pump from a variety of insurance-covered options or
        choose to upgrade at a fraction of the cost.
      </div>
      <div className={css.EligibilityModal__image_grid}>
        {carouselProducts?.map((product) => (
          <div className={css.EligibilityModal__image_grid__item}>
            <Image
              alt={`picture of ${product.title}`}
              className={css.EligibilityModal__image_grid__item__image}
              src={product.imageUrl}
            />
            <div className={css.EligibilityModal__image_grid__item__title}>
              {product.title}
            </div>
          </div>
        ))}
        <div
          className={classNames(
            css.EligibilityModal__image_grid__item,
            css['EligibilityModal__image_grid__item--link']
          )}
        >
          And many more!{' '}
          <TextLink
            underline
            className={css.EligibilityModal__image_grid__item__title__link}
            href={link}
            icon={{
              side: 'right',
              icon: <LinkOut />,
              size: 'xs',
            }}
            size="xs"
            target="_blank"
            variant="eggplant"
            onClick={() => {
              tracker.trackEvent({
                event: track.chooseBreastPumpButtonClicked,
                eventLocation,
              })
            }}
          >
            See all
          </TextLink>
        </div>
      </div>
    </ScrollLockModal>
  )
}
