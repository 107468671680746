import React, { useEffect, useState } from 'react'
import { RegItem } from 'src/types/regItem'
import { NavLink, RightDrawer } from 'baby-design'
import NiceModal, { NiceModalHocProps, useModal } from '@ebay/nice-modal-react'
import { useRegItemReservations } from 'src/routes/(registry)/api/reservations'
import { showCartItemAddedToast } from 'shared/CartItemAddedToast/showCartItemAddedToast'
import { RegItemFields } from './fields/RegItemFields'
import { FavorFields } from './fields/FavorFields'
import { ExternalRegistryFields } from './fields/ExternalRegistryFields'
import { GiftCardFields } from './fields/GiftCardFields'
import { CrowdfundFields } from './fields/CrowdfundFields'
import { GroupGiftFields } from './fields/GroupGiftFields'

import ConfirmRegItemDeleteModal from '../ConfirmRegItemDeleteModal/ConfirmRegItemDeleteModal'
import GroupGiftRedemptionModal from '../GroupGiftRedemptionModal/GroupGiftRedemptionModal'
import { useRegistry } from '../../utils/useQueries'
import { getCategoriesAsKeyValue } from '../../utils/productHelpers'
import useSaveRegItem from '../../hooks/useSaveRegItem'
import useGroupGiftUnredeemedFundsModal from '../../hooks/useGroupGiftUnredeemedFundsModal'

interface EditRegItemDrawerProps {
  regItem: RegItem
}

export default NiceModal.create(({ regItem: initialRegItem }) => {
  const modal = useModal()
  const [regItem, setRegItem] = useState(initialRegItem)
  const { openUnredeemedFundsModal } = useGroupGiftUnredeemedFundsModal(regItem)

  const {
    saveRegItemMutation,
    onSave,
    regItemChanges,
    setRegItemChanges,
    handleClose,
    hasUnsavedChanges,
    clearRegItemFields,
    updateRegItemFields,
  } = useSaveRegItem({ regItem, modal })

  const { data: reservations } = useRegItemReservations(regItem.id)

  const categories = getCategoriesAsKeyValue(useRegistry()?.categories || [])

  const regItemFields: RegItem = {
    ...regItem,
    ...regItemChanges,
  }

  useEffect(() => {
    setRegItemChanges({})
  }, [modal.visible])

  useEffect(() => {
    setRegItem(initialRegItem)
  }, [initialRegItem])

  const handleUpdateRegItem = (fields: Record<string, any>) => {
    setRegItem({
      ...regItem,
      ...fields,
    })
  }

  const handleDelete = async () => {
    if (regItem.isGroupGift) {
      const confirmGroupGiftRedemption: boolean | undefined =
        await NiceModal.show(GroupGiftRedemptionModal, {
          regItem: regItem,
          action: 'delete-reg-item',
        })
      if (confirmGroupGiftRedemption) {
        modal.hide()
      }
      return
    }

    const confirmDeleteModal: boolean = await NiceModal.show(
      ConfirmRegItemDeleteModal,
      { regItem: regItem }
    )
    if (confirmDeleteModal) {
      modal.hide()
    }
  }

  const onAddToCartSuccess = () => {
    modal.hide()
    const images = [{ url: regItem.imgUrl, thumbnailUrl: '' }]
    showCartItemAddedToast({
      product: {
        name: regItem.title,
        images,
      },
      timeout: 2000,
    })
    if (regItem.fundsUnredeemedAmount && regItem.fundsUnredeemedAmount > 0) {
      openUnredeemedFundsModal()
    }
  }

  let Fields = RegItemFields
  if (regItem.isFavor) {
    Fields = FavorFields
  } else if (regItem.isExternalRegistry) {
    Fields = ExternalRegistryFields
  } else if (regItem.isGiftCard) {
    Fields = GiftCardFields
  } else if (regItem.isCrowdfund) {
    Fields = CrowdfundFields
  } else if (regItem.isGroupGift) {
    Fields = GroupGiftFields
  }

  return (
    <RightDrawer
      handleClose={handleClose}
      handleDismount={modal.remove}
      isOpen={modal.visible}
      title="Edit Item Details"
      contextualIcon={
        <NavLink
          variant="eggplant"
          size="md"
          onClick={onSave}
          disabled={saveRegItemMutation.isLoading || !hasUnsavedChanges()}
        >
          Save
        </NavLink>
      }
    >
      <Fields
        categories={categories}
        clearRegItemFields={clearRegItemFields}
        handleDelete={handleDelete}
        regItem={regItem}
        regItemFields={regItemFields}
        reservations={reservations || []}
        updateRegItemFields={updateRegItemFields}
        onAddToCartSuccess={onAddToCartSuccess}
        onRegItemUpdated={handleUpdateRegItem}
      />
    </RightDrawer>
  )
}) as React.FC<EditRegItemDrawerProps & NiceModalHocProps>
