import { FavorFieldsProps } from 'src/routes/(registry)/list/[slug]/components/EditRegItemDrawer/fields/RegItemFields.types'
import { EditItemDetailsQuantitySetting } from '../components/QuantitySetting'
import { EditItemDetailsFavoriteSetting } from '../components/Favorite'

import Image from 'components/image'
import { Textarea, IconButton } from 'baby-design'
import { EditPencil } from 'baby-design/icons'

import css from './fields.style.scss'
import { RegItem } from 'src/types/regItem'
import { CategoryDropdown } from '../components/CategoryDropdown'
import NiceModal from '@ebay/nice-modal-react'
import ProductInfoDrawer from '../../ProductInfoDrawer/ProductInfoDrawer'
import EditRegItemDrawer from '../EditRegItemDrawer'
import useMarkPurchasedEnabled from 'src/routes/(registry)/list/[slug]/components/EditRegItemDrawer/hooks/useMarkPurchasedEnabled'
import FavorsSection from 'src/routes/(registry)/list/[slug]/components/EditRegItemDrawer/fields/FavorFields/components/FavorsSection/FavorsSection'
import usePrivateItemsEnabled from 'src/routes/(registry)/list/[slug]/components/EditRegItemDrawer/hooks/usePrivateItemsEnabled'
import EditItemDetailsPrivateItemSetting from 'src/routes/(registry)/list/[slug]/components/EditRegItemDrawer/components/PrivateItem/PrivateItem'

export function FavorFields({
  regItem,
  reservations,
  categories,
  regItemFields,
  updateRegItemFields,
  handleDelete,
  onRegItemUpdated,
}: FavorFieldsProps) {
  const isMarkPurchasedEnabled = useMarkPurchasedEnabled()
  const isPrivateItemsEnabled = usePrivateItemsEnabled()

  const openProductInfoDrawer = async () => {
    const response: RegItem | undefined = await NiceModal.show(
      ProductInfoDrawer,
      { regItem: regItem }
    )
    if (response) {
      NiceModal.show(EditRegItemDrawer, { regItem: response })
    }
  }

  return (
    <div className={css.fields}>
      <div className={css.fields__item_intro}>
        <Image
          src={regItemFields.imgUrl}
          alt={regItemFields.title}
          className={css.fields__item_intro_img}
        />
        <div className={css.fields__item_intro_info}>
          <div className={css.fields__item_title}>{regItemFields.title}</div>
        </div>
        <IconButton
          variant="fill-inverted"
          size="sm"
          className={css.fields__image_edit}
          onClick={openProductInfoDrawer}
        >
          <EditPencil />
        </IconButton>
      </div>
      <div className={css.fields__item_details}>
        <div className={css.fields__item_input}>
          <CategoryDropdown
            regItemFields={regItemFields}
            categories={categories}
            updateRegItemFields={updateRegItemFields}
          />
          <Textarea
            label="Notes to friends and family "
            size="md"
            value={regItemFields.description || undefined}
            onChange={(e) =>
              updateRegItemFields({ description: e.target.value })
            }
            placeholder="Let your Gift Givers know how they can help!"
          />
        </div>
        <EditItemDetailsQuantitySetting
          regItemFields={regItemFields}
          updateRegItemFields={updateRegItemFields}
          handleDelete={handleDelete}
        />
        {isMarkPurchasedEnabled && (
          <FavorsSection
            regItem={regItemFields}
            reservations={reservations}
            onRegItemUpdated={onRegItemUpdated}
          />
        )}
        <EditItemDetailsFavoriteSetting
          regItemFields={regItemFields}
          updateRegItemFields={updateRegItemFields}
        />
        {isPrivateItemsEnabled && (
          <EditItemDetailsPrivateItemSetting
            categories={categories}
            regItemFields={regItemFields}
            updateRegItemFields={updateRegItemFields}
          />
        )}
      </div>
      <div className={css.fields__divider} />
      <div className={css.fields__item_delete}>
        <a className={css.fields__delete_link} onClick={handleDelete}>
          Delete item
        </a>
      </div>
    </div>
  )
}
