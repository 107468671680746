import { RegItem } from 'src/types/regItem'
import { useEffect, useState } from 'react'
import { useMutation, useQueryClient } from '@tanstack/react-query'
import {
  FREE_SHIPPING_THRESHOLD,
  GROUP_GIFT_GOAL_AMOUNT_BUFFER_PERCENT_DISPLAY,
} from 'shared/constants'
import { GroupGiftModal as Modal } from 'components/modal'
import { Button, TextLink } from 'baby-design'
import css from './EnableGroupGiftModal.style.scss'
import { MultiStepWizard } from 'components/presentation'
import GroupGiftProductPreviewCard from './components/GroupGiftProductPreviewCard'
import GroupGiftGoalAmountForm from './components/GroupGiftGoalAmountForm'
import { apiV3PdpPath, groupGiftFaqPath } from 'lib/urls'
import NiceModal, { useModal } from '@ebay/nice-modal-react'
import { FETCH_REG_ITEMS_KEY, saveRegItem } from 'src/api/queries'
import { errorMessage, successMessage } from 'lib/flash-message'
import fetch from 'lib/fetch'
import {
  invalidateRegItemsQuery,
  optimisticallyUpdateRegItem,
} from '../../utils/registryHelpers'

interface EnableGroupGiftModalProps {
  regItem: RegItem
  isFirstGroupGift: boolean
}

interface Product {
  name: string
  image: string
  price: number
}

export const getProduct = (id: number) => fetch(apiV3PdpPath(id))

const EnableGroupGiftModal = ({
  regItem,
  isFirstGroupGift,
}: EnableGroupGiftModalProps) => {
  const modal = useModal()

  const [activeStep, setActiveStep] = useState(isFirstGroupGift ? 1 : 2)
  const [product, setProduct] = useState<Product | undefined>(undefined)

  useEffect(() => {
    setActiveStep(isFirstGroupGift ? 1 : 2)
    setProduct(undefined)
  }, [modal.visible])

  useEffect(() => {
    async function getProductAsync() {
      const resp = await getProduct(regItem.productId)
      const { genericProduct } = resp

      const price =
        genericProduct['products'][genericProduct.activeProductId]['price'][
          'msrp'
        ]
      const image =
        genericProduct['products'][genericProduct.activeProductId]['images'][
          '0'
        ]['thumbnailUrl']

      setProduct({
        name: genericProduct?.name,
        image,
        price,
      })
    }
    getProductAsync()
  }, [regItem])

  const { canEnableGroupGift, groupGiftingDisabledMessage } = regItem

  const queryClient = useQueryClient()
  const saveRegItemMutation = useMutation({
    mutationFn: (regItemFields: Partial<RegItem>) => {
      optimisticallyUpdateRegItem({
        queryClient,
        updatedRegItem: { ...regItem, ...regItemFields },
      })

      return saveRegItem(regItem.registry.id, regItem.id, regItemFields)
    },
    onSuccess: ({ regItem: newRegItem }: { regItem: RegItem }) => {
      invalidateRegItemsQuery({
        queryClient,
        registryId: newRegItem.registry.id,
      })
      successMessage(`${newRegItem.title} was successfully saved!`)

      modal.resolve(newRegItem)
      modal.remove()
    },
    onError: (error: any) => {
      errorMessage(
        error?.error?.message ||
          'Failed to save. Try refreshing the page. If the problem persists, contact support.'
      )
    },
  })

  const onClose = () => {
    if (!saveRegItemMutation.isLoading) {
      modal.resolve(undefined)
      modal.remove()
    }
  }

  let title =
    'Group Gifting allows family and friends to contribute toward a higher priced item on your registry'

  if (activeStep === 2) {
    title = 'Group Gift is Available Only For Items Babylist Sells Directly'
  }

  if (activeStep === 3) {
    title = `Add ${GROUP_GIFT_GOAL_AMOUNT_BUFFER_PERCENT_DISPLAY} to Group Gift Goal to Help Cover Any Taxes${
      (product?.price || 0) < FREE_SHIPPING_THRESHOLD ? ' and Shipping?' : '?'
    }`
  }

  if (!canEnableGroupGift) {
    return (
      <Modal
        show={modal.visible}
        onHide={onClose}
        title={groupGiftingDisabledMessage}
      >
        <div className={css['group_gift_modal__modal--align-center']}>
          <Button variant="primary" size="md" onClick={onClose}>
            Continue
          </Button>
        </div>
      </Modal>
    )
  }

  return (
    <Modal
      show={modal.visible}
      onHide={onClose}
      title={title}
      className={css['group_gift_modal__modal']}
      backdropProps={{
        style: { zIndex: 9995 },
      }}
    >
      <MultiStepWizard activeStep={activeStep} animateInitialStep={false}>
        {/* Step 1 */}
        <div className={css.group_gift_modal__body}>
          <p>
            Each group gift contribution is a Babylist Shop gift card. Gift
            cards are not returnable, transferable to another account, or
            refundable after purchase (except as required by law).
          </p>

          <p>
            Group gift contributions can be used towards this item (or any other
            product Babylist sells).
          </p>

          <p>
            Once the group gift is fully funded, it will not automatically ship.
            It needs to be purchased by the registry owner.
          </p>

          <p>
            <TextLink
              href={groupGiftFaqPath}
              target="_blank"
              variant="default"
              size="lg"
            >
              See the full Group Gift FAQ
            </TextLink>
          </p>
          <div className={css['group_gift_modal__modal--align-center']}>
            <Button
              variant="primary"
              size="md"
              onClick={() => setActiveStep(activeStep + 1)}
            >
              Got It
            </Button>
          </div>
        </div>

        {/* Step 2 */}
        <div className={css.group_gift_modal__body}>
          <p className={css['group_gift_modal__modal--text-align-center']}>
            <strong>Yes</strong>, make this item group giftable (this will
            remove other retailer links from the item)
          </p>
          <div className={css.group_gift_modal__preview_card}>
            <GroupGiftProductPreviewCard product={product} />
          </div>
          <div className={css['group_gift_modal__modal--align-center']}>
            <Button
              variant="primary"
              size="md"
              onClick={() => setActiveStep(activeStep + 1)}
              disabled={!product}
            >
              Continue
            </Button>
          </div>
        </div>

        {/* Step 3 */}
        <div>
          <GroupGiftGoalAmountForm
            basePrice={product?.price || 0}
            onSubmit={(fields) => saveRegItemMutation.mutate(fields)}
          />
        </div>
      </MultiStepWizard>
    </Modal>
  )
}

export default NiceModal.create(({ regItem }: EnableGroupGiftModalProps) => {
  const queryClient = useQueryClient()

  const regItemsQueryResponse:
    | { pages: { regItems: RegItem[] }[] }
    | undefined = queryClient.getQueryData([
    FETCH_REG_ITEMS_KEY,
    regItem.registry.id,
  ])

  const anyGroupGiftsExist = regItemsQueryResponse?.pages?.some(
    (page: { regItems: RegItem[] }) =>
      Object.values(page?.regItems).find((ri: RegItem) => ri.isGroupGift)
  )

  return (
    <EnableGroupGiftModal
      isFirstGroupGift={!anyGroupGiftsExist}
      regItem={regItem}
    />
  )
})
