import Image from 'components/image'
import { InfoButton } from 'components/buttons'
import { FREE_SHIPPING_THRESHOLD } from 'shared/constants'
import { absoluteUrl, reservationsPath } from 'lib/urls'
import { track, useTracking } from 'lib/analytics'
import { PriceTag } from 'baby-design'
import { getPriceTagPropsFromPriceDetails } from 'src/utils/productHelpers'
import ExternalProductOffer from './ExternalProductOffer'

export const BabylistOffer = ({
  offer,
  regItem,
  registry,
  onClick,
  inCart,
  trackedCreateReservation,
  registryFirstNames,
  submitting = false,
}) => {
  const { isAvailableOnBabylist, offers, isGiftCard } = regItem
  const { msrp, minPrice, maxPrice, currentPrice, onSale, saleCallouts } =
    getPriceTagPropsFromPriceDetails(offer.priceDetails)

  const priceTagProps = {
    callouts: saleCallouts,
    currentPrice,
    currentlyInActiveSale: onSale,
    minPrice,
    maxPrice,
    msrp,
  }
  const hasAdditionalOffers = offers.length > 1
  const tracker = useTracking()
  function handleAddToCart(e) {
    if (onClick) {
      onClick(e)
    }
    tracker.trackEvent({
      event: track.registryProductOfferClicked,
      offerIds: regItem.offers.map(({ id }) => id),
      regItemId: regItem.id,
      store: 'Babylist',
    })
  }

  return (
    <>
      <div className="flex">
        <div>
          <Image
            src="//images.babylist.com/image/upload/w_88/v1679504724/icons/Babylist.png"
            width={44}
          />
        </div>
        <div className="flex-grow col plm">
          <div className="flex">
            <div className="text-bold h5 flex grow mtm mbn">Babylist Shop</div>
            <div className="text-bold h5 mtm mbn">
              <PriceTag {...priceTagProps} variant="compact" />
            </div>
          </div>
        </div>
      </div>
      <div className="xsmall mts lh-lg">
        <div>Free shipping on orders ${FREE_SHIPPING_THRESHOLD}+*</div>
        <div>Free returns for {registryFirstNames}.</div>
      </div>
      <div className={hasAdditionalOffers && 'pbl'}>
        {inCart ? (
          <InfoButton
            block
            empty
            pill
            className="mtm pam"
            href={absoluteUrl(reservationsPath)}
          >
            In Cart
          </InfoButton>
        ) : (
          <>
            <InfoButton
              block
              pill
              className="mtm pam"
              disabled={!isAvailableOnBabylist}
              submitting={submitting}
              onClick={handleAddToCart}
            >
              Add to Cart
            </InfoButton>
            {!isAvailableOnBabylist && (
              <small className="block text-center xsmall pam">
                Out of Stock
              </small>
            )}
          </>
        )}
      </div>

      {offers && !isGiftCard ? (
        <ExternalProductOffer
          offers={offers}
          regItem={regItem}
          registry={registry}
          trackedCreateReservation={trackedCreateReservation}
        />
      ) : null}
    </>
  )
}

BabylistOffer.defaultProps = {
  registryFirstNames: 'the family',
}
