import React from 'react'
import NiceModal from '@ebay/nice-modal-react'
import { Callout, TextLink } from 'baby-design'
import { SimpleShipping } from 'baby-design/icons'
import { CONSOLIDATED_SHIPPING_COPY } from 'src/constants'
import ConsolidatedShippingExplainerModal from '../ConsolidatedShippingExplainerModal'
import { ConsolidatedShippingCalloutProps } from './ConsolidatedShippingCallout.types'
import css from './ConsolidatedShippingCallout.styles.scss'

const ConsolidatedShippingCallout: React.FC<
  ConsolidatedShippingCalloutProps
> = ({ textLink, text, title }) => {
  const onClick = async () => {
    await NiceModal.show(ConsolidatedShippingExplainerModal)
  }

  const textLinkCta = textLink ? (
    React.cloneElement(textLink, {
      innerContentClassName: css.ConsolidatedShippingCallout__link,
    })
  ) : (
    <TextLink
      underline
      innerContentClassName={css.ConsolidatedShippingCallout__link}
      size="md"
      onClick={onClick}
    >
      Learn more
    </TextLink>
  )

  const titleText = title || CONSOLIDATED_SHIPPING_COPY

  return (
    <>
      <Callout
        backgroundColor="green"
        cta={textLinkCta}
        icon={<SimpleShipping />}
        iconColor="green"
        text={text}
        title={titleText}
      />
    </>
  )
}

export default ConsolidatedShippingCallout
