import React from 'react'
import { TextLink } from 'baby-design'
import { settingsConsolidatedShippingPath } from 'lib/urls'
import { ConsolidatedShippingSubtext } from 'registry/forms/consolidated-shipping-form-fields'
import { CloseButton } from './components'
import { dataTestId } from '../../functions'
import { ActionsProps } from './Actions.types'
import css from './Actions.styles.scss'

const Actions: React.FC<ActionsProps> = ({ handleClose }) => (
  <div className={css.Actions} data-testid={dataTestId('actions')}>
    <div className={css.Actions__actionContainer}>
      <CloseButton handleClose={handleClose} />
      <TextLink underline href={settingsConsolidatedShippingPath} size="md">
        Manage shipping preferences
      </TextLink>
    </div>
    <ConsolidatedShippingSubtext centered />
  </div>
)

export default Actions
