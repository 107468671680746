import React from 'react'
import PurchasesSection from 'src/routes/(registry)/list/[slug]/components/EditRegItemDrawer/components/PurchasesSection/PurchasesSection'
import PurchaseBullet from 'src/routes/(registry)/list/[slug]/components/EditRegItemDrawer/components/PurchasesSection/components/PurchaseBullet/PurchaseBullet'
import RecordFavorModal from 'src/routes/(registry)/list/[slug]/components/EditRegItemDrawer/components/RecordFavorModal/RecordFavorModal'
import { purchasesStatus } from 'src/routes/(registry)/list/[slug]/components/EditRegItemDrawer/components/PurchasesSection/utils/helpers'
import { FavorsSectionProps } from './FavorsSection.types'
import { HelpFavors } from 'baby-design/icons'

const TITLE = 'Favors gifted'
const RECORD_FAVOR = 'Record a favor'

const FavorsSection: React.FC<FavorsSectionProps> = ({
  onRegItemUpdated,
  regItem,
  reservations = [],
}) => {
  const currentQuantity = reservations.reduce(
    (sum, reservation) => sum + reservation.quantity,
    0
  )

  return (
    <PurchasesSection
      bullet={PurchaseBullet}
      icon={HelpFavors}
      initialButtonText={RECORD_FAVOR}
      markAsPurchasedModal={RecordFavorModal}
      regItem={regItem}
      reservations={reservations}
      status={purchasesStatus(currentQuantity, regItem.quantity)}
      title={TITLE}
      onRegItemUpdated={onRegItemUpdated}
    />
  )
}

export default FavorsSection
