import React from 'react'
import classNames from 'classnames'

import css from './PricingTreatment.styles.scss'
import { PricingTreatmentProps } from './PricingTreatment.types'
import {
  displayPrice,
  displayStrikePrice,
  formatPrice,
  shouldDisplayPriceRange,
  shouldDisplayStrikePrice,
} from './PricingTreatment.utils'

const PricingTreatment: React.FC<PricingTreatmentProps> = (props) => {
  const {
    price,
    priceRange,
    decimalPlaces = 2,
    strikePrice,
    size = 'md',
    weightVariant = 'default',
    saleVariant = 'default',
  } = props

  const PricingTreatmentClasses = classNames(css.PricingTreatment, {
    [css.PricingTreatment__sm]: size === 'sm',
    [css.PricingTreatment__md]: size === 'md',
    [css.PricingTreatment__lg]: size === 'lg',
    [css.PricingTreatment__bold]: weightVariant === 'bold',
    [css.PricingTreatment__sale]: saleVariant === 'red',
  })

  const isPriceRangeVisible = shouldDisplayPriceRange(priceRange)
  const isStrikePriceVisible = shouldDisplayStrikePrice(
    price,
    strikePrice,
    priceRange
  )

  return (
    <div className={PricingTreatmentClasses} data-testid="PricingTreatment">
      {isPriceRangeVisible && (
        <span className={css.PricingTreatment__PriceRange}>
          {formatPrice(priceRange?.min || 0, decimalPlaces)}
          <span className={css.PricingTreatment__Dash}>-</span>
          {formatPrice(priceRange?.max || 0, decimalPlaces)}
        </span>
      )}
      {!isPriceRangeVisible && (
        <span className={css.PricingTreatment__Price}>
          {displayPrice(price || 0, decimalPlaces)}
        </span>
      )}
      {isStrikePriceVisible && (
        <>
          {' '}
          <span className={css.PricingTreatment__StrikePrice}>
            {displayStrikePrice(strikePrice || 0, decimalPlaces)}
          </span>
        </>
      )}
    </div>
  )
}

export default PricingTreatment
