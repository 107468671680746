import React from 'react'

interface BabylistStoreProps {
  className?: string
  rounded?: boolean
}

const BabylistStore: React.FC<BabylistStoreProps> = ({
  className,
  rounded = true,
}: BabylistStoreProps) => (
  <svg
    className={className}
    fill="none"
    height="24"
    viewBox="0 0 24 24"
    width="24"
    xmlns="http://www.w3.org/2000/svg"
  >
    {rounded ? (
      <path
        d="M0 12C0 5.37258 5.37258 0 12 0C18.6274 0 24 5.37258 24 12C24 18.6274 18.6274 24 12 24C5.37258 24 0 18.6274 0 12Z"
        fill="#6E3264"
      />
    ) : (
      <rect fill="#6E3264" height="24" width="24" />
    )}
    <path
      clipRule="evenodd"
      d="M13.5886 7.373C12.8127 7.73187 12.3123 8.3366 11.965 9.07537H11.9631C11.6157 8.3366 11.1153 7.73187 10.3394 7.373C9.0996 6.79945 7.88883 6.87778 6.78061 7.67573C5.68805 8.46251 5.26986 9.57261 5.43464 10.9044C5.64773 12.6262 6.59653 13.9279 7.82979 15.0579C9.05507 16.1803 10.4653 17.0228 11.9637 17.7248C13.4621 17.0228 14.8731 16.1803 16.0981 15.0579C17.3316 13.9279 18.2803 12.6262 18.4934 10.9044C18.6581 9.57261 18.2399 8.46251 17.1474 7.67573C16.5161 7.22113 15.8513 7.00006 15.1671 7.00006C14.6501 7.00006 14.1221 7.12626 13.5886 7.373Z"
      fill="url(#paint0_linear_3112_3554)"
      fillRule="evenodd"
    />
    <path
      clipRule="evenodd"
      d="M8.82319 7.00013C8.82319 7.00013 6.16781 8.62286 7.08253 11.1307C7.9971 13.6384 11.5375 12.2813 11.5375 12.2813L11.5343 12.2803C11.5343 12.2803 15.0748 10.9231 15.9893 13.431C16.201 14.0111 16.2214 14.5439 16.128 15.0201L16.13 15.0288C17.3479 13.9055 18.282 12.6112 18.4934 10.9044C18.6581 9.57261 18.2399 8.46251 17.1474 7.67573C16.0393 6.87764 14.8283 6.79931 13.5886 7.373C12.8127 7.73187 12.3123 8.3366 11.965 9.07537H11.9631C11.6157 8.3366 11.1153 7.73187 10.3394 7.373C9.82038 7.13293 9.30665 7.00709 8.80331 7.00042L8.82319 7.00013Z"
      fill="#FF7AAB"
      fillRule="evenodd"
    />
    <defs>
      <linearGradient
        gradientUnits="userSpaceOnUse"
        id="paint0_linear_3112_3554"
        x1="18.4675"
        x2="18.4675"
        y1="17.9012"
        y2="7.27514"
      >
        <stop stopColor="#FF3B3F" />
        <stop offset="1" stopColor="#FF79AA" />
      </linearGradient>
    </defs>
  </svg>
)

export default BabylistStore
