// @ts-ignore
import { LazyLoadComponent } from 'react-lazy-load-image-component'
import { ImageProps } from './Image.types'

const getAlt = (alt?: string): string => {
  if (!alt) {
    return ''
  }
  if (alt[alt.length - 1] !== '.') {
    return `${alt}.` // period at the end forces the screen reader to pause before reading on
  }
  return alt
}

const Image: React.FC<ImageProps> = ({
  alt,
  className,
  height,
  lazyLoad,
  pinterest,
  placeholder,
  role,
  src,
  style,
  width,
  wrapperClassName,
}: ImageProps) => {
  const imgProps = {
    className,
    height,
    nopin: pinterest ? undefined : 'nopin',
    role,
    src,
    style,
    width,
    loading: lazyLoad ? 'lazy' : 'auto',
  }

  const imageComponent = (
    <img
      {...imgProps}
      alt={getAlt(alt)}
      loading={imgProps.loading as 'lazy' | 'eager' | undefined}
    />
  )

  if (lazyLoad === false) {
    return imageComponent
  }

  return (
    <LazyLoadComponent
      delayTime={150}
      height={imgProps.height}
      placeholder={placeholder}
      width={imgProps.width}
      wrapperClassName={wrapperClassName}
    >
      {imageComponent}
    </LazyLoadComponent>
  )
}

Image.defaultProps = {
  lazyLoad: true,
}

export default Image
