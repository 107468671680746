import React from 'react'
import { PinkCheckmarkIcon } from 'components/icons'
import { InfoButton } from 'components/buttons'

interface FavorReservationStatusActionProps {
  printableUrl: string
}

const FavorReservationStatusAction: React.FC<
  FavorReservationStatusActionProps
> = ({ printableUrl }) => (
  <div>
    {/* @ts-ignore */}
    <PinkCheckmarkIcon />{' '}
    <span className="text-bold text-brand-info">Gifted By You</span>
    <InfoButton
      empty
      pill
      className="block mtm"
      href={printableUrl}
      size="small"
    >
      Download PDF
    </InfoButton>
  </div>
)

export default FavorReservationStatusAction
