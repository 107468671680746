import classnames from 'classnames'
// eslint-disable-next-line
import { Discount } from 'baby-design/icons'
import css from './PromoMessage.styles.scss'

export interface PromoMessageProps {
  className?: string
  size?: 'xs' | 'sm' | 'md' | 'lg'
  text: string
}

const PromoMessage = ({ className, size = 'sm', text }: PromoMessageProps) => (
  <div
    className={classnames(
      className,
      css.PromoMessage,
      css[`PromoMessage--size-${size}`]
    )}
    data-testid="component"
  >
    <Discount className={css.PromoMessage__icon} />
    <span className={css.PromoMessage__text}>{text}</span>
  </div>
)

export default PromoMessage
