import React from 'react'
import { Button } from 'baby-design'
import { CloseButtonProps } from './CloseButton.types'
import { dataTestId } from '../../../../functions'
import css from './CloseButton.styles.scss'

interface GotItButtonProps extends CloseButtonProps {
  variant: 'desktop' | 'mobile'
}

const GotItButton: React.FC<GotItButtonProps> = ({ handleClose, variant }) => (
  <>
    <Button
      block
      className={css[`CloseButton--${variant}`]}
      data-testid={dataTestId(`close-${variant}`)}
      size={variant === 'desktop' ? 'lg' : 'md'}
      variant="tertiary"
      onClick={handleClose}
    >
      Got it
    </Button>
  </>
)

const CloseButton: React.FC<CloseButtonProps> = ({ handleClose }) => (
  <>
    <GotItButton handleClose={handleClose} variant="desktop" />
    <GotItButton handleClose={handleClose} variant="mobile" />
  </>
)

export default CloseButton
