// TODO - https://babylist.atlassian.net/browse/HRT-7
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React from 'react'
import classNames from 'classnames'

import css from './TextLink.styles.scss'

import { TextLinkProps } from './TextLink.types'

const TextLink: React.FC<TextLinkProps> = ({
  variant = 'default',
  size = 'md',
  disabled = false,
  className,
  innerContentClassName,
  children,
  icon,
  underline = false,
  weight = 'medium',
  onClick = () => null,
  isSelected = false,
  ...props
}) => {
  const TextLinkClasses = classNames(
    css.TextLink,
    css[`text_link--size-${size}-${weight}`],
    icon?.size ? css[`text_link--icon-${icon.size}`] : null,
    css[`text_link--style-${variant}`],
    isSelected && css['text_link--selected'],
    disabled && css['text_link--disabled'],
    className
  )

  return (
    <a
      aria-disabled={disabled}
      className={TextLinkClasses}
      data-testid="text-link"
      onClick={(e) => {
        e.currentTarget.blur()
        onClick(e)
      }}
      {...props}
    >
      {icon?.side === 'left' && icon.icon}
      <div
        className={classNames(
          css.text_link__label,
          underline && css['text_link__label--underline'],
          innerContentClassName
        )}
      >
        {children}
      </div>
      {icon?.side === 'right' && icon.icon}
    </a>
  )
}

export default TextLink
/* eslint-enable jsx-a11y/no-static-element-interactions */
/* eslint-enable jsx-a11y/anchor-is-valid */
/* eslint-enable jsx-a11y/click-events-have-key-events */
