import React, { useState } from 'react'
import classNames from 'classnames'

import { TextInputProps } from './TextInput.types'
import Check from '../../../icons/Check.svg'

import css from './TextInput.styles.scss'

const TextInput: React.FC<TextInputProps> = ({
  label,
  size,
  className,
  controlClassName,
  prefix,
  status,
  RightIcon,
  LeftIcon,
  variant = 'primary',
  inputProps = {},
}) => {
  const {
    className: inputClassName,
    disabled,
    id,
    onBlur,
    onFocus,
    ...inputPropsRest
  } = inputProps
  const [focused, setFocused] = useState<boolean>(false)
  // TODO: Once we are on React 18 we can use the useId() hook to generate a unique ID for the tooltip
  // Until then this little trick will convert a random number to a base 36 string
  const [labelId] = useState<string>(
    id || Math.random().toString(36).substring(2)
  )
  const [errorMessageId] = useState<string>(
    Math.random().toString(36).substring(2)
  )

  const TextInputClasses = classNames(
    css.TextInput,
    css[`TextInput--${size}`],
    css[`TextInput--${variant}`],
    {
      [css['TextInput--focused']]: focused,
      [css['TextInput--disabled']]: disabled,
      [css[`TextInput--${status?.type}`]]: status?.type,
    },
    className
  )
  const ControlClasses = classNames(css.TextInput__control, controlClassName)

  const shouldShowErrorMessage = status?.type === 'error' && status.message

  return (
    <div className={TextInputClasses}>
      <label className={css.TextInput__label} htmlFor={labelId}>
        {label}
        <div className={ControlClasses}>
          {LeftIcon && <span className={css.TextInput__icon}>{LeftIcon}</span>}
          <span className={css.TextInput__inputWrapper}>
            {prefix && <span className={css.TextInput__prefix}>{prefix}</span>}
            <input
              aria-errormessage={
                shouldShowErrorMessage ? errorMessageId : undefined
              }
              aria-invalid={status?.type === 'error'}
              className={classNames(css.TextInput__input, inputClassName)}
              disabled={disabled}
              id={labelId}
              onBlur={(e) => {
                setFocused(false)
                onBlur?.(e)
              }}
              onFocus={(e) => {
                setFocused(true)
                onFocus?.(e)
              }}
              {...inputPropsRest}
            />
          </span>
          {RightIcon && (
            <span
              className={classNames(
                css.TextInput__icon,
                css['TextInput__icon--right']
              )}
            >
              {RightIcon}
            </span>
          )}
        </div>
      </label>
      {status?.message &&
        (status.type === 'success' ? (
          <div className={css.TextInput__successMessage} role="status">
            <Check /> {status.message}
          </div>
        ) : (
          <div className={css.TextInput__errorMessage} id={errorMessageId}>
            {status.message}
          </div>
        ))}
    </div>
  )
}

export default TextInput
