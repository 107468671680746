/* eslint-disable react/prop-types */
import * as React from 'react'
import { AnimatePresence, motion } from 'framer-motion'
import merge from 'lodash/merge'
import { UI } from '../../../global'

export interface TransitionBuilderProps
  extends UI.TransitionProps,
    React.HTMLAttributes<HTMLDivElement> {
  /**
   * Accepts a list of transition components, and combines each transition into one single transition.
   */
  Transitions: Record<
    'makeProps',
    (params: UI.TransitionPropMakerParams) => UI.TransitionSettings
  >[]
}

export const TransitionBuilder = React.forwardRef<
  HTMLDivElement,
  TransitionBuilderProps
>(function TransitionBuilder(
  {
    Transitions,
    animateOnEnter = true,
    animateOnExit = true,
    children,
    delay,
    duration = 0.23,
    show,
    ...rest
  },
  ref
) {
  return (
    <AnimatePresence>
      {show && (
        <motion.div
          ref={ref}
          {...rest}
          {...merge(
            {},
            ...Transitions.map((transitionComponent) =>
              transitionComponent.makeProps({
                animateOnEnter,
                animateOnExit,
                duration,
                delay,
              })
            )
          )}
        >
          {children}
        </motion.div>
      )}
    </AnimatePresence>
  )
})

export default TransitionBuilder
