import { ReactNode } from 'react'

interface AnchoredH2Props {
  explicitAnchor?: string
  textForAnchor?: string
  children: ReactNode
}

const AnchoredH2: React.FC<AnchoredH2Props> = ({
  textForAnchor,
  explicitAnchor,
  children,
}: AnchoredH2Props) => {
  const anchor =
    explicitAnchor ||
    textForAnchor
      ?.trim()
      .toLowerCase()
      .replace(/[^a-zA-Z0-9 -]/g, '')
      .replace(/\s/g, '-')

  return (
    <h2 id={anchor} style={{ scrollMarginTop: '100px' }}>
      {children}
    </h2>
  )
}

export default AnchoredH2
