import React, { useEffect } from 'react'
import classNames from 'classnames'
import { ModalBackdropProps } from './ModalBackdrop.types'
import css from './ModalBackdrop.styles.scss'

const ModalBackdrop: React.FC<ModalBackdropProps> = ({
  className,
  handleClose,
}) => {
  useEffect(() => {
    const handleKeyDown = (e: KeyboardEvent) => {
      if (e.key === 'Escape') {
        handleClose()
      }
    }

    document.addEventListener('keydown', handleKeyDown, false)
    return () => {
      document.removeEventListener('keydown', handleKeyDown, false)
    }
  }, [handleClose])

  return (
    <div
      aria-label="Modal backdrop, click or press escape key to close modal"
      className={classNames(css.ModalBackdrop, className)}
      data-testid="modal-backdrop"
      role="button"
      tabIndex={0}
      onClick={handleClose}
      onKeyDown={() => undefined}
    />
  )
}

export default ModalBackdrop
