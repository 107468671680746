import React from 'react'
import NiceModal from '@ebay/nice-modal-react'
import { GiftDetailsDrawer } from 'src/routes/(registry)/list/[slug]/components/EditRegItemDrawer/components/GiftDetailsDrawer/GiftDetailsDrawer'
import ReservationBullet from 'src/routes/(registry)/list/[slug]/components/EditRegItemDrawer/components/ReservationBullet/ReservationBullet'
import { numberToCurrency } from 'lib/money'
import { ContributionBulletProps } from './ContributionBullet.types'

const ContributionBullet: React.FC<ContributionBulletProps> = ({
  reservation,
}) => {
  const handleOpenGiftDetails = () => {
    NiceModal.show(GiftDetailsDrawer)
  }

  const quantityString = numberToCurrency(reservation.giftAmount, {
    truncateZeroCents: true,
    unit: '$',
  }) as string

  return (
    <ReservationBullet
      isSurprise={
        reservation.isSurprise && !reservation.isSurpriseRevealedForUser
      }
      name={reservation.name}
      quantity={quantityString}
      onViewGift={handleOpenGiftDetails}
    />
  )
}

export default ContributionBullet
