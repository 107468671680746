import css from './InsuranceEligibilityValidatorTool.styles.scss'

export default function DefaultHeading() {
  return (
    <div data-testid="default-heading">
      <h2 className={css.InsuranceEligibilityValidatorTool__heading__title}>
        <b>Get your FREE breast pump through insurance!</b>
      </h2>

      <p className={css.InsuranceEligibilityValidatorTool__heading__subtitle}>
        Babylist Health supports hundreds of insurance plans and all major pump
        brands.
      </p>
    </div>
  )
}
