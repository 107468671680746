import Image from 'components/image'
import { Textarea, IconButton, TextInput } from 'baby-design'
import { EditPencil } from 'baby-design/icons'

import { ExternalRegistryFieldsProps } from 'src/routes/(registry)/list/[slug]/components/EditRegItemDrawer/fields/RegItemFields.types'
import css from './fields.style.scss'
import { RegItem } from 'src/types/regItem'
import { CategoryDropdown } from '../components/CategoryDropdown'
import ProductInfoDrawer from '../../ProductInfoDrawer/ProductInfoDrawer'
import NiceModal from '@ebay/nice-modal-react'
import EditRegItemDrawer from '../EditRegItemDrawer'
import EditItemDetailsPrivateItemSetting from 'src/routes/(registry)/list/[slug]/components/EditRegItemDrawer/components/PrivateItem/PrivateItem'
import usePrivateItemsEnabled from 'src/routes/(registry)/list/[slug]/components/EditRegItemDrawer/hooks/usePrivateItemsEnabled'

export function ExternalRegistryFields({
  regItem,
  categories,
  regItemFields,
  updateRegItemFields,
  handleDelete,
}: ExternalRegistryFieldsProps) {
  const isPrivateItemsEnabled = usePrivateItemsEnabled()

  const openProductInfoDrawer = async () => {
    const response: RegItem | undefined = await NiceModal.show(
      ProductInfoDrawer,
      { regItem: regItem }
    )
    if (response) {
      NiceModal.show(EditRegItemDrawer, { regItem: response })
    }
  }

  return (
    <div className={css.fields}>
      <div className={css.fields__item_intro}>
        <Image
          src={regItemFields.imgUrl}
          alt={regItemFields.title}
          className={css.fields__item_intro_img}
        />
        <div className={css.fields__item_intro_info}>
          <div className={css.fields__item_title}>{regItemFields.title}</div>
        </div>
        <IconButton
          variant="fill-inverted"
          size="sm"
          className={css.fields__image_edit}
          onClick={openProductInfoDrawer}
        >
          <EditPencil />
        </IconButton>
      </div>
      <div className={css.fields__item_details}>
        <div className={css.fields__item_input}>
          <TextInput
            inputProps={{
              placeholder: 'https://',
              value: regItemFields.url,
              onChange: (e) => updateRegItemFields({ url: e.target.value }),
            }}
            label="Link to registry"
            size="md"
          />
          <CategoryDropdown
            regItemFields={regItemFields}
            categories={categories}
            updateRegItemFields={updateRegItemFields}
          />
          <Textarea
            label="Notes to friends and family "
            size="md"
            value={regItemFields.description || undefined}
            onChange={(e) =>
              updateRegItemFields({ description: e.target.value })
            }
            placeholder="Tell Gift Givers what they should know about this registry."
          />
          {isPrivateItemsEnabled && (
            <EditItemDetailsPrivateItemSetting
              categories={categories}
              regItemFields={regItemFields}
              updateRegItemFields={updateRegItemFields}
            />
          )}
        </div>
      </div>
      <div className={css.fields__divider} />
      <div className={css.fields__item_delete}>
        <a className={css.fields__delete_link} onClick={handleDelete}>
          Delete item
        </a>
      </div>
    </div>
  )
}
