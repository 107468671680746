import React from 'react'

interface SortProps {
  className?: string
}

const Sort: React.FC<SortProps> = ({ className }: SortProps) => (
  <svg
    className={className}
    fill="none"
    height="24"
    viewBox="0 0 24 24"
    width="24"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      clipRule="evenodd"
      d="M2.46967 14.4697C2.76256 14.1768 3.23744 14.1768 3.53033 14.4697L6.25 17.1893L6.25 5C6.25 4.58579 6.58579 4.25 7 4.25C7.41421 4.25 7.75 4.58579 7.75 5L7.75 17.1893L10.4697 14.4697C10.7626 14.1768 11.2374 14.1768 11.5303 14.4697C11.8232 14.7626 11.8232 15.2374 11.5303 15.5303L7.53033 19.5303C7.23744 19.8232 6.76256 19.8232 6.46967 19.5303L2.46967 15.5303C2.17678 15.2374 2.17678 14.7626 2.46967 14.4697ZM12.4697 9.53033C12.1768 9.23744 12.1768 8.76256 12.4697 8.46967L16.4697 4.46967C16.6103 4.32902 16.8011 4.25 17 4.25C17.1989 4.25 17.3897 4.32902 17.5303 4.46967L21.5303 8.46967C21.8232 8.76256 21.8232 9.23744 21.5303 9.53033C21.2374 9.82322 20.7626 9.82322 20.4697 9.53033L17.75 6.81066V19C17.75 19.4142 17.4142 19.75 17 19.75C16.5858 19.75 16.25 19.4142 16.25 19V6.81066L13.5303 9.53033C13.2374 9.82322 12.7626 9.82322 12.4697 9.53033Z"
      fill="#252324"
      fillRule="evenodd"
      id="Vector"
    />
  </svg>
)

export default Sort
