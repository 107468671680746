import { Document } from '@contentful/rich-text-types'
import RichTextBody from '../../RichTextBody/RichTextBody'

import { AnchoredH2 } from '../Utilities'
import CriteriaCard, {
  PostEmbedCriteriaCard,
} from '../CriteriaCard/CriteriaCard'
import css from './MethodologySection.styles.scss'

interface PostEmbedMethodologySectionFields {
  criteria?: PostEmbedCriteriaCard[]
  headerText: string
  methodologyText: Document
}

export interface MethodologySectionProps {
  fields: PostEmbedMethodologySectionFields
}

const MethodologySection: React.FC<MethodologySectionProps> = ({
  fields,
}: MethodologySectionProps) => (
  <section>
    <AnchoredH2 explicitAnchor="editorial-methodology">
      {fields.headerText}
    </AnchoredH2>
    <div className={css.MethodologySection__criteriaCardContainer}>
      {fields.criteria?.map((criteria) => (
        <CriteriaCard fields={criteria.fields} key={criteria.sys.id} />
      ))}
    </div>
    <div>
      <RichTextBody richTextBody={fields.methodologyText} />
    </div>
  </section>
)

export default MethodologySection
