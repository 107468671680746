/* eslint-disable import/no-extraneous-dependencies */
import { useState } from 'react'
import classNames from 'classnames'
import { IconButton } from 'baby-design'
import { Chevron, Plus } from 'baby-design/icons'
import useFeatureFlag from 'shared/hooks/useFeatureFlag/useFeatureFlag'
import { FLASH_MESSAGE, UPDATE_CART_SUMMARY } from 'shared/constants'
import { parseErrorMessage } from 'lib/api-utils'
import { addCartItem } from 'src/api/queries'
import { RegItem } from 'src/types/regItem'
import { Offer } from 'src/types/offer'
import { SelfPurchaseBuyingOption } from './components/SelfPurchaseBuyingOption'
import { BuyingOption } from './components/BuyingOption'

import css from './BuyingOptions.style.scss'
import { OutOfStockOption } from './components/OutOfStockOption'

interface EditItemDetailsBuyingOptionsProps {
  onAddToCartSuccess?: () => void
  openAddSecondaryLinkDrawer: () => void
  openEditSecondaryLinkDrawer: (offer: Offer) => void
  regItem: RegItem
  selfContained?: boolean
}

export const EditItemDetailsBuyingOptions = ({
  onAddToCartSuccess,
  openAddSecondaryLinkDrawer,
  openEditSecondaryLinkDrawer,
  regItem,
  selfContained = false,
}: EditItemDetailsBuyingOptionsProps) => {
  const [isAddingToCart, setIsAddingToCart] = useState(false)
  const { offers, secondaryOffers } = regItem
  const { flagValue } = useFeatureFlag(
    '24e_ex_dayzero_self_purchase',
    'Control'
  )
  const showBuyingButtons = flagValue === 'Treatment 1'

  const primaryOffers = offers.filter(
    (offer) =>
      !secondaryOffers.find(
        (secondaryOffer) =>
          secondaryOffer.id === offer.id &&
          secondaryOffer.normalUrl === offer.normalUrl
      )
  )

  const handleAddToCart = () => {
    setIsAddingToCart(true)
    addCartItem({
      productId: regItem.productId,
      quantity: 1,
      regItemId: regItem.id,
    })
      .then(() => {
        onAddToCartSuccess?.()
        PubSub.publish(UPDATE_CART_SUMMARY, { animateBadge: true })
      })
      .catch((resp) => {
        PubSub.publish(FLASH_MESSAGE, {
          message: parseErrorMessage(resp),
          variant: 'danger',
        })
      })
      .finally(() => {
        setIsAddingToCart(false)
      })
  }

  const renderPrimaryOffer = (offer: Offer) => {
    if (showBuyingButtons) {
      if (offer.isBabylist && !regItem.isAvailableOnBabylist) {
        return (
          <OutOfStockOption
            isBabylistProductDiscontinued={
              regItem.isBabylistProductDiscontinued
            }
            option={offer}
            productId={regItem.productId}
            useHorizontalPadding={selfContained}
          />
        )
      }

      return (
        <SelfPurchaseBuyingOption
          handleAddToCart={handleAddToCart}
          isAddingToCart={isAddingToCart}
          key={offer.id || offer.url}
          offer={offer as Offer}
          useHorizontalPadding={selfContained}
        />
      )
    }
    return <BuyingOption key={offer.id || offer.url} offer={offer as Offer} />
  }

  return (
    <div
      className={classNames(css.BuyingOptions, {
        [css['BuyingOptions--withBuyingButtons']]: showBuyingButtons,
      })}
    >
      <div className={css.BuyingOptions__heading}>Buying Options</div>
      <div
        className={classNames({
          [css.BuyingOptions__selfContained]: selfContained,
        })}
      >
        {primaryOffers.map(renderPrimaryOffer)}
        {secondaryOffers.map((offer) =>
          showBuyingButtons ? (
            <SelfPurchaseBuyingOption
              editOnClick={() => openEditSecondaryLinkDrawer(offer)}
              handleAddToCart={handleAddToCart}
              isAddingToCart={isAddingToCart}
              key={offer.id || offer.url}
              offer={offer as Offer}
              useHorizontalPadding={selfContained}
            />
          ) : (
            <BuyingOption
              editOnClick={() => openEditSecondaryLinkDrawer(offer)}
              key={offer.id || offer.url}
              offer={offer as Offer}
            />
          )
        )}
        {secondaryOffers.length < 2 && (
          <button
            className={css.BuyingOptions__add_buying_option}
            type="button"
            onClick={openAddSecondaryLinkDrawer}
          >
            <IconButton size="sm" variant="fill-tertiary">
              <Plus />
            </IconButton>
            <div className={css.BuyingOptions__buying_option_name}>
              Add a buying option
            </div>
            {!showBuyingButtons && (
              <Chevron className={css.BuyingOptions__buying_option_chevron} />
            )}
          </button>
        )}
      </div>
    </div>
  )
}
