import { useState } from 'react'
import { CashFundPreferences, Registry } from 'src/types/registry'
import { useMutation, useQueryClient } from '@tanstack/react-query'
import { FETCH_REGISTRY_KEY, saveCashFundPreferences } from 'src/api/queries'
import { SaveCashFundPreferencesRequest } from 'src/routes/(registry)/api/types'
import { ErrorResponseBody } from 'src/api/types'

export interface CashFundPreferencesSaveCallbacks {
  onSuccess?: (cashFundPreferences: any) => void
  onError?: (error: any) => void
}

const initializeCashFundPreferences = (
  cashFundPreferences?: CashFundPreferences
): CashFundPreferences => ({
  direct: cashFundPreferences?.direct || false,
  paypalme: cashFundPreferences?.paypalme,
  paymentPreferences: cashFundPreferences?.paymentPreferences || [],
})

const useCashFundPreferences = (
  initialCashFundPreferences: CashFundPreferences | undefined,
  mapToRequestBody: (
    cashFundPreferences: CashFundPreferences
  ) => SaveCashFundPreferencesRequest,
  callbacks?: CashFundPreferencesSaveCallbacks,
  registry?: Registry
) => {
  const [showPreferenceRequired, setShowPreferenceRequired] = useState(false)
  const [cashFundPreferences, setCashFundPreferences] =
    useState<CashFundPreferences>(
      initializeCashFundPreferences(initialCashFundPreferences)
    )

  const [errors, setErrors] = useState<ErrorResponseBody | undefined>()

  const queryClient = useQueryClient()
  const handleSuccessfulSave = (newCashFundPreferences: any) => {
    if (registry) {
      queryClient.setQueryData(
        [FETCH_REGISTRY_KEY, registry.id],
        (previous: any) => ({
          ...previous,
          cashFundPreferences: newCashFundPreferences,
        })
      )
    }

    if (callbacks?.onSuccess) {
      callbacks?.onSuccess(newCashFundPreferences)
    }
  }

  const handleSaveError = (errorResponse: ErrorResponseBody) => {
    setErrors(errorResponse)
    if (callbacks?.onError) {
      callbacks.onError(errorResponse)
    }
  }

  const { mutate: saveCashFundPreferencesMutation, isLoading } = useMutation({
    mutationFn: (data: CashFundPreferences) =>
      saveCashFundPreferences(mapToRequestBody(data)),
    onError: handleSaveError,
    onSuccess: handleSuccessfulSave,
  })

  const onSave = () => {
    saveCashFundPreferencesMutation(cashFundPreferences)
  }

  return {
    cashFundPreferences,
    errors,
    isLoading,
    onSave,
    setCashFundPreferences,
    setErrors,
    setShowPreferenceRequired,
    showPreferenceRequired,
  }
}

export default useCashFundPreferences
