import fetch from 'lib/fetch'
import {
  apiV3CartPath,
  apiV3CartItemsPath,
  apiV3CheckoutPath,
  apiV3CartConvertToRegistryItemsPath,
} from 'lib/urls'
import { getRegCartItems, getStoreCartItems } from 'cart/lib'

export const createCheckout = (data) => {
  return fetch(apiV3CheckoutPath, {
    body: JSON.stringify(data),
    method: 'POST',
  })
}

export const getCart = () => {
  return fetch(apiV3CartPath)
}

export const getCartWithRecommendations = () => {
  return fetch(`${apiV3CartPath}?recommendations=true`)
}

export const removeCartItem = (cartItem) => {
  // Delete legacy cart items with a GTIN - Remove 1 week after Shopify p3 launch
  return fetch(`${apiV3CartItemsPath}/${cartItem.uuid || cartItem.productId}`, {
    method: 'DELETE',
  })
}

export const updateCart = (cart) => {
  return fetch(`${apiV3CartPath}`, {
    body: JSON.stringify({ cart }),
    method: 'PUT',
  })
}

export const updateCartItem = (cartItem) => {
  // Update legacy cart items with a GTIN - Remove 1 week after Shopify p3 launch
  return fetch(`${apiV3CartItemsPath}/${cartItem.uuid || cartItem.productId}`, {
    body: JSON.stringify({ cartItem }),
    method: 'PUT',
  })
}

export const convertToRegistryItems = ({
  name,
  email,
  subscribeToMarketingEmail,
  isSurprise,
}) => {
  const data = { name, email, subscribeToMarketingEmail, isSurprise }
  return fetch(apiV3CartConvertToRegistryItemsPath, {
    body: JSON.stringify(data),
    method: 'POST',
  })
}

export const submitCheckout = (cartItems) => {
  const regCartItems = getRegCartItems(cartItems)
  const storeCartItems = getStoreCartItems(cartItems)
  const data = {}

  if (storeCartItems.length > 0) {
    data.cart_items =
      regCartItems.length > 0
        ? storeCartItems.map((item) => ({ id: item.uuid, is_bonus: true }))
        : storeCartItems.map((item) => ({ id: item.uuid }))
  }

  if (regCartItems.length > 0) {
    data.reservations = regCartItems.map((res) => ({
      token: res.reservationToken,
    }))
  }

  return createCheckout(data)
}
