import ImageWithPlaceholderColor from 'components/image-with-placeholder-color'
import Pinnable from 'shared/pinnable'
import classnames from 'classnames'

interface FeaturedAssetProps {
  post: Post
  assetClasses: string
  minimalView: boolean
}

const FeaturedAsset: React.FC<FeaturedAssetProps> = ({
  post,
  assetClasses,
  minimalView,
}: FeaturedAssetProps) => {
  if (post.videoId) {
    return (
      <div className="breakout-right">
        <div
          className={classnames(
            'embed-responsive embed-responsive-16by9',
            assetClasses
          )}
        >
          <iframe
            allowFullScreen
            className="embed-responsive-item"
            src={`https://www.youtube.com/embed/${post.videoId}`}
            title="Babylist Video"
          />
        </div>
      </div>
    )
  }
  const image = (
    <ImageWithPlaceholderColor
      alt={post.featuredImage?.description}
      aspectHeight={2}
      aspectWidth={3}
      className=""
      src={post.featuredImage?.url}
    />
  )
  return (
    <div className={assetClasses}>
      {minimalView ? (
        <>{image}</>
      ) : (
        <Pinnable
          buttonSize="large"
          description={post.meta.socialDescription || ''}
          media={post.featuredImage?.url || ''}
          url={post.socialShareUrl || ''}
        >
          {image}
        </Pinnable>
      )}

      {post.featuredImage?.attribution ? (
        <div className="h7 mts post-featured-image-attribution">
          {`Photo by `}
          {post.featuredImage?.attributionUrl ? (
            <a
              href={post.featuredImage?.attributionUrl}
              rel="noreferrer"
              target="_blank"
            >
              {post.featuredImage?.attribution}
            </a>
          ) : (
            post.featuredImage?.attribution
          )}
        </div>
      ) : null}
    </div>
  )
}

export default FeaturedAsset
