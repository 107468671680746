import React from 'react'
import classNames from 'classnames'
import css from './PurchasesSectionHeader.styles.scss'
import { PurchasesSectionHeaderProps } from './PurchasesSectionHeader.types'

const DEFAULT_TITLE = 'Purchases'

const PurchasesSectionHeader: React.FC<PurchasesSectionHeaderProps> = ({
  hasReservations = false,
  icon: Icon,
  status,
  title = DEFAULT_TITLE,
}) => {
  const iconClasses = classNames({
    [css.PurchasesSectionHeader__Icon__Colored]: hasReservations,
  })

  const statusClasses = classNames(
    css.PurchasesSectionHeader__Text,
    css.PurchasesSectionHeader__Status
  )

  return (
    <div className={css.PurchasesSectionHeader}>
      {Icon && <Icon className={iconClasses} />}
      <span className={css.PurchasesSectionHeader__Text}>{title}</span>
      <p className={statusClasses}>{status}</p>
    </div>
  )
}

export default PurchasesSectionHeader
