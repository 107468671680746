import { ReactNode, CSSProperties } from 'react'
import classNames from 'classnames'
import css from './Carousel.styles.scss'

interface CarouselItemProps {
  children: ReactNode
  className?: string
  carouselWidth?: number
}

const CarouselItem: React.FC<CarouselItemProps> = ({
  children,
  className,
  carouselWidth,
}: CarouselItemProps) => (
  <li
    className={classNames(
      {
        [className || '']: Boolean(className),
      },
      css.Carousel__item
    )}
    style={
      {
        // This is variable because it is applied via breakpoints when the
        // viewport is wide enough to display as carousel (otherwise it should
        // remain full width)
        '--carousel-width': carouselWidth ? `${carouselWidth}px` : 'auto',
      } as CSSProperties
    }
  >
    {children}
  </li>
)

export default CarouselItem
