import { IneligibilityReason } from './IneligibilityReason'
import { EligibilityMetadata } from './InsuranceEligibilityValidator'

interface EligibilityCheckHandlerParams {
  onEligible: (metadata: NonNullable<EligibilityMetadata>) => void
  onIneligible: (reason: IneligibilityReason) => void
}

export function handleEligibilityCheck({
  onEligible,
  onIneligible,
}: EligibilityCheckHandlerParams) {
  return function validateEligibility(metadata: EligibilityMetadata) {
    const { state, insurance } = metadata

    if (state && !state.enabled) {
      onIneligible(IneligibilityReason.StateNotSupported)
    } else if (insurance && (!insurance.enabled || !insurance.eligible)) {
      onIneligible(IneligibilityReason.InsuranceNotSupported)
    } else if (state && insurance) {
      onEligible(metadata)
    }
  }
}
