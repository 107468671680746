import React from 'react'
// eslint-disable-next-line import/no-extraneous-dependencies
import Gift from 'baby-design/icons/Gift.svg'
import { ReservationBulletProps } from './ReservationBullet.types'
import css from './ReservationBullet.styles.scss'

const ReservationBullet: React.FC<ReservationBulletProps> = ({
  isSurprise,
  name,
  quantity,
}) => (
  <div className={css.PurchaseBullet}>
    <div className={css.PurchaseBullet__BulletContainer}>
      <div className={css.PurchaseBullet__BulletContainer__Top} />
      <div className={css.PurchaseBullet__BulletContainer__Bullet} />
      <div className={css.PurchaseBullet__BulletContainer__Bottom} />
    </div>
    <div className={css.PurchaseBullet__Content}>
      <div className={css.PurchaseBullet__ReservationDetails}>
        {isSurprise && (
          <div className={css.PurchaseBullet__ReservationDetails__Surprise}>
            <Gift
              filled
              className={css.PurchaseBullet__ReservationDetails__Surprise__Icon}
            />
            Surprise gift from
          </div>
        )}
        <p className={css.PurchaseBullet__ReservationDetails__GiftGiver}>
          {isSurprise ? 'Someone secret' : name}
        </p>
        {quantity && (
          <p className={css.PurchaseBullet__ReservationDetails__GiftQuantity}>
            {quantity}
          </p>
        )}
      </div>
    </div>
  </div>
)

export default ReservationBullet
