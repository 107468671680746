import classNames from 'classnames'
import css from 'registry/components/reg-item-card/guest-actions/guest-actions.scss'
import { InfoButton, LinkButton } from 'components/buttons'
import { PinkCheckmarkIcon } from 'components/icons'
import React from 'react'

interface OpenGiftTrackerActionProps {
  openGiftTrackerModal: () => void
  statusText: string
}

const OpenGiftTrackerAction: React.FC<OpenGiftTrackerActionProps> = ({
  openGiftTrackerModal,
  statusText,
}) => (
  <div
    className={classNames(
      css.guestAction,
      'h6 inline-block flex col justify-end'
    )}
  >
    <LinkButton
      className="text-bold link-info man pan"
      onClick={openGiftTrackerModal}
    >
      {/* @ts-ignore */}
      <PinkCheckmarkIcon /> <span>{statusText}</span>
    </LinkButton>
    <InfoButton
      empty
      pill
      className="block mtl"
      size="small"
      onClick={openGiftTrackerModal}
    >
      Edit Gift Details
    </InfoButton>
  </div>
)

export default OpenGiftTrackerAction
