import React from 'react'
import classnames from 'classnames'
import Link from 'components/link'
import {
  ELECTRONIC_GIFT_CARD_MIN,
  ELECTRONIC_GIFT_CARD_MAX,
} from 'shared/constants'
import { track, useTracking } from 'lib/analytics/track'
import {
  PRODUCT_SEARCH_INDEX_KEY,
  getSearchId,
} from 'shared/hooks/useSearchId/useSearchId'
import { getSearchPreviewIds } from 'components/global-nav/components/SearchBar/SearchBar.utils'
import { ProductCallout } from 'src/types/productCallout'
import { StarRating, Badge } from 'baby-design'

import PriceTag, {
  PriceTagProps,
} from 'baby-design/components/PriceTag/PriceTag'
import { getPriceTagPropsFromPriceDetails } from 'src/utils/productHelpers'
import PromoBadge, { PromoBadgeProps } from '../PromoBadge/PromoBadge'
import ProductImage, { Overlay } from '../ProductImage/ProductImage'
import css from './ProductCardV2.styles.scss'

interface Price {
  msrp?: number
  min?: number
  max?: number
  current?: number
}

interface ProductCardV2Props {
  productId: number
  brand?: string
  title: string
  imageUrl: string
  secondaryImageUrl?: string
  path: string
  slugSet?: string | null
  lazyLoad?: boolean
  fsaHsaEligible?: boolean
  isCashFund: boolean
  isCrowdfund: boolean
  isElectronicGiftCard: boolean
  isFavor: boolean
  isRegistryOwner?: boolean
  reviewCount?: number
  rating?: number | null
  optionsCta?: string
  position?: number
  price?: Price
  priceDetails?: any
  merchandisingMessage?: string
  promotionalBadges?: PromoBadgeProps[]
  callouts?: ProductCallout[]
  __forcePageLoad?: boolean
  actionButton?: React.ReactNode
  isMobile?: boolean
  onClick?: (e: MouseEvent) => void
}

const ProductCardV2: React.FC<ProductCardV2Props> = ({
  productId,
  brand,
  title,
  imageUrl,
  secondaryImageUrl,
  path,
  slugSet,
  fsaHsaEligible,
  // lazyLoad, // TODO - lazy load images
  isCashFund,
  isCrowdfund,
  isElectronicGiftCard,
  isFavor,
  isRegistryOwner,
  reviewCount,
  rating,
  optionsCta,
  position,
  price,
  priceDetails,
  merchandisingMessage,
  promotionalBadges,
  callouts,
  __forcePageLoad = false,
  actionButton,
  isMobile = false,
  onClick,
}) => {
  const tracker = useTracking()

  const productPrice = Number.isNaN(price?.min) ? undefined : price?.min
  const productType = isElectronicGiftCard
    ? track.ProductType.GIFT_CARD
    : track.ProductType.PRODUCT
  const searchId = getSearchId(PRODUCT_SEARCH_INDEX_KEY)
  const searchPreviewIds = getSearchPreviewIds()

  const trackProductClick = (eventType: string, eventLocation: string) => {
    const productSaleType =
      priceDetails?.saleAttributes?.saleType === 'active_sale'
        ? 'price_sale'
        : priceDetails?.saleAttributes?.saleType
    const payload = {
      event: track.productClicked,
      eventType,
      eventLocation,
      isRegistryOwner,
      isFsaHsaEligible: fsaHsaEligible,
      positionInList: position,
      productIds: [productId],
      productPrice,
      productType,
      searchId,
      searchPreviewIds,
      ...(productSaleType && { saleType: [productSaleType] }),
    }

    tracker.trackEvent(payload)
  }

  const priceTagProps = getPriceTagProps(
    price,
    priceDetails,
    isElectronicGiftCard,
    isMobile
  )

  const supportsRatings = () =>
    !isCashFund &&
    !isFavor &&
    !isElectronicGiftCard &&
    !isCrowdfund &&
    !!reviewCount

  const imageAlt = `${brand || ''} ${title}`
  const images = [
    {
      alt: imageAlt,
      src: imageUrl,
    },
  ]
  if (secondaryImageUrl) {
    images.push({
      alt: imageAlt,
      src: secondaryImageUrl,
    })
  }

  const overlays = getProductImageOverlays(promotionalBadges)

  const linkAction = __forcePageLoad
    ? { url: path }
    : { to: { pathname: path, state: { slugSet } } }

  return (
    <>
      <div className={classnames(css.ProductCardV2)}>
        <Link
          {...linkAction}
          data-testid="product-link"
          onClick={(e: MouseEvent) => {
            trackProductClick(
              track.EventType.CLICK,
              track.EventLocation.STORE_MINUS_PRODUCT_CARD
            )
            onClick?.(e)
          }}
        >
          <ProductImage images={images} overlays={overlays} />
          <div
            className={classnames(
              actionButton
                ? css.ProductCardV2__contentWrapperWithActions
                : css.ProductCardV2__contentWrapper
            )}
          >
            <h3 className="flex col unset-all">
              {brand && (
                <div
                  className={classnames(css.ProductCardV2__brand)}
                  data-testid="brand"
                >
                  {brand}
                </div>
              )}
              <div
                className={classnames(css.ProductCardV2__title)}
                data-testid="title"
              >
                {title}
              </div>
            </h3>
            {optionsCta && (
              <div
                className={classnames(css.ProductCardV2__optionsCta)}
                data-testid="optionsCta"
              >
                {optionsCta}
              </div>
            )}
            {supportsRatings() && (
              <StarRating
                className="mbs"
                rating={rating || 0}
                reviewCount={reviewCount || 0}
              />
            )}
            {priceTagProps && <PriceTag {...priceTagProps} />}
            {merchandisingMessage && (
              <div
                className={classnames(css.ProductCardV2__merchandisingMessage)}
              >
                {merchandisingMessage}
              </div>
            )}
            {callouts?.map((callout, index) => (
              <div key={`callout-${index}`}>
                <Badge
                  fill="theme-sand"
                  label={callout.text}
                  size="small"
                  variant="general"
                />
              </div>
            ))}
            {actionButton && (
              <div className={classnames(css.ProductCardV2__actions)}>
                {actionButton}
              </div>
            )}
          </div>
        </Link>
      </div>
    </>
  )
}

const getPriceTagProps = (
  price?: Price,
  priceDetails?: any,
  isElectronicGiftCard?: boolean,
  isMobile?: boolean
): PriceTagProps | null => {
  if (!price) return null

  if (isElectronicGiftCard) {
    return {
      minPrice: ELECTRONIC_GIFT_CARD_MIN,
      maxPrice: ELECTRONIC_GIFT_CARD_MAX,
      truncateZeroCents: true,
    }
  }

  return {
    ...getPriceTagPropsFromPriceDetails(priceDetails),
    ...(isMobile && { size: 'xs' }),
  }
}

const getProductImageOverlays = (
  promotionalBadges?: PromoBadgeProps[]
): Overlay[] =>
  promotionalBadges?.map((badge, index) => {
    const badgePosition = badge.type === 'text' ? 'top-left' : 'top-right'
    return {
      key: `badge-${badgePosition}-${index}`,
      position: badgePosition,
      hideOnHover: true,
      content: <PromoBadge {...badge} />,
    }
  }) || []

export default ProductCardV2
