import { Checkbox, TextInput, RadioButton } from 'baby-design'
import { useForm, FieldError } from 'react-hook-form'
import { useGiftGiverCookie } from 'lib/cookies'
import { email as isEmail } from 'lib/form-validation'
import css from './Form.styles.scss'

export interface FormSubmitData {
  email: string
  name: string
  subscribeToMarketingEmail: boolean
  isSurprise: string
}

interface FormProps {
  onSubmit: (values: FormSubmitData) => void
  id: string
}

const Form = ({ onSubmit, id: formId }: FormProps) => {
  const { name, email, subscribeToMarketingEmail } = useGiftGiverCookie()
  const defaultValues: FormSubmitData = {
    name: name ?? '',
    email: email ?? '',
    subscribeToMarketingEmail: subscribeToMarketingEmail ?? true,
    isSurprise: 'false',
  }
  const { errors, handleSubmit, register } = useForm({
    defaultValues,
  })

  const getStatus = (fieldError: FieldError | undefined) => {
    if (fieldError) {
      return { type: 'error' as 'error', message: fieldError.message as string }
    } else {
      return undefined
    }
  }

  return (
    <form id={formId} onSubmit={handleSubmit(onSubmit)}>
      <fieldset className={css.formRow}>
        <legend className={css.surpriseLabel}>
          Would you like to keep this gift a surprise?
        </legend>
        <div className={css.radioRow}>
          <label className={css.inlineInput}>
            <RadioButton
              size="md"
              inputProps={{
                name: 'isSurprise',
                value: 'true',
                ref: register,
              }}
            />
            <span>Yes</span>
          </label>
          <label className={css.inlineInput}>
            <RadioButton
              size="md"
              inputProps={{
                name: 'isSurprise',
                value: 'false',
                ref: register,
              }}
            />
            No
          </label>
        </div>
      </fieldset>
      <div className={css.formRow}>
        <TextInput
          label="Your first &amp; last name"
          size="md"
          status={getStatus(errors.name)}
          inputProps={{
            name: 'name',
            ref: register({ required: 'Enter your first and last name' }),
          }}
        />
      </div>
      <div className={css.formRow}>
        <TextInput
          label="Your email"
          size="md"
          status={getStatus(errors?.email)}
          inputProps={{
            name: 'email',
            ref: register({
              validate: isEmail,
            }),
          }}
        />
      </div>
      <div className={css.formRow}>
        <label className={css.inlineInput}>
          <Checkbox
            size="md"
            inputProps={{
              name: 'subscribeToMarketingEmail',
              ref: register,
            }}
          />
          <span>Send me gift ideas and deals from Babylist</span>
        </label>
      </div>
    </form>
  )
}

export default Form
