import React from 'react'
import classNames from 'classnames'
import { Image } from 'baby-design'
import { CONSOLIDATED_SHIPPING_COPY } from 'src/constants'
import { dataTestId } from '../../../../functions'
import css from './ExplainerImage.styles.scss'

const ExplainerImage: React.FC = () => {
  const imgAltText = `Visual representation of ${CONSOLIDATED_SHIPPING_COPY}`
  const desktopImageClasses = classNames(
    css.ExplainerImage,
    css['ExplainerImage--desktop']
  )
  const mobileImageClasses = classNames(
    css.ExplainerImage,
    css['ExplainerImage--mobile']
  )

  return (
    <>
      <div data-testid={dataTestId('image-desktop')}>
        <Image
          alt={imgAltText}
          className={desktopImageClasses}
          src="https://images.babylist.com/image/upload/v1726846597/consolidated-shipping/bundled_shipping_explainer_desktop.png"
        />
      </div>
      <div data-testid={dataTestId('image-mobile')}>
        <Image
          alt={imgAltText}
          className={mobileImageClasses}
          src="https://images.babylist.com/image/upload/v1726846597/consolidated-shipping/bundled_shipping_explainer_mobile.png"
        />
      </div>
    </>
  )
}

export default ExplainerImage
