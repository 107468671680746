import React from 'react'
import classNames from 'classnames'
import css from './Filter.styles.scss'

import { FilterProps } from './Filter.types'

const filterSizeClassname = (size: FilterProps['size']) => {
  switch (size) {
    case 'sm':
      return css.filter__small
    case 'md':
      return css.filter__medium
    case 'lg':
      return css.filter__large
    case 'xl':
      return css.filter__extralarge
    default:
      return ''
  }
}

const filterTypeClassname = (type: FilterProps['type']) => {
  switch (type) {
    case 'primary':
      return css.filter__primary
    case 'secondary':
      return css.filter__secondary
    default:
      return ''
  }
}

const filterVariantClassname = (variant: FilterProps['variant']) => {
  switch (variant) {
    case 'fill':
      return css.filter__fill
    case 'outline':
      return css.filter__outline
    default:
      return ''
  }
}

const Filter: React.FC<FilterProps> = ({
  LeftIcon = undefined,
  RightIcon = undefined,
  children,
  variant,
  type,
  size,
  onClick = () => undefined,
  overrideIconColor = true,
  disabled = false,
  className,
  isPressed = false,
}) => {
  const filterClasses = classNames(
    css.filter,
    className,
    filterSizeClassname(size),
    filterTypeClassname(type),
    filterVariantClassname(variant)
  )

  const leftIconClasses = classNames(
    css.left_icon,
    overrideIconColor && css.override_icon_color
  )

  const rightIconClasses = classNames(
    css.right_icon,
    overrideIconColor && css.override_icon_color
  )

  return (
    <button
      aria-pressed={isPressed}
      className={filterClasses}
      disabled={disabled}
      type="button"
      onClick={onClick}
    >
      {LeftIcon && (
        <div className={leftIconClasses} data-testid="filter-left-icon">
          {LeftIcon}
        </div>
      )}
      {children}
      {RightIcon && (
        <div className={rightIconClasses} data-testid="filter-right-icon">
          {RightIcon}
        </div>
      )}
    </button>
  )
}

export default Filter
