import { Switch, TextLink } from 'baby-design'
import css from './GroupGift.style.scss'
import { RegItem } from 'src/types/regItem'
import { CashFundIcon, Chevron, GroupGiftIcon } from 'baby-design/icons'
import { numberToCurrency } from 'lib/money'

interface EditItemDetailsGroupGiftSettingProps {
  regItemFields: RegItem
  handleGroupGiftChange: (checked: boolean) => void
  disabled?: boolean
  openEditGroupGiftModal?: () => void
}

export const EditItemDetailsGroupGiftSetting = ({
  regItemFields,
  handleGroupGiftChange,
  disabled = false,
  openEditGroupGiftModal = () => {},
}: EditItemDetailsGroupGiftSettingProps) => {
  const { isGroupGift, groupGiftingDisabledMessage } = regItemFields

  return (
    <div className={css.group_gift}>
      <div className={css.group_gift__main}>
        <div className={css.group_gift__label} id="groupGiftLabel">
          <div className={css.group_gift__heading}>Group Gift</div>
          <div className={css.group_gift__subheading}>
            {(disabled && groupGiftingDisabledMessage) ||
              'Let friends and family contribute any amount towards this gift.'}
          </div>
        </div>
        <Switch
          aria-labelledby="groupGiftLabel"
          checked={isGroupGift}
          disabled={disabled}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
            handleGroupGiftChange(e.target.checked)
          }
        />
      </div>
      {regItemFields.isGroupGift && (
        <>
          <div className={css.group_gift__controls}>
            <CashFundIcon className={css.group_gift__controls__icon} />
            <div className={css.group_gift__controls_heading}>
              Funded Amount
            </div>
            <TextLink
              variant="default"
              size="md"
              weight="regular"
              icon={{
                side: 'right',
                icon: <Chevron className={css.group_gift__right_chevron} />,
              }}
              onClick={() => openEditGroupGiftModal()}
            >
              <div className={css.group_gift__controls_price_text}>
                {`${numberToCurrency(
                  regItemFields.fundedAmount
                )} of ${numberToCurrency(regItemFields.goalAmount)}`}
              </div>
            </TextLink>
          </div>
          <div className={css.group_gift__controls}>
            <GroupGiftIcon className={css.group_gift__controls__icon} />
            <div className={css.group_gift__controls_heading}>
              Group Gift Funds
            </div>
            <TextLink
              variant="default"
              size="md"
              weight="regular"
              icon={{
                side: 'right',
                icon: <Chevron className={css.group_gift__right_chevron} />,
              }}
              onClick={() =>
                window.open('/settings/gift-cards-and-promos', '_blank')
              }
            >
              <div className={css.group_gift__controls_manage_text}>Manage</div>
            </TextLink>
          </div>
        </>
      )}
    </div>
  )
}
