interface EmbedPrerenderedProps {
  serverData?: ServerData
  id: string
}

const EmbedPrerendered: React.FC<EmbedPrerenderedProps> = ({
  serverData,
  id,
}: EmbedPrerenderedProps) => {
  if (!serverData?.embeddedEntries) {
    return null
  }

  const item = serverData.embeddedEntries.find((i) => i.id === id)
  if (item) {
    // eslint-disable-next-line react/no-danger
    return <div dangerouslySetInnerHTML={{ __html: item.html }} />
  }
  return null
}

export default EmbedPrerendered
