// @ts-ignore
import { AutoAffix } from 'react-overlays'
import RenderOnScroll from 'shared/render-on-scroll'
import ShareButtons from '../../../../../shared/share-buttons/share-buttons'

interface PostSocialButtonProps {
  container: () => unknown
  description?: string
  media?: string
  url: string
}

const PostSocialButtons = ({
  container,
  description,
  media,
  url,
}: PostSocialButtonProps) => (
  <RenderOnScroll
    rehide
    containerRef={container}
    offsetClasses={['navbar-default', 'navbar-secondary']}
    triggerY={25}
    unmountDelay={300}
  >
    <AutoAffix
      affixClassName="affixed"
      bottomClassName="bottom"
      className="bas"
      container={container}
      viewportOffsetTop={60}
    >
      <div className="fadeIn posts-sidebar-wrapper">
        <div className="pan prxl mtn">
          <ShareButtons
            className="hidden-xs text-right article-side-share pln"
            description={description}
            media={media}
            shareText="Share"
            url={url}
          />
        </div>
      </div>
    </AutoAffix>
  </RenderOnScroll>
)

export default PostSocialButtons
