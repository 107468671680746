import { Component } from 'react'
import PropTypes from 'prop-types'
import { AutoAffix } from 'react-overlays'
import classNames from 'classnames'
import withCurrentUser from 'shared/HOC/withCurrentUser'
import { ProgressBar } from 'components/progress-bar'
import { getScrollTracker } from 'shared/scroll-tracker'
import css from './sticky-header.scss'

class StickyHeader extends Component {
  static propTypes = {
    hideTitle: PropTypes.bool,
    title: PropTypes.string.isRequired,
    leftActionComponent: PropTypes.object,
    rightActionComponent: PropTypes.object,
    triggerY: PropTypes.number,
    children: PropTypes.oneOfType([PropTypes.object, PropTypes.array])
      .isRequired,
    currentUser: PropTypes.object,
  }

  static defaultProps = { triggerY: 75, hideTitle: false }

  scrollTracker = null

  state = { percentScrolled: 0 }

  componentDidMount() {
    window.addEventListener('scroll', this.onScroll, false)
    this.scrollTracker = getScrollTracker('navbar-default', 'navbar-secondary')
  }

  componentWillUnmount() {
    this.scrollTracker.resetScrollArea()
    window.removeEventListener('scroll', this.onScroll, false)
  }

  onScroll = () => {
    this.setState({
      percentScrolled: this.scrollTracker.getPercentScrolled(
        this.childContentContainer
      ),
    })
  }

  scrollToTop() {
    window.scrollTo(0, 0)
  }

  render() {
    const {
      children,
      className,
      currentUser,
      title,
      leftActionComponent,
      rightActionComponent,
      hideTitle,
    } = this.props
    const { percentScrolled } = this.state

    const triggerY = (this.props.triggerY || 400) * -1

    const utilityBarEnabled =
      typeof window !== 'undefined' &&
      !window.location.href.includes('embedded') &&
      !currentUser

    return (
      <>
        <AutoAffix
          affixClassName={classNames(
            css.affixed,
            className,
            utilityBarEnabled && css.utilityBarEnabled
          )}
          viewportOffsetTop={triggerY}
        >
          <div className={css.stickyHeader}>
            <div
              className={classNames(css.container, hideTitle && css.hideTitle)}
            >
              {!hideTitle && (
                <div className={css.header}>
                  <div className={css.controlWrapper}>
                    {leftActionComponent}
                  </div>

                  <div
                    className={css.titleContainer}
                    onClick={this.scrollToTop.bind(this)}
                  >
                    <div className={classNames('h4 bold', css.title)}>
                      {title}
                    </div>
                  </div>

                  <div className={css.controlWrapper}>
                    {rightActionComponent}
                  </div>
                </div>
              )}
              <ProgressBar
                color={css.progressBarColor}
                height={css.progressBarHeight}
                percent={percentScrolled}
              />
            </div>
          </div>
        </AutoAffix>
        <div ref={(el) => (this.childContentContainer = el)}>{children}</div>
      </>
    )
  }
}

export default withCurrentUser(StickyHeader)
