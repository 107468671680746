import classNames from 'classnames'
import { InfoButton } from 'components/buttons'
import { FAVORITE_COPY } from 'registry/constants'

const FavoriteFilters = ({ addFilter, className, label, selected }) => (
  <>
    {label && (
      <>
        <hr className="mbl" />
        <div className="h6 text-uppercase text-bold text-light tiny mtn mbs">
          {label}
        </div>
      </>
    )}

    <InfoButton
      pill
      className={classNames(className)}
      empty={!selected}
      selected={!!selected}
      size="small"
      onClick={() => addFilter({ favoritesView: !selected })}
    >
      {FAVORITE_COPY}
    </InfoButton>
  </>
)

export default FavoriteFilters
