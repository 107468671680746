import React from 'react'

interface BagProps {
  className?: string
}

const Bag: React.FC<BagProps> = ({ className }) => (
  <svg
    className={className}
    fill="none"
    height="64"
    viewBox="0 0 64 64"
    width="64"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      clipRule="evenodd"
      d="M11.0474 47.1066C11.0595 53.6249 16.35 58.9033 22.8683 58.9033H41.2046C47.7321 58.9033 53.0256 53.6098 53.0256 47.0823V10.5735C53.0256 7.55382 50.5547 5.08594 47.538 5.08594H43.4967C40.477 5.08594 38.0091 7.55685 38.0091 10.5735V19.1383H25.985V10.0763C25.985 7.3325 23.7415 5.08897 20.9977 5.08897H16.4773C13.4516 5.08897 10.9746 7.56594 10.9746 10.5947L11.0474 47.1066ZM47.538 10.3514L48.0777 19.1383H48.0019L43.1597 18.6803V10.0763L47.538 10.3514ZM20.5221 10.0763L21.1463 19.1383H21.1432L21.1022 19.376H15.9461C15.9291 19.376 15.9121 19.3762 15.895 19.3765L16.2508 10.3514L20.5221 10.0763Z"
      fill="url(#paint0_linear_2352_5632)"
      fillRule="evenodd"
    />
    <path
      d="M38.3098 29.1816C43.382 29.1816 47.4931 33.6748 47.4931 39.2199C47.4931 44.7651 43.382 49.2612 38.3098 49.2612C33.2376 49.2612 29.1265 44.7681 29.1265 39.2199C29.1265 33.6717 33.2376 29.1816 38.3098 29.1816ZM42.2996 36.0123C42.2996 35.3786 41.8297 34.8663 41.2506 34.8663C40.6715 34.8663 40.2016 35.3786 40.2016 36.0123C40.2016 36.6459 40.6715 37.1613 41.2506 37.1613C41.8297 37.1613 42.2996 36.6489 42.2996 36.0123ZM36.4179 36.0123C36.4179 35.3786 35.948 34.8663 35.3689 34.8663C34.7899 34.8663 34.3199 35.3786 34.3199 36.0123C34.3199 36.6459 34.7899 37.1613 35.3689 37.1613C35.948 37.1613 36.4179 36.6489 36.4179 36.0123Z"
      fill="url(#paint1_linear_2352_5632)"
    />
    <path
      d="M41.2551 37.1698C41.8351 37.1698 42.3051 36.6498 42.3051 36.0198C42.3051 35.3898 41.8351 34.8698 41.2551 34.8698C40.6751 34.8698 40.2051 35.3798 40.2051 36.0198C40.2051 36.6598 40.6751 37.1698 41.2551 37.1698Z"
      fill="#6E3264"
    />
    <path
      d="M35.3751 37.1698C35.9551 37.1698 36.4251 36.6498 36.4251 36.0198C36.4251 35.3898 35.9551 34.8698 35.3751 34.8698C34.7951 34.8698 34.3251 35.3798 34.3251 36.0198C34.3251 36.6598 34.7951 37.1698 35.3751 37.1698Z"
      fill="#6E3264"
    />
    <path
      d="M47.9251 4.08984H43.1051C39.7451 4.08984 37.0051 6.81984 37.0051 10.1898V18.1398H26.9851V10.1898C26.9851 6.82984 24.2551 4.09984 20.8951 4.09984H16.4851C12.8951 4.08984 9.9751 7.00984 9.9751 10.5998L10.0451 46.8898C10.0551 54.0698 15.9151 59.9098 23.0951 59.9098H40.9851C48.1751 59.9098 54.0251 54.0598 54.0251 46.8598V10.1898C54.0251 6.82984 51.2851 4.08984 47.9251 4.08984ZM52.0251 46.8598C52.0251 52.9498 47.0651 57.9098 40.9851 57.9098H23.0951C17.0151 57.9098 12.0651 52.9698 12.0451 46.8898L11.9751 10.5998C11.9751 8.11984 13.9951 6.08984 16.4751 6.08984H20.8851C23.1451 6.08984 24.9751 7.92984 24.9751 10.1798V21.3398C24.9751 22.1598 25.2951 22.9198 25.8751 23.4998C26.4451 24.0698 27.2051 24.3798 28.0151 24.3798H28.0351L46.1051 24.2698C46.9251 24.2698 47.6851 23.9398 48.2651 23.3598C48.8351 22.7798 49.1451 22.0098 49.1351 21.1898L49.0951 17.8498C49.0951 17.8498 49.0851 17.7998 49.0751 17.7798V11.9298C49.0751 11.9298 49.0751 11.8698 49.0751 11.8398C49.0251 10.2398 47.7051 8.94984 46.0851 8.94984H44.7451C43.0951 8.94984 41.7551 10.2898 41.7551 11.9398V17.2598C41.7551 18.8598 43.0551 20.1498 44.6451 20.1498H47.1251V21.2298C47.1451 21.5098 47.0351 21.7698 46.8451 21.9698C46.6551 22.1698 46.3851 22.2798 46.1051 22.2798L28.0351 22.3898C27.7451 22.4398 27.4951 22.2798 27.2951 22.0898C27.0951 21.8898 26.9851 21.6298 26.9851 21.3498V20.1398H39.0051V10.1898C39.0051 7.92984 40.8451 6.08984 43.1051 6.08984H47.9251C50.1851 6.08984 52.0251 7.92984 52.0251 10.1898V46.8598ZM47.0851 12.0098V18.1498H44.6451C44.1551 18.1498 43.7551 17.7498 43.7551 17.2598V11.9398C43.7551 11.3898 44.2051 10.9498 44.7451 10.9498H46.0851C46.6351 10.9498 47.0751 11.3998 47.0751 11.9398C47.0751 11.9698 47.0751 11.9898 47.0751 12.0198L47.0851 12.0098Z"
      fill="#6E3264"
    />
    <path
      d="M22.1451 20.1398V11.9298C22.1451 10.3098 20.7751 8.93984 19.1551 8.93984H17.8051C16.1851 8.93984 14.8151 10.3098 14.8151 11.9298V21.2998C14.8151 22.2898 15.0851 23.0598 15.6251 23.5898C16.1751 24.1398 16.9651 24.3998 18.0251 24.3998C18.0451 24.3998 18.0751 24.3998 18.0951 24.3998H19.0851C20.7551 24.3998 22.1251 23.0398 22.1251 21.3698V20.1398H22.1451ZM16.8151 11.9298C16.8151 11.3998 17.2751 10.9398 17.8051 10.9398H19.1551C19.6851 10.9398 20.1451 11.3998 20.1451 11.9298V17.7998C20.1451 17.7998 20.1451 17.7998 20.1451 17.8098V18.1298H16.8151V11.9198V11.9298ZM19.0951 22.3998H18.0951C17.5451 22.4198 17.1951 22.3198 17.0351 22.1698C16.8551 21.9898 16.8151 21.5998 16.8151 21.2998V20.1398H20.1351V21.3598C20.1351 21.9298 19.6651 22.3998 19.0951 22.3998Z"
      fill="#6E3264"
    />
    <path
      d="M38.3151 28.1798C32.6951 28.1798 28.1351 33.1298 28.1351 39.2198C28.1351 45.3098 32.7051 50.2598 38.3151 50.2598C43.9251 50.2598 48.4951 45.3098 48.4951 39.2198C48.4951 33.1298 43.9251 28.1798 38.3151 28.1798ZM38.3151 48.2598C33.8051 48.2598 30.1351 44.1998 30.1351 39.2198C30.1351 34.2398 33.8051 30.1798 38.3151 30.1798C42.8251 30.1798 46.4951 34.2298 46.4951 39.2198C46.4951 44.2098 42.8251 48.2598 38.3151 48.2598Z"
      fill="#6E3264"
    />
    <path
      d="M42.6851 39.2298C42.6851 40.5298 42.2051 41.7898 41.3651 42.7098C40.5451 43.6098 39.4651 44.1098 38.3151 44.1098C37.1651 44.1098 36.0851 43.6098 35.2551 42.7098C34.4251 41.7998 33.9451 40.5298 33.9351 39.2298C33.9351 38.6798 33.4651 38.2798 32.9351 38.2298C32.3851 38.2298 31.9351 38.6798 31.9351 39.2298C31.9351 41.0498 32.5951 42.7598 33.7751 44.0598C34.9651 45.3598 36.6151 46.1098 38.3151 46.1098C40.0051 46.1098 41.6551 45.3598 42.8451 44.0598C44.0351 42.7598 44.6851 41.0498 44.6851 39.2298C44.6851 38.6798 44.2351 38.2298 43.6851 38.2298C43.1351 38.2298 42.6851 38.6798 42.6851 39.2298Z"
      fill="#6E3264"
    />
    <path
      d="M22.3651 29.1798C21.8151 29.1798 21.3651 29.6298 21.3651 30.1798V46.3998C21.3651 46.9498 21.8151 47.3998 22.3651 47.3998C22.9151 47.3998 23.3651 46.9498 23.3651 46.3998V30.1798C23.3651 29.6298 22.9151 29.1798 22.3651 29.1798Z"
      fill="#6E3264"
    />
    <defs>
      <linearGradient
        gradientUnits="userSpaceOnUse"
        id="paint0_linear_2352_5632"
        x1="18.3817"
        x2="18.4812"
        y1="34.8085"
        y2="19.376"
      >
        <stop stopColor="#A0DCFF" />
        <stop offset="0.7359" stopColor="#56BB6C" />
      </linearGradient>
      <linearGradient
        gradientUnits="userSpaceOnUse"
        id="paint1_linear_2352_5632"
        x1="38.3098"
        x2="38.3098"
        y1="49.2611"
        y2="27.426"
      >
        <stop offset="0.239141" stopColor="#FFD43E" />
        <stop offset="1" stopColor="#FF6D4E" />
      </linearGradient>
    </defs>
  </svg>
)

export default Bag
