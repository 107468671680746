import { createContext, Dispatch, SetStateAction } from 'react'
import { RegItemCategory } from 'src/types/regItemCategory'

interface Category {
  value?: number
  label?: string
}

export interface RegistryContextType {
  activeCategory: Category
  setActiveCategory: Dispatch<SetStateAction<Category>>
  handleCategoryUpdated: (category: RegItemCategory) => void
  registryId?: number
  registryOwnerId?: number
  isRegistryOwner: boolean
}

const RegistryContext = createContext<RegistryContextType>({
  activeCategory: {
    value: undefined,
    label: undefined,
  },
  handleCategoryUpdated: () => {},
  setActiveCategory: () => {},
  isRegistryOwner: false,
} as RegistryContextType)

export default RegistryContext
