import React from 'react'
import { PurchaseBulletProps } from 'src/routes/(registry)/list/[slug]/components/EditRegItemDrawer/components/PurchasesSection/components/PurchaseBullet/PurchaseBullet.types'
import { GiftDetailsDrawer } from 'src/routes/(registry)/list/[slug]/components/EditRegItemDrawer/components/GiftDetailsDrawer/GiftDetailsDrawer'
import NiceModal from '@ebay/nice-modal-react'
import ReservationBullet from '../../../ReservationBullet/ReservationBullet'

const PurchaseBullet: React.FC<PurchaseBulletProps> = ({
  regItem,
  reservation,
  totalReservationQuantity,
}) => {
  const handleOpenGiftDetails = () => {
    NiceModal.show(GiftDetailsDrawer)
  }

  let quantityString: string | undefined
  if (totalReservationQuantity !== 1 || regItem.quantity !== 1) {
    quantityString = `Qty ${reservation.quantity}`
  }

  return (
    <ReservationBullet
      isSurprise={
        reservation.isSurprise && !reservation.isSurpriseRevealedForUser
      }
      name={reservation.name}
      quantity={quantityString}
      onViewGift={handleOpenGiftDetails}
    />
  )
}

export default PurchaseBullet
