import useFeatureFlag from 'shared/hooks/useFeatureFlag/useFeatureFlag'

const useSimpleShippingByDefault = () => {
  const { flagValue } = useFeatureFlag(
    '24e_ff_regex_simple_shipping_by_default',
    false
  )

  return flagValue
}

export default useSimpleShippingByDefault
