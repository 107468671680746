import { useState, useEffect, useRef } from 'react'
import PropTypes from 'prop-types'
import { getScrollTracker } from './scroll-tracker'
import delayUnmount from './delayUnmount'

const RenderOnScroll = ({
  children,
  triggerY,
  containerRef,
  offsetClasses,
  percent,
  rehide,
  unmountDelay,
}) => {
  const scrollTrackerRef = useRef(null)
  const [showChildren, setShowChildren] = useState(false)
  const shouldRender = delayUnmount(showChildren, unmountDelay)

  useEffect(() => {
    const scrollProgress = () => {
      if (percent) {
        return scrollTrackerRef.current.getPercentScrolled(containerRef())
      }
      return scrollTrackerRef.current.getDistanceScrolled()
    }

    const onScroll = () => {
      const belowTrigger = scrollProgress() > triggerY
      if (belowTrigger && !showChildren) {
        setShowChildren(true)
      } else if (rehide && !belowTrigger && showChildren) {
        setShowChildren(false)
      }
    }

    window.addEventListener('scroll', onScroll, false)
    scrollTrackerRef.current = getScrollTracker(...offsetClasses)

    return () => {
      scrollTrackerRef.current.resetScrollArea()
      window.removeEventListener('scroll', onScroll, false)
    }
  }, [showChildren])

  return shouldRender ? (
    <div
      style={{
        opacity: showChildren ? 1 : 0,
        transition: `opacity ${unmountDelay}ms ease-in`,
      }}
    >
      {children}
    </div>
  ) : null
}

export default RenderOnScroll

RenderOnScroll.propTypes = {
  children: PropTypes.oneOfType([PropTypes.array, PropTypes.object]).isRequired,
  triggerY: PropTypes.number,
  containerRef: PropTypes.func,
  offsetClasses: PropTypes.arrayOf(PropTypes.string).isRequired,
  percent: PropTypes.bool,
  rehide: PropTypes.bool,
  unmountDelay: PropTypes.number,
}

RenderOnScroll.defaultProps = {
  containerRef: null,
  triggerY: 25,
  percent: true,
  rehide: false,
  unmountDelay: 0,
}
