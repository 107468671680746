export const purchasesStatus = (
  currentQuantity: number,
  totalQuantity?: number | null
): string => {
  const optionalTotalQuantity = totalQuantity
    ? `of ${totalQuantity}`
    : undefined

  const optionalEmoji =
    totalQuantity && currentQuantity >= totalQuantity ? '🎉' : undefined

  return [currentQuantity, optionalTotalQuantity, optionalEmoji]
    .filter((s) => typeof s === 'number' || !!s)
    .join(' ')
}
