import React from 'react'
import classNames from 'classnames'
import css from './Tag.styles.scss'

import { TagProps } from './Tag.types'

const tagSizeClassname = (size: TagProps['size']) => {
  switch (size) {
    case 'sm':
      return css.tag__small
    case 'md':
      return css.tag__medium
    case 'lg':
      return css.tag__large
    default:
      return css.tag__medium
  }
}

const tagVariantClassname = (
  variant: TagProps['variant'],
  type: TagProps['type']
) => {
  switch (variant) {
    case 'normal':
      return type === 'primary'
        ? css.tag__normal_primary
        : css.tag__normal_secondary
    case 'special':
      return type === 'primary'
        ? css.tag__special_primary
        : css.tag__special_secondary
    default:
      return type === 'primary'
        ? css.tag__normal_primary
        : css.tag__normal_secondary
  }
}

const Tag: React.FC<TagProps> = ({
  LeftIcon = undefined,
  RightIcon = undefined,
  children,
  variant,
  size,
  onClick,
  disabled = false,
  type = 'primary',
  className,
}) => {
  const tagClasses = classNames(
    css.tag,
    className,
    tagSizeClassname(size),
    tagVariantClassname(variant, type)
  )

  return (
    <button
      className={tagClasses}
      disabled={disabled}
      type="button"
      onClick={onClick}
    >
      {LeftIcon && (
        <div className={css.left_icon} data-testid="tag-left-icon">
          {LeftIcon}
        </div>
      )}
      <div className={css.tag__label}>{children}</div>
      {RightIcon && (
        <div className={css.right_icon} data-testid="tag-right-icon">
          {RightIcon}
        </div>
      )}
    </button>
  )
}

export default Tag
