import React from 'react'
import classNames from 'classnames'
import { TextLink } from 'baby-design'
import { shippingConsolidationHelpPath } from 'lib/urls'
import css from './consolidated-shipping-subtext.styles.scss'

interface ConsolidatedShippingSubtextProps {
  centered?: boolean
}

const ConsolidatedShippingSubtext: React.FC<
  ConsolidatedShippingSubtextProps
> = (props = {}) => {
  const { centered } = props
  const subtextClasses = classNames(css.ConsolidatedShippingSubtext, {
    [css['ConsolidatedShippingSubtext--centered']]: centered,
  })

  return (
    <div className={subtextClasses}>
      <span>* Exclusions apply. </span>
      <TextLink
        underline
        href={shippingConsolidationHelpPath}
        innerContentClassName={css.ConsolidatedShippingSubtext__link}
        rel="noreferrer"
        size="sm"
        target="_blank"
      >
        Learn more
      </TextLink>
    </div>
  )
}

export default ConsolidatedShippingSubtext
