import { Chevron, RightArrow } from 'baby-design/icons'
import classnames from 'classnames'
import { Button, Select } from 'baby-design'
import useInsuranceEligibilityValidator, {
  InsuranceEligibilityValidatorHookParams,
} from './useInsuranceEligibilityValidator'
import css from './InsuranceEligibilityValidator.styles.scss'
import { InsuranceEligibilityValidatorToolVariant } from '../InsuranceEligibilityValidatorTool'

// Workaround for the type restriction until the type is fixed in `SelectInputCustomProps`
const ChevronIcon = () => <Chevron />

interface InsuranceEligibilityValidatorProps
  extends InsuranceEligibilityValidatorHookParams {
  variant: InsuranceEligibilityValidatorToolVariant
}

export default function InsuranceEligibilityValidator({
  onEligibilityCheck,
  variant,
}: InsuranceEligibilityValidatorProps) {
  const {
    errors,
    insuranceOptions,
    onInsuranceChange,
    onStateChange,
    onSubmit,
    selectedInsurance,
    selectedState,
    stateOptions,
  } = useInsuranceEligibilityValidator({ onEligibilityCheck, variant })

  return (
    <div
      className={css.InsuranceEligibilityValidator}
      data-testid="insurance-eligibility-validator"
    >
      <div className={css.InsuranceEligibilityValidator__select_wrapper}>
        <div className={css.InsuranceEligibilityValidator__select_field}>
          <Select
            RightIcon={ChevronIcon}
            label="Shipping state"
            options={stateOptions}
            placeholder="Select state"
            size="md"
            status={
              errors.state
                ? { type: 'error', message: errors.state }
                : undefined
            }
            type="inverted"
            value={selectedState}
            onChange={onStateChange}
          />
        </div>

        <div
          className={classnames(
            css.InsuranceEligibilityValidator__select_field,
            css.InsuranceEligibilityValidator__select_field__insurance
          )}
        >
          {insuranceOptions.length ? (
            <Select
              RightIcon={ChevronIcon}
              label="Insurance carrier"
              options={insuranceOptions}
              placeholder="Select carrier"
              size="md"
              status={
                errors.insurance
                  ? { type: 'error', message: errors.insurance }
                  : undefined
              }
              type="inverted"
              value={selectedInsurance}
              onChange={onInsuranceChange}
            />
          ) : null}
        </div>
      </div>

      <Button
        block
        icon={{ side: 'right', icon: <RightArrow /> }}
        size="md"
        variant="primary"
        onClick={onSubmit}
      >
        Check your eligibility
      </Button>
    </div>
  )
}
