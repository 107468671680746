// eslint-disable-next-line import/no-extraneous-dependencies
import React from 'react'
import classNames from 'classnames'
import { Button } from 'baby-design'
import ScrollLockModal from 'shared/ScrollLockModal'
import { MarkPurchasedModalProps } from 'src/routes/(registry)/list/[slug]/components/EditRegItemDrawer/components/MarkPurchasedModal/MarkPurchasedModal.types'
import PurchaseOptionRequiredCallout from 'src/routes/(registry)/list/[slug]/components/EditRegItemDrawer/components/RegItemPurchaseModal/components/PurchaseOptionRequiredCallout/PurchaseOptionRequiredCallout'
import css from './MarkPurchasedModal.styles.scss'

const MarkPurchasedModal: React.FC<MarkPurchasedModalProps> = ({
  children,
  errorCallout,
  isLoading,
  isOpen,
  onCloseModal,
  onSubmit,
  subtitle,
  title,
}) => {
  const primaryButton = (
    <div className={css.MarkPurchasedModal__ButtonContainer}>
      <Button
        className={classNames(css.MarkPurchasedModal__Button, css.HiddenMobile)}
        data-testid="desktop-save-button"
        loading={isLoading}
        size="lg"
        variant="primary"
        onClick={onSubmit}
      >
        Save
      </Button>
      <Button
        className={classNames(css.MarkPurchasedModal__Button, css.MobileOnly)}
        data-testid="mobile-save-button"
        loading={isLoading}
        size="md"
        variant="primary"
        onClick={onSubmit}
      >
        Save
      </Button>
    </div>
  )

  return (
    <ScrollLockModal
      showCloseButton
      handleClose={onCloseModal}
      isOpen={isOpen}
      primaryButton={primaryButton}
    >
      {errorCallout && <PurchaseOptionRequiredCallout text={errorCallout} />}
      <div className={css.MarkPurchasedModal}>
        <div>
          <h1>{title}</h1>
          <p className={css.MarkPurchasedModal__Subtitle}>{subtitle}</p>
        </div>
        <div className={css.MarkPurchasedModal__FormFields}>{children}</div>
      </div>
    </ScrollLockModal>
  )
}

export default MarkPurchasedModal
