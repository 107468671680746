import React, { useState } from 'react'
import classNames from 'classnames'
import { Body, CloseButton } from './components'
import css from './Callout.styles.scss'
import { CalloutProps } from './Callout.types'

const Callout: React.FC<CalloutProps> = ({
  backgroundColor = 'white',
  cta,
  className,
  icon,
  iconColor = 'grey',
  onClose,
  showCloseButton = false,
  text,
  title,
  variant = 'single-line',
}) => {
  const [isOpen, setIsOpen] = useState(true)
  const calloutClasses = classNames(
    css.Callout,
    className,
    css[`Callout--background-${backgroundColor}`]
  )
  const iconClasses = classNames(css.Callout__icon, {
    [css[`Callout__icon--${iconColor}`]]: !!icon,
  })

  const handleClose = () => {
    onClose?.()
    setIsOpen(false)
  }

  if (!isOpen) return null

  return (
    <div className={calloutClasses} data-testid="callout">
      {showCloseButton && <CloseButton handleClose={handleClose} />}
      <div className={css.Callout__innerContainer}>
        {icon && (
          <div className={iconClasses} data-testid="callout-icon">
            {icon}
          </div>
        )}
        <Body cta={cta} text={text} title={title} variant={variant} />
      </div>
    </div>
  )
}

export default Callout
