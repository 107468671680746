import { Block, Text } from '@contentful/rich-text-types'
import { ReactNode } from 'react'
import { AnchoredH2 } from '../Embeds/Utilities'

export interface Heading2Props {
  block: Block
  children: ReactNode
}

const Heading2: React.FC<Heading2Props> = ({
  block,
  children,
}: Heading2Props) => {
  const { content } = block

  return (
    <AnchoredH2 textForAnchor={(content[0] as Text)?.value}>
      {children}
    </AnchoredH2>
  )
}

export default Heading2
