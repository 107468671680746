// TODO: create an endpoint to pull this info from the back end
// for now, we just display this constant list of options
export const PAYMENT_METHOD_OPTIONS = [
  {
    label: '',
    value: '',
  },
  {
    label: 'Venmo',
    value: 'venmo',
  },
  {
    label: 'PayPal',
    value: 'paypal',
  },
  {
    label: 'Cash or check',
    value: 'cash',
  },
  {
    label: 'Other',
    value: null,
  },
]
