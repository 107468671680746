import React from 'react'
import classNames from 'classnames'

import css from './CircularProgressBar.styles.scss'

import { CircularProgressBarProps } from './CircularProgressBar.types'

const CircularProgressBar: React.FC<CircularProgressBarProps> = ({
  value,
  min = 0,
  max = 100,
  ariaLabelledBy,
  ariaLabel,
  className,
}) => {
  const CircularProgressBarClasses = classNames(
    css.circular_progress_bar,
    className
  )

  const valuenow = Math.min(Math.max(value, min), max)

  const radius = 115
  const circumference = radius * 2 * 3.14

  const currentProgress = valuenow / max
  const [fill, empty] = [
    circumference * currentProgress,
    circumference * (1 - currentProgress),
  ]

  return (
    <svg
      aria-label={ariaLabel}
      aria-labelledby={ariaLabelledBy}
      aria-valuemax={max}
      aria-valuemin={min}
      aria-valuenow={valuenow}
      className={CircularProgressBarClasses}
      height="250"
      role="progressbar"
      viewBox="0 0 250 250"
      width="250"
    >
      <circle
        className={css.circular_progress_bar__background}
        cx="125"
        cy="125"
        fill="none"
        r={radius}
        strokeWidth="15"
      />
      <circle
        className={classNames(
          css.circular_progress_bar__bar,
          valuenow === min && css.circular_progress_bar__hide
        )}
        cx="125"
        cy="125"
        fill="none"
        r={radius}
        strokeDasharray={`${fill} ${empty}`}
        strokeWidth="20"
      />
    </svg>
  )
}

export default CircularProgressBar
