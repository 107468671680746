import { useState } from 'react'
import { TypeCloudinaryImageJSON } from 'hello_baby/types/guides'
import { cloudinaryImageURL } from 'hello_baby/lib/cloudinary'
import { Image as BabyImage } from 'baby-design'
import ScrollLockModal from 'shared/ScrollLockModal'
import { LineClamped } from '../Utilities'

// eslint-disable-next-line import/no-extraneous-dependencies
import css from './styles.scss'

export interface TypeParentReviewFields {
  reviewerName: string
  reviewerLocation?: string
  reviewerAvatar?: TypeCloudinaryImageJSON | TypeCloudinaryImageJSON[]
  reviewText: string
}

const ParentReview: React.FC<TypeParentReviewFields> = ({
  reviewerName,
  reviewerLocation,
  reviewerAvatar,
  reviewText,
}: TypeParentReviewFields) => {
  const [modalIsOpen, setModalOpen] = useState(false)

  const avatarUrl = cloudinaryImageURL(reviewerAvatar)

  const closeModal = () => {
    setModalOpen(false)
  }

  const showMoreClicked = () => {
    setModalOpen(true)
  }

  const reviewHeader = (
    <div className={css.ParentReview__reviewerInfo}>
      <span className={css.ParentReview__reviewerAvatar}>
        {avatarUrl ? (
          <BabyImage lazyLoad alt={reviewerName} src={avatarUrl} />
        ) : (
          <span className={css.ParentReview__reviewerInitial}>
            {Array.from(reviewerName)[0]}
          </span>
        )}
      </span>

      <div>
        <span className={css.ParentReview__reviewerName}>{reviewerName}</span>
        <span className={css.ParentReview__reviewerLocation}>
          {reviewerLocation}
        </span>
      </div>
    </div>
  )

  return (
    <>
      <div className={css.ColoredCard__contentWrapper}>
        <header>{reviewHeader}</header>

        <div className={css.ParentReview__reviewText}>
          <LineClamped
            lines={7}
            showMoreComponent={
              <span className={css.ParentReview__reviewText__showMore}>
                &hellip;
                <button
                  className={css.ParentReview__reviewText__showMore__button}
                  type="button"
                  onClick={showMoreClicked}
                >
                  More
                </button>
              </span>
            }
          >
            <q>{reviewText}</q>
          </LineClamped>
        </div>
      </div>

      <ScrollLockModal
        showCloseButton
        handleClose={closeModal}
        isOpen={modalIsOpen}
      >
        <header className={css.ParentReview__modalHeader}>
          {reviewHeader}
        </header>
        <div className={css.ParentReview__modalBody}>
          <q className={css.ParentReview__reviewText}>{reviewText}</q>
        </div>
      </ScrollLockModal>
    </>
  )
}

export default ParentReview
