import React from 'react'
import classNames from 'classnames'

import css from './QuantifiableThumbnail.styles.scss'

import { QuantifiableThumbnailComponentProps } from './QuantifiableThumbnail.types'
import Badge from '../Badge/Badge'
import Play from '../../icons/Play.svg'

const QuantifiableThumbnail: React.FC<QuantifiableThumbnailComponentProps> = ({
  imageAlt,
  imageSrc,
  onClick,
  quantity,
  strike,
  selectable = false,
  selected = false,
  video = false,
  responsive,
  size,
  className,
}) => {
  const QuantifiableThumbnailClasses = classNames(
    css.QuantifiableThumbnail,
    {
      [css['QuantifiableThumbnail--small']]: size === 'sm',
      [css['QuantifiableThumbnail--medium']]: size === 'md',
      [css['QuantifiableThumbnail--responsive']]: responsive,
    },
    {
      [css['QuantifiableThumbnail--selectable']]: selectable,
      [css['QuantifiableThumbnail--selected']]: selected,
    },
    className
  )

  const handleClick = () => {
    if (!selectable) return
    if (onClick) onClick()
  }

  const strikeClasses = classNames(css.QuantifiableThumbnail__strike, {
    [css['QuantifiableThumbnail__strike--dark']]: strike === 'dark',
    [css['QuantifiableThumbnail__strike--light']]: strike === 'light',
  })

  // TODO - https://babylist.atlassian.net/browse/HRT-3
  /* eslint-disable jsx-a11y/no-static-element-interactions */
  /* eslint-disable jsx-a11y/click-events-have-key-events */
  /* eslint-disable jsx-a11y/no-noninteractive-tabindex */
  return (
    <div className={QuantifiableThumbnailClasses} onClick={handleClick}>
      <div className={css.ImageContainer}>
        <img
          alt={imageAlt}
          src={imageSrc}
          tabIndex={selectable ? 0 : undefined}
        />
        {strike && <div className={strikeClasses} data-testid="strike" />}
        {video && (
          <div
            className={css.QuantifiableThumbnail__video}
            data-testid="video-icon"
          >
            <Play className={css.QuantifiableThumbnail__video__icon} />
          </div>
        )}
      </div>
      {quantity > 1 && (
        <div className={css.QuantityBadgeContainer}>
          <Badge
            fill="default"
            quantity={quantity}
            size="small"
            variant="quantity"
          />
        </div>
      )}
    </div>
  )
}

/* eslint-enable jsx-a11y/no-static-element-interactions */
/* eslint-enable jsx-a11y/click-events-have-key-events */
/* eslint-enable jsx-a11y/no-noninteractive-tabindex */
export default QuantifiableThumbnail
