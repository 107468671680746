import { map, sortBy } from 'lodash'
import classNames from 'classnames'
import { helloBabyPostPath } from 'lib/urls'
import { Link } from 'react-router'

// pregnancy and week-by-week topics should always display before trimester topic
// topics that don't appear below will display AFTER these topics
const SORTED_TOPIC_SLUGS = [
  'pregnancy',
  'week-by-week',
  'pregnancy-week-by-week',
]

interface Topic {
  name: string
  slug: string
}

interface TopicListProps {
  topics: Topic[]
  className: string
}

const sortTopics = (topics: Topic[]) =>
  sortBy(topics, ({ slug }) =>
    [...SORTED_TOPIC_SLUGS].reverse().indexOf(slug)
  ).reverse()

const TopicList = ({ topics, className }: TopicListProps) => (
  <ul className={classNames('list-unstyled', className)}>
    {map(sortTopics(topics), (topic, idx) => (
      <li key={topic.slug} style={{ display: 'inline-block' }}>
        <Link
          to={helloBabyPostPath(topic.slug)}
          className="text-uppercase h7 text-bold"
        >
          {topic.name}
        </Link>
        {idx < topics.length - 1 && <span className="h7 phs">/</span>}
      </li>
    ))}
  </ul>
)

export default TopicList
