import NiceModal, { useModal } from '@ebay/nice-modal-react'
import ScrollLockModal from 'shared/ScrollLockModal'
import { Actions, Info, Intro } from './components'
import { dataTestId } from './functions'
import css from './ConsolidatedShippingExplainerModal.styles.scss'

const ConsolidatedShippingExplainerModal = NiceModal.create(() => {
  const modal = useModal()

  const handleClose = () => {
    modal.hide()
    modal.resolve(false)
    setTimeout(() => {
      modal.remove()
    }, 300)
  }

  return (
    <ScrollLockModal
      showCloseButton
      className={css.ConsolidatedShippingExplainerModal}
      handleClose={handleClose}
      isOpen={modal.visible}
    >
      <div
        className={css.ConsolidatedShippingExplainerModal__content}
        data-testid={dataTestId('content')}
      >
        <Intro />
        <Info />
        <Actions handleClose={handleClose} />
      </div>
    </ScrollLockModal>
  )
})

export default ConsolidatedShippingExplainerModal
