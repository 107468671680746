import { Fragment } from 'react'
import PropTypes from 'prop-types'
import { Media, Row } from 'react-bootstrap'
import { Link } from '@material-ui/core'
import * as blUrls from 'lib/urls'

const nameWithPostnominals = (author) => {
  if (!author) {
    return ''
  }
  let name = `${author.firstName} ${author.lastName}`
  if (!author.firstName && !author.lastName) {
    name = author.name
  }
  if (author.postnominals) {
    name += ` ${author.postnominals}`
  }
  if (!author.slug) {
    return name
  }
  return <Link href={blUrls.helloBabyAuthors(author.slug)}>{name}</Link>
}

const AuthorNames = (authors) => {
  // one author: X
  // just two authors:  X and Y
  // three + authors: X, Y and Z

  if (authors.length == 0) {
    return 'Babylist Team'
  }
  return (
    <>
      {authors.map((author, index) => {
        const authorName = nameWithPostnominals(author)
        return (
          <Fragment key={index}>
            {authors.length == 2 && index == 1 && ' and '}
            {authors.length > 2 &&
              index != authors.length - 1 &&
              index > 0 &&
              ', '}
            {authors.length > 2 && index == authors.length - 1 && ' and '}
            {authorName}
          </Fragment>
        )
      })}
    </>
  )
}

const AuthorByline = ({ post, className }) => {
  const { medicallyReviewedBy, factCheckedBy } = post
  const reviewerName = nameWithPostnominals(medicallyReviewedBy)
  const factCheckerName = nameWithPostnominals(factCheckedBy)

  let altText = `{authorName} {jobTitle}`

  const reviewer = medicallyReviewedBy ? (
    <>| Medically Reviewed by {reviewerName}</>
  ) : (
    ''
  )
  const factChecker = factCheckedBy ? (
    <>| Fact Checked by {factCheckerName}</>
  ) : (
    ''
  )

  return (
    <Row className={className}>
      <Media className="col-sm-12">
        <Media.Body>
          <h6 className="text-muted mts h7">
            By {AuthorNames(post.author)} {reviewer} {factChecker}
          </h6>
        </Media.Body>
      </Media>
    </Row>
  )
}

AuthorByline.propTypes = {
  post: PropTypes.object.isRequired,
  className: PropTypes.string,
}

export default AuthorByline
