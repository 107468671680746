import { Document } from '@contentful/rich-text-types'
import RichTextBody from 'hello_baby/components/RichTextBody'

import css from './styles.scss'

const ALLOWED_EMBEDS = [
  'postEmbedImage',
  'postEmbedProductCard',
  'postEmbedSocialMedia',
  'video',
]

export interface TypePostEmbedCollapsingSectionFields {
  heading: string
  openOnLoad: boolean
  richTextContent: Document
}

interface EmbedCollapsingSectionProps {
  fields: TypePostEmbedCollapsingSectionFields
}

const EmbedCollapsingSection: React.FC<EmbedCollapsingSectionProps> = ({
  fields,
}: EmbedCollapsingSectionProps) => (
  <details className={css.EmbedCollapsingSection} open={fields.openOnLoad}>
    <summary>
      <h3>{fields.heading}</h3>
    </summary>

    <RichTextBody
      allowedEmbeds={ALLOWED_EMBEDS}
      richTextBody={fields.richTextContent}
    />
  </details>
)

export default EmbedCollapsingSection
