import React from 'react'
import RegItemPurchaseModal from 'src/routes/(registry)/list/[slug]/components/EditRegItemDrawer/components/RegItemPurchaseModal/RegItemPurchaseModal'
import PurchasesSection from 'src/routes/(registry)/list/[slug]/components/EditRegItemDrawer/components/PurchasesSection/PurchasesSection'
import { GiftCard2 } from 'baby-design/icons'
import { numberToCurrency } from 'lib/money'
import ContributionBullet from 'src/routes/(registry)/list/[slug]/components/EditRegItemDrawer/fields/components/ContributionBullet/ContributionBullet'
import { GiftCardPurchasesSectionProps } from './GiftCardPurchasesSection.types'

const TITLE = 'Amount gifted'

const GiftCardPurchasesSection: React.FC<GiftCardPurchasesSectionProps> = ({
  regItem,
  reservations = [],
  onRegItemUpdated,
}) => {
  const totalAmount = reservations.reduce(
    (sum, reservation) => sum + (reservation.giftAmount || 0),
    0
  )

  const currencyString = numberToCurrency(totalAmount, {
    truncateZeroCents: true,
    unit: '$',
  }) as string

  return (
    <PurchasesSection
      bullet={ContributionBullet}
      icon={GiftCard2}
      markAsPurchasedModal={RegItemPurchaseModal}
      regItem={regItem}
      reservations={reservations}
      status={currencyString}
      title={TITLE}
      onRegItemUpdated={onRegItemUpdated}
    />
  )
}

export default GiftCardPurchasesSection
