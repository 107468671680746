import { track } from 'lib/analytics'
import { registryProperties } from 'src/lib/tracking/shared/bundles/registryProperties'
import { EventPropertiesProps } from 'src/lib/tracking/shared/bundles/eventProperties'
import { CurrentUser } from '../../../../../global'

type TrackToastClickedProps = EventPropertiesProps & {
  currentUser: CurrentUser | undefined
  regItemIds: number[]
  tracker: any
}

export const trackToastClicked = ({
  currentUser,
  eventCta,
  eventLocation,
  eventType,
  regItemIds,
  tracker,
}: TrackToastClickedProps) => {
  tracker.trackEvent({
    event: track.toastClicked,
    ...registryProperties(currentUser),
    eventLocation,
    eventCta,
    eventType,
    regItemIds,
  })
}
