import { cloneElement, FC } from 'react'

interface FulfillmentTrackingInfosProps {
  trackingInfos: Array<{
    action: string
    number: number | string
    url: string
    isSubscriptionItem: boolean
  }>
  trackingInfoButtonComponent: JSX.Element
  subscriptionSetupButton: JSX.Element
}

const FulfillmentTrackingInfos: FC<FulfillmentTrackingInfosProps> = ({
  trackingInfos,
  trackingInfoButtonComponent,
  subscriptionSetupButton,
}) => {
  if (trackingInfos?.length === 0) return null

  return (
    <>
      {trackingInfos
        .filter(({ url }) => !!url)
        .map(({ url, number, action, isSubscriptionItem }) => {
          const actionButton = isSubscriptionItem
            ? subscriptionSetupButton
            : trackingInfoButtonComponent
          return cloneElement(actionButton, { key: number, href: url }, action)
        })}
    </>
  )
}

export default FulfillmentTrackingInfos
