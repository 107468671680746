import React from 'react'

interface TargetStoreProps {
  className?: string
  rounded?: boolean
}

const TargetStore: React.FC<TargetStoreProps> = ({
  className,
  rounded = true,
}: TargetStoreProps) => (
  <svg
    className={className}
    fill="none"
    height="24"
    viewBox="0 0 24 24"
    width="24"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_3112_3547)">
      <path
        d="M24 12C24 18.6274 18.6274 24 12 24C5.37258 24 0 18.6274 0 12C0 5.37258 5.37258 0 12 0C18.6274 0 24 5.37258 24 12Z"
        fill="white"
      />
      <path
        clipRule="evenodd"
        d="M12 16.0029C12.5257 16.0029 13.0462 15.8993 13.5318 15.6982C14.0175 15.497 14.4587 15.2021 14.8304 14.8304C15.2021 14.4587 15.497 14.0175 15.6982 13.5318C15.8993 13.0462 16.0029 12.5257 16.0029 12C16.0029 11.4743 15.8993 10.9538 15.6982 10.4682C15.497 9.98252 15.2021 9.54125 14.8304 9.16955C14.4587 8.79785 14.0175 8.50301 13.5318 8.30184C13.0462 8.10068 12.5257 7.99714 12 7.99714C10.9384 7.99714 9.92023 8.41887 9.16955 9.16955C8.41887 9.92023 7.99714 10.9384 7.99714 12C7.99714 13.0616 8.41887 14.0798 9.16955 14.8304C9.92023 15.5811 10.9384 16.0029 12 16.0029ZM24 12C24 15.1826 22.7357 18.2348 20.4853 20.4853C18.2348 22.7357 15.1826 24 12 24C8.8174 24 5.76515 22.7357 3.51472 20.4853C1.26428 18.2348 0 15.1826 0 12C0 8.8174 1.26428 5.76515 3.51472 3.51472C5.76515 1.26428 8.8174 0 12 0C15.1826 0 18.2348 1.26428 20.4853 3.51472C22.7357 5.76515 24 8.8174 24 12ZM19.9971 12C19.9971 14.121 19.1546 16.1551 17.6548 17.6548C16.1551 19.1546 14.121 19.9971 12 19.9971C9.87903 19.9971 7.84492 19.1546 6.34517 17.6548C4.84541 16.1551 4.00286 14.121 4.00286 12C4.00286 9.87903 4.84541 7.84492 6.34517 6.34517C7.84492 4.84541 9.87903 4.00286 12 4.00286C14.121 4.00286 16.1551 4.84541 17.6548 6.34517C19.1546 7.84492 19.9971 9.87903 19.9971 12Z"
        fill="#CC0000"
        fillRule="evenodd"
      />
    </g>
    <defs>
      <clipPath id="clip0_3112_3547">
        {rounded ? (
          <path
            d="M0 12C0 5.37258 5.37258 0 12 0C18.6274 0 24 5.37258 24 12C24 18.6274 18.6274 24 12 24C5.37258 24 0 18.6274 0 12Z"
            fill="white"
          />
        ) : (
          <rect fill="white" height="24" width="24" />
        )}
      </clipPath>
    </defs>
  </svg>
)

export default TargetStore
