/* eslint-disable import/no-extraneous-dependencies */
import { useState } from 'react'
import classNames from 'classnames'
import { StoreIcon, Button } from 'baby-design'
import { Check } from 'baby-design/icons'
import { FLASH_MESSAGE } from 'shared/constants'
import { parseErrorMessage } from 'lib/api-utils'
import { addProductAvailabilitySubscription } from 'src/api/queries'
import { Offer } from 'src/types/offer'
import { showNotifyMeSuccessToast } from '../../../NotifyMeSuccessToast/showNotifyMeSuccessToast'

import css from './OutOfStockOption.styles.scss'

interface OutOfStockOptionProps {
  isBabylistProductDiscontinued: boolean
  option: Offer
  productId: number
  useHorizontalPadding: boolean
}

export const OutOfStockOption = ({
  isBabylistProductDiscontinued,
  option,
  productId,
  useHorizontalPadding,
}: OutOfStockOptionProps) => {
  const [isSubscribed, setIsSubscribed] = useState(false)
  const [isLoading, setIsLoading] = useState(false)

  const handleNotifyMeClick = () => {
    setIsLoading(true)
    addProductAvailabilitySubscription(productId)
      .then(() => {
        setIsSubscribed(true)
        showNotifyMeSuccessToast()
      })
      .catch((resp) => {
        PubSub.publish(FLASH_MESSAGE, {
          message: parseErrorMessage(resp),
          variant: 'danger',
        })
      })
      .finally(() => {
        setIsLoading(false)
      })
  }

  return (
    <div
      className={classNames(css.OutOfStockOption, {
        [css['OutOfStockOption--horizontalPadding']]: useHorizontalPadding,
      })}
    >
      <div className={css.OutOfStockOption__info}>
        <StoreIcon
          className={css.OutOfStockOption__icon}
          store={{ name: option.storeIconName }}
        />
        <div className={css.OutOfStockOption__nameAndStatus}>
          <div className={css.OutOfStockOption__name}>
            {option.storeDisplayName}
          </div>
          {isBabylistProductDiscontinued ? (
            <span className={css.OutOfStockOption__statusText}>
              Out of stock
            </span>
          ) : (
            <div className={css.OutOfStockOption__statusText}>
              In stock soon
            </div>
          )}
        </div>
      </div>
      {!isBabylistProductDiscontinued &&
        (isSubscribed ? (
          <div className={css.OutOfStockOption__successMessage}>
            <Check className={css.OutOfStockOption__successIcon} />
            <span className={css.OutOfStockOption__successText}>
              We&rsquo;ll notify you
            </span>
          </div>
        ) : (
          <Button
            className={css.OutOfStockOption__notifyMeButton}
            loading={isLoading}
            size="sm"
            variant="de-emphasized"
            onClick={handleNotifyMeClick}
          >
            Notify me
          </Button>
        ))}
    </div>
  )
}
