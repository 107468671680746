import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import { reservationPath } from 'lib/urls'
import Link from 'components/link'
import classNames from 'classnames'
import { openGiftTrackerModal } from 'registry/modals/actions'
import FavorReservationStatusAction from 'registry/components/reg-item-card/guest-actions/reservation-status-actions/FavorReservationStatusAction'
import OpenGiftTrackerAction from 'registry/components/reg-item-card/guest-actions/reservation-status-actions/OpenGiftTrackerAction'
import css from './guest-actions.scss'
import { reservationProps } from '../reservation-props'

export const BaseReservationStatusAction = ({
  reservation: {
    token,
    isGroupGiftContribution,
    isPurchased,
    regItem,
    printableUrl,
  },
  openGiftTrackerModal,
}) => (
  <>
    {isPurchased ? (
      <OpenGiftTrackerAction
        openGiftTrackerModal={openGiftTrackerModal}
        statusText={
          isGroupGiftContribution || regItem.isCrowdfund
            ? 'Gifted by you'
            : 'Purchased by you'
        }
      />
    ) : (
      <>
        <hr className="visible-xs-block mts mbm" style={{ width: '155px' }} />
        <div
          className={classNames(
            css.guestAction,
            'h6 inline-block flex col justify-end'
          )}
        >
          {regItem?.isFavor ? (
            <FavorReservationStatusAction printableUrl={printableUrl} />
          ) : (
            <Link className="link-info text-bold" url={reservationPath(token)}>
              In Cart
            </Link>
          )}
        </div>
      </>
    )}
  </>
)

BaseReservationStatusAction.propTypes = {
  reservation: PropTypes.shape(reservationProps).isRequired,
  openGiftTrackerModal: PropTypes.func.isRequired,
}

export const ReservationStatusAction = connect(undefined, {
  openGiftTrackerModal,
})(BaseReservationStatusAction)
