import React from 'react'
import classNames from 'classnames'
import { PurchaseOptionRequiredCalloutProps } from './PurchaseOptionRequiredCallout.types'
import { Exclamation } from 'baby-design/icons'
import css from './PurchaseOptionRequiredCallout.scss'

const PurchaseOptionRequiredCallout: React.FC<
  PurchaseOptionRequiredCalloutProps
> = ({ className, text }) => (
  <div className={classNames(css.PurchaseOptionRequiredCallout, className)}>
    <Exclamation className={css.PurchaseOptionRequiredCallout__Icon} />
    <span className={css.PurchaseOptionRequiredCallout__Text}>{text}</span>
  </div>
)

export default PurchaseOptionRequiredCallout
